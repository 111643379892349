import { useState, Fragment, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import BlogService from "../../services/blogService";
// import Comment from './comment'
import CommentReplies from './CommentReplies'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Button,
    TextField,
    List,
    Box
  } from "@material-ui/core";
import moment from 'moment'
import { Toast } from '../toastify' 

const CssTextField = withStyles({
    root: {
        '& label': {
            color: 'white'
        },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
    },
  })(TextField);

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    fonts: {
        color: 'white'
    },
    inline: {
      display: "inline"
    },
    button: {
        borderColor:"#fed108", 
        color: "#fed108", 
        marginTop: '5px',
        marginRight: '5px',
        "&:hover": {
            backgroundColor: '#fed108',
            color: 'black'
        }
    }
  }));
  
const isAuthenticated = () => {
    if(localStorage.hasOwnProperty('vettageToken') && localStorage.getItem("vettageToken") !== "undefined"){
        return true
    }
    return false
}

const ArticleComments = ({ articleId, commentsList }) => {
    const [newComment, setNewComment] = useState('');
    const [patch, setPatch] = useState("")
    const [patchCommentContent, setPatchComment] = useState("")
    const [showReply, setShowReply] = useState("")
    const [comments, setComments] = useState([])
    const classes = useStyles();
    const history = useHistory()

    useEffect(() => {
        setComments(commentsList)
    }, [])

    const postComment = () => {
        if(newComment === ""){
            Toast("error", "The comment can't be empty.")
            return;
        }
        BlogService.postComment(articleId, newComment)
        .then(result => {
            if(result.status === 201){
                setComments([...comments, result.data])
                setNewComment("")
                Toast("success", "The comment has been posted.")
            }
        })
    }
    
    const deleteComment = commentId => {
        BlogService.deleteComment(articleId, commentId)
        .then(result => {
            if(result.status === 204){
                setComments(comments.filter(item => item.id !== commentId))
                Toast('success', "The comment has been deleted.")
            }else{
                Toast('error', result?.data?.error)
            }
        })
    }

    const patchComment = commentId => {
        if(patchCommentContent === ""){
        Toast("error", "The comment can't be empty.")
        return;
        }
        BlogService.patchComment(articleId, commentId, patchCommentContent)
        .then(result => {
            if(result.status === 200){
                const data = comments.filter(item => item.id !== commentId)
                setComments([...data, result.data])
                Toast("success", "The comment has been edited.")
            }
            setPatch("")
        })
    }

    const hideReply = commentId => {
        if(showReply === ""){
            setShowReply(commentId)
        }else{
            setShowReply("")
        }
    }

    return (
        <div>
        <List>
            {comments?.sort((a,b) => moment(a.created_at) - moment(b.created_at)).map(comment => {
                return (
                    <Fragment key={comment.id}>
                        <ListItem key={comment.id} alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="avatar" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={patch === comment.id ? 
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Add a comment"
                                    multiline
                                    rows={4}
                                    value={patchCommentContent}
                                    onChange={e => setPatchComment(e.target.value)}
                                    defaultValue="Default Value"
                                    variant="outlined"
                                    />
                                : 
                                <Typography className={classes.fonts}>
                                    {comment?.content}
                                </Typography>
                                }
                                secondary={
                                <>
                                    <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    style={{ color: "white" }}
                                    >
                                    {comment?.user} {" "} - {moment(comment?.created_at).format('DD/MM/YYYY hh:mm')}

                                    </Typography>
                                    <div>
                                    {isAuthenticated() ? 
                                        <div>
                                            <Button size='small' className={classes.button} variant='outlined' 
                                            onClick={() => hideReply(comment.id)}>Reply</Button>
                                            {comment.owner &&
                                            <span>
                                            {patch === comment.id ? 
                                            <Button size='small'  className={classes.button} variant='outlined'
                                            onClick={() => patchComment(comment.id)}>Update</Button> 
                                            : 
                                            <Button size='small' className={classes.button} variant='outlined'
                                            onClick={() => {setPatch(comment.id); setPatchComment(comment.content)}}>Edit</Button> }
                                            <Button size='small' className={classes.button} variant='outlined'
                                            onClick={() => deleteComment(comment.id)}>Delete</Button>
                                            </span>}
                                        </div> 
                                    :
                                    <Box component='div'>
                                        <Button variant='outlined' className={classes.button} size='small'
                                        onClick={() => history.push('/login')}
                                        >Login to reply</Button>
                                    </Box>}
                                    {comment.replies && 
                                        <CommentReplies 
                                        commentId={comment.id} 
                                        listReplies={comment.replies}
                                        showReply={showReply}
                                        setShowReply={setShowReply}
                                        />
                                    }                                                                            
                                </div>
                                </>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </Fragment>)   
            })}
        </List>

        {isAuthenticated() ? 
            <div>
                <Box component='div' fullWidth margin='15px 15px'>
                    <Box>
                        <CssTextField      
                        label="Add a comment"
                        size='large'
                        multiline
                        fullWidth
                        rows={4}
                        style={{borderColor:"#fed108", color: "#fed108"}}
                        value={newComment}
                        onChange={e => setNewComment(e.target.value)}
                        defaultValue="Default Value"
                        variant="outlined"
                        />
                    </Box>
                    <Box component='div' marginTop='10px'>
                        <Button variant='outlined' size='small'className={classes.button} onClick={postComment}>Comment</Button>
                    </Box>
                </Box>
              </div>
              :
              <Box component='div'>
                <Button variant='outlined' className={classes.button} size='small'
                onClick={() => history.push('/login')}
                >Login to comment</Button>
            </Box>}
        </div>
    )
}

export default ArticleComments;