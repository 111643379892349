import { Component } from 'react';

import Select from 'react-select';
import Creatable from 'react-select/creatable';
import FormControl from '@material-ui/core/FormControl';
import { WithContext as ReactTags } from 'react-tag-input';
import validator from 'validator';
import { Box, Grid, Typography, withStyles, Button, RadioGroup, Radio, FormControlLabel, TextField } from '@material-ui/core'
import '../../styles/tags.css'
import '../../styles/styles.css'
import moment from 'moment';
import csc from 'country-state-city'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = (theme) => ({
    formControl: {
      margin: theme.spacing(3),
      color: 'white'
    },
    button: {
        margin: 'auto',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    gridItem: {
        'margin': '0px 20px 20px 0px'  
    },
    radioItem: {
        margin: 'auto',
        height: '5em',   
    },
    button: {
        padding: '0px',
        color: '#fed108',
        backgroundColor: 'black',
        border: '1px solid #fed108',
        '& span':{
          padding: '8px 15px'
        },
        '&.Mui-disabled':{
            border: '0px',
          backgroundColor: 'silver',
          color: 'black',  
        },
        '& :hover':{
          color: 'black',
          backgroundColor: '#fed108'
        }
      }
});

const CssTextField = withStyles({
    root: {
      '& label': {
          color: 'white'
      },
      '& label.Mui-focused': {
        color: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#fed108',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
      '& .MuiFormHelperText-root':{
          color: 'white'
      }
    }
})(TextField);

const CssDateTimePicker = withStyles({
    root: {
        width: '100%',
        color: 'white',
        borderColor: 'white',
        '& label':{
            color: 'white'
        },
        '&  input': {
            color: 'white'
        },
        '& label.Mui-focused':{
            color: 'white'
        },
        '&  input': {
            color: 'white'
        },
        '& .MuiInput-underline:before':{
            borderColor: 'white'
        },
        '& .MuiInput-underline:focused':{
            borderColor: 'white'
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'

        }
    }
})(DateTimePicker);

const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: state.isDisabled ? 'white' : '#fed108'  
    }),
    option: (provided, state) => ({
        ...provided,
        opacity: 1,
        color: state.isSelected ? '#fed108' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
}

const KeyCodes = {
comma: 188,
enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class RawContentForm extends Component{
    constructor(props) {
        super(props)

        this.state = {
            data: {
                content_type: "",
                tags: [],
                copyright: false,
                raw_content_address: {
                    country: "",
                    state: "",
                    city: "",
                    zip_code: ""
                },
                event_time: moment().format("YYYY-MM-DDThh:mm"),
                link: "",
            },
            countries: csc.getAllCountries().map(item => ({value: item.isoCode, label: item.name})),
            country: null,
            states: [],
            state: null,
            cities: [],
            city: null,
            contentOptions: [
                {"value": "MULTIMEDIA", "label": "Multimedia"}, 
                {"value": "ANIMATION", "label": "Animation"},
                {"value": "AUDIO", "label": "Audio"},
                {"value": "GRAPHIC", "label": "Graphic"},
                {"value": "PHOTO", "label": "Photo"},
                {"value": "TEXT", "label": "Text"},
                {"value": "VIDEO", "label": "Video"},
            ]
        }
      }


    componentDidMount(){
        if(this.props?.data){
            const tags = this.props.data.tags.map(tag => { return {"id": tag, "text": tag}} )
            const contentType = this.state.contentOptions.find(option => option.value === this.props.data.content_type)
            this.setState({ 
                data: {...this.props.data, tags: tags, content_type: contentType }
            })
        }
    }

    handleDelete = (i) => {
        const tags = this.state.data.tags;
        this.setState({
         data: {...this.state.data, tags: tags.filter((tag, index) => index !== i)}
        });
    }
    
    handleAddition = (tag) => {
        this.setState(prevState => ({ data: {...prevState.data, tags: [...prevState.data.tags, tag] }}));
    }
    
    handleDrag = (tag, currPos, newPos) => {
        const tags = [...this.state.data.tags];
        const newTags = tags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        this.setState({ data: {...this.state.data, tags: newTags }});
    }

    getSelectedCountry = () => {
        if (!this.state.country && !!this.state.data.raw_content_address.country){
            const country = csc.getAllCountries().find(item => item.name === this.state.data.raw_content_address.country)
            this.setState({  country: { value: country.isoCode, label: country.name }});
        }
        return this.state.country;
    }

    setSelectedCountry = event => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                raw_content_address: {
                    ...prevState.data.raw_content_address,
                    country : event.label,
                    city: "",
                    state: ""
                }
            },
            country: { value: event.value, label: event.label },
            states: csc.getStatesOfCountry(event.value).map(item => ({value: item.isoCode, label: item.name})),
            state: null,
            cities: [],
            city: null
        }));
    }

    getSelectedState = () => {
        if (!!this.state.country && !this.state.state && !!this.state.data.raw_content_address.state){
            const country = this.getSelectedCountry();
            const state = csc.getStatesOfCountry(country.value).find(item => item.name === this.state.data.raw_content_address.state)
            if (state) this.setState({ state: { value: state.isoCode, label: state.name }});
        }
        return this.state.state;
    }

    setSelectedState = event => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                raw_content_address: {
                    ...prevState.data.raw_content_address,
                    state : event.label
                }
            },
            state: { value: event.value, label: event.label },
            cities: csc.getCitiesOfState(prevState.country.value, event.value).map(item => ({value: item.name, label: item.name})),
            city: null
        }));
    }

    getSelectedCity = () => {
        if (!!this.state.country && !!this.state.state && !this.state.city && !!this.state.data.raw_content_address.state){
            const country = this.getSelectedCountry();
            const state = this.getSelectedState();
            const city = csc.getCitiesOfState(country.value, state.value).find(item => item.name === this.state.data.raw_content_address.city)
            if (city) this.setState({ city: { value: city.name, label: city.name }});
        }
        return this.state.city;
    }

    setSelectedCity = event => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                raw_content_address: {
                    ...prevState.data.raw_content_address,
                    city : event.label
                }
            },
            city: { value: event.value, label: event.label }
        }));
    }

    render() {
        const { classes } = this.props;
        return (
            <FormControl component="fieldset" fullWidth className={classes.formControl}>
                <Box component='div'>
                <Grid container lg={10} md={10} xs={12} sm={12} style={{margin: 'auto'}}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                        <Box component='div' width='full' className={classes.gridItem}>
                            <Typography style={{color: 'white'}}>Raw Media Type:</Typography>
                            <Select id="content-type" name="contentType" 
                                required
                                styles={customStyles}
                                value={this.state.data.content_type}
                                options={this.state.contentOptions} 
                                onChange={e =>  this.setState(prevState => ({ data: {...prevState.data, content_type: e }}))} />
                        </Box>
                        <Box component='div' width='full' style={{border: '1px solid silver', borderRadius: '10px', padding: '10px 15px'}} className={classes.gridItem}>
                            <Grid container lg={12} md={12} xs={12} sm={12}style={{margin: 'auto'}}>
                                <Grid item lg={3} md={3} xs={12} sm={12}>
                                    <Typography style={{margin: '10px'}}>Tags:</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12} sm={12}>
                                <ReactTags tags={this.state.data.tags}
                                    handleDelete={this.handleDelete}
                                    handleAddition={this.handleAddition}
                                    handleDrag={this.handleDrag}
                                    delimiters={delimiters} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box component='div' width='full' className={classes.gridItem} style={{border: '1px solid silver', borderRadius: '10px', padding: '10px 15px'}}>
                            <Typography>Copyright: </Typography>
                            <RadioGroup onChange={() => this.setState(prevState => ({ data: {...this.state.data, copyright: !prevState.data.copyright }}))} 
                            value={this.state.data.copyright.toString()} 
                                defaultValue="false"  labelId="copyright" name="copyright" 
                                className={classes.radioItem}>
                                <FormControlLabel style={{margin: '0 auto'}} value="true" control={<Radio color="primary"/>} label="True" />
                                <FormControlLabel style={{margin: '0 auto'}} value="false" control={<Radio color="primary"/>} label="False" />
                            </RadioGroup>
                            <Typography style={{fontSize: '13px', color: 'white', margin: '-30px 0 0 0'}}>{this.state.data.copyright == false ? "In order to submit content on Vettage, you must affirm that you are the Copyright holder." : ""}</Typography>
                        </Box>
                        <Box component='div' width='full' className={classes.gridItem} style={{border: '1px solid silver', borderRadius: '10px', padding: '10px 15px'}}>
                            <Grid container lg={12} md={10} xs={12} sm={12}>
                                <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                                    <CssDateTimePicker
                                        required
                                        id="time"
                                        label="Media Date"
                                        name="eventTime"
                                        value={this.state.data.event_time}
                                        onChange={value => this.setState(prevState => ({ data: {...prevState.data, event_time: value } }))}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Box>       
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                        <Box component='div' width='full' className={classes.gridItem}>
                            <CssTextField
                                required
                                defaultValue=""
                                id="link"
                                label="Link to media"
                                variant="outlined"
                                name="link"
                                fullWidth
                                value={this.state.data.link}
                                onChange={e => this.setState(prevState => ({ data: {...prevState.data, link: e.target.value} }))}
                                error={this.state.data.link === "" ? false : !validator.isURL(this.state.data.link)}
                                helperText={validator.isURL(this.state.data.link) === false ? "Please enter a valid URL" : ""}
                            />
                        </Box>
                    <Box border='1px solid white' padding='5px 15px' mb={1}>
                        <Box m={2}>
                            <Typography variant='h6' style={{ color: 'white' }}>Media Location</Typography>
                        </Box>
                        <Box component='div' width='full' className={classes.gridItem}>
                            <Box>
                                <Typography>Select a country*:</Typography>
                            </Box>
                            <Select
                                options={this.state.countries}
                                value={this.getSelectedCountry()}
                                styles={customStyles}
                                onChange={this.setSelectedCountry}
                            />
                        </Box>
                        <Box component='div' width='full' className={classes.gridItem}>
                            <Box mb={1}>
                                <Typography>State: </Typography>
                            </Box>
                            <Select 
                                options={this.state.states}
                                value={this.getSelectedState()} 
                                name="state"
                                styles={customStyles} 
                                onChange={this.setSelectedState}
                            />
                        </Box>
                        <Box component='div' width='full' className={classes.gridItem}>
                            <Box mb={1}>
                                <Typography>City: </Typography>
                            </Box>
                            <Creatable
                                options={this.state.cities}
                                value={this.getSelectedCity()} 
                                name="city"
                                id='city'
                                styles={customStyles} 
                                onChange={this.setSelectedCity}
                            />
                        </Box>
                    </Box>
                    </Grid>
                        <Box margin={'auto'}>
                            <Button type="submit" size='large'
                            className={useStyles.button}
                            disabled={!this.state.data.copyright}
                            onClick={e => this.props.handleUpload(e, this.state.data)} className={classes.button}>
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                    </Box>
            </FormControl>
        )}
    }

export default withStyles(useStyles)(RawContentForm);
