// ** React Imports
import { Link } from 'react-router-dom'
import EditorService from '../../../services/editorsService'
// ** Custom Components

// ** Third Party Components
import {
  Edit,
  Trash
} from 'react-feather'
import { Button } from '@material-ui/core'
import moment from 'moment'

const deleteFinal = finalId => {
  EditorService.deleteContent(finalId)
  .then(result => {
    if(result.status === 204){
      window.location.reload()
    }
  })
}

// ** Table columns
export const columns = [
  {
    name: 'Story Type',
    minWidth: '50px',
    selector: 'content_type',
    sortable: true,
    cell: row =>  <span><strong>{row.content_type}</strong></span>
  },
  {
    name: 'Title',
    minWidth: '80px',
    selector: 'title',
    sortable: true,
    cell: row =>  <span><strong>{row.title?.length > 10 ? row.title.substring(0, 10) + '...' : row.title}</strong></span>
  },
  {
    name: 'Thumbnail',
    minWidth: '80px',
    selector: 'image-thumbnail',
    sortable: true,
    cell: row =>  <Button size="small" color="primary" href={row.thumbnail} target="_blank">Thumbnail</Button>
  },
  {
    name: 'Preview',
    minWidth: '100px',
    selector: 'image-preview',
    sortable: true,
    cell: row =>  <Button size="small" color="primary" href={row.preview} target="_blank">Preview</Button>
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '130px',
    cell: row => {
      let address = ""
      const length = Object.keys(row?.final_content_address)?.length
      Object.keys(row?.final_content_address).map((key, index) =>{
        address += row.final_content_address[key]
        if(length !== index + 1){
          address += ', '
      }
    })
      return address?.length > 30 ? address.substring(0, 30) + '...' : address
    }
  },
  {
    name: 'Story Date',
    selector: 'eventTime',
    sortable: true,
    minWidth: '150px',
    cell: row => moment(row.event_time).format('MM/DD/YYYY hh:mm')
  },
  {
    name: 'Link',
    selector: 'link',
    sortable: true,
    minWidth: '80px',
    cell: row => <Button size="small" color="primary" href={row.link} target="_blank">Link</Button>
  },
  {
    name: 'Date Posted',
    selector: 'createdAt',
    sortable: true,
    minWidth: '150px',
    cell: row => moment(row.created_at).format('MM/DD/YYYY hh:mm')
  },
  {
    name: 'Action',
    minWidth: '80px',
    selector: '',
    sortable: true,
    cell: row => (
      <div className='column-action d-flex align-items-center'>
        <Link to={{
          pathname: `/editor/edit/${row.id}`,
          data: row
        }}>
          <Edit size={14} className='mr-50' />
        </Link>
        <Link>
          <Trash size={14} className='mr-50' onClick={() => deleteFinal(row.id)}/>
        </Link>
      </div>
    )
  }
]
