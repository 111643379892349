import Form from "react-validation/build/form";
import { Box, TextField, withStyles, Container } from '@material-ui/core';


const CssTextField = withStyles({
    root: {
      '& label': {
          color: 'white'
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'black',
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
    },
})(TextField);

const UserDetailsForm = ({ email, setEmail, password, setPassword }) => {
    return (
                <Form>
                    <Container maxWidth='xs'> 
                        <Box component="div" m={1}>
                            <CssTextField
                                type="text"
                                className="form-control"
                                name="email"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Box>
                        <Box component="div" m={1}>            
                            <CssTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                className="form-control"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Box>
                    </Container>      
                </Form>
    )
}

export default UserDetailsForm;