import axios from './custom-axios';
import AuthService from './authService';

class EditorsService {

    filterRawContent(params){
        return axios
        .get("content/editor/raw/search/" + params,
         { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    uploadContent(data) {
        const tags = data.tags.map(item => item.text)
        const contributors = data.contributors.map(item => item.text)

        return axios
          .post("/content/editor/final/", {
              "content_type": data.content_type.value,
              "title": data.title,
              "description": data.description,
              "thumbnail": data.thumbnail,
              "preview": data.preview,
              "tags": tags,
              "content_contributors": contributors,
              "copyright": data.copyright,
              "final_content_address": {
                  "country": data.final_content_address.country,
                  "state": data.final_content_address.state,
                  "city": data.final_content_address.city,
                  "zip_code": data.final_content_address.zip_code
              },
              "event_time": data.event_time,
              "link": data.link
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    getContent(limit, currentPage, country, contentType){
        return axios
        .get("/content/editor/final/?limit=" + limit + "&page=" + (currentPage + 1) + "&country=" + country +
        "&content_type=" + contentType , { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }
    
    getContentById(id){
        return axios
        .get("/content/editor/final/" + id, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    checkUserContributor(username){
        return axios
        .post("/account/username/contributor/", {
            "username": username
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    patchContent(data) {
        const tags = data.tags.map(item => item.text)
        const contributors = data.contributors.map(item => item.text)

        return axios
            .patch("/content/editor/final/" + data.id, {
                    "content_type": data.content_type.value,
                    "title": data.title,
                    "description": data.description,
                    "thumbnail": data.thumbnail,
                    "preview": data.preview,
                    "tags": tags,
                    "content_contributors": contributors,
                    "copyright": data.copyright,
                    "final_content_address": {
                        "country": data.final_content_address.country,
                        "state": data.final_content_address.state,
                        "city": data.final_content_address.city,
                        "zip_code": data.final_content_address.zip_code
                    },
                    "event_time": data.event_time,
                    "link": data.link
            }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    deleteContent(id){
        return axios
        .delete("/content/editor/final/" + id , { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    getRatings(id, rows, page){
        return axios
        .get("/content/" + id + "/ratings/?limit=" + rows + "&page=" + (page + 1) , { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }
}

export default new EditorsService();