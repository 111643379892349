// ** React Imports
import { Component } from 'react'
// ** Table Columns
import { columns } from './columns'
import moment from 'moment'
// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import DataTable from 'react-data-table-component'
import { Card } from 'reactstrap'
import Select from 'react-select';
import { FormControl, Box, Button, TextField, withStyles, Drawer, Typography, Container  } from '@material-ui/core';
import { WithContext as ReactTags } from 'react-tag-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import EditorService from "../../../services/editorsService";
import { CustomToast, Toast } from '../../../components/toastify'
import csc from 'country-state-city'

const styles = () => ({
  gridItem: {
    margin: '20px auto',
    textAlign: 'center'
  }, 
  button: {
    padding: '0px',
    color: '#fed108',
    backgroundColor: 'black',
    border: '1px solid #fed108',
    '& span':{
      padding: '8px 15px'
    },
    '&.Mui-disabled':{
        border: '0px',
      backgroundColor: 'silver',
      color: 'black',  
    },
    '& :hover':{
      color: 'black',
      backgroundColor: '#fed108'
    }
  }
});


const KeyCodes = {
  comma: 188,
  enter: 13,
  };
  
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const experienceOptions = [ {'value': "", 'label': 'Select experience'},
    {"value": "LESS_THAN_1", "label": "Less than 1 year"}, {"value": "ONE_TWO_YEARS", "label": "1-2 Years"},
    {"value": "TWO_FIVE_YEARS", "label": "2-5 Years"}, {"value": "FIVE_YEARS_MORE", "label": "5 Years or more"},
]

const customStyles = {
  menu: base => ({
      ...base,
      zIndex: 100
  }),
  control: (provided, state) => ({
    ...provided,
    opacity: 1,
  }),
  option: (provided, state) => ({
      ...provided,
      opacity: 1,
  }),
  singleValue: (provided, state) => ({
      ...provided,
      opacity: 1,
  })
}

class EditorSearchPage extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            content: [],
            count: 0,
            params: {
              content_type: "",
              username: "",
              tags: [],
              country: "",
              state: "",
              city: "",
              zip_code: "",
              experience: "",
              event_time: null,
            },
            open: false,            
            currentPage: 0,
            rowsPerPage: 10,
            countries: csc.getAllCountries().map(item => { 
              return {value: item.isoCode, label: item.name}
            }),
              contentOptions: [
                {"value": "", "label": "Select"},
                {"value": "MULTIMEDIA", "label": "Multimedia"},
                {"value": "ANIMATION", "label": "Animation"},
                {"value": "AUDIO", "label": "Audio"},
                {"value": "GRAPHIC", "label": "Graphic"},
                {"value": "PHOTO", "label": "Photo"},
                {"value": "TEXT", "label": "Text"},
                {"value": "VIDEO", "label": "Video"},
          ]
        }
    }

  getData = () => {
    let params = "?"
    Object.keys(this.state.params).map((key) => {

      if(this.state.params[key] !== null && this.state.params[key] !== "" && this.state.params[key]?.length !== 0){
        params += key + "=" + this.state.params[key] + "&"
      }
    })
    EditorService.filterRawContent(params)
      .then(response => {
        if(response.status === 200){
          this.setState({
            content: response.data.results,
            count: response.data.count
          })
        }else{
          Toast('error', 'There was an error with the request. Please try again later.')
        }
      })
  }
    
  resetFilter = () => {
    this.setState({
      content: [],
      params: {
        country: "",
        experience: "",
        content_type: "",
        tags: [],
        state: "",
        city: "",
        zip_code: "",
        event_time: null,
        username: "",        
      }
    })
  }

  handlePerPage = e => {
    this.setState({
      rowsPerPage: parseInt(e.target.value)
    })
  }

  handleDelete = (i) => {
      this.setState(prevState => ({
       params: { ...prevState.params, tags: prevState.params.tags.filter((tag, index) => index !== i)}
      }));
  }
  
  handleAddition = (tag) => {
      this.setState(prevState => ({ params: {...prevState.params, tags: [...prevState.params.tags, tag.text] }}));
  }
  
  handleDrag = (tag, currPos, newPos) => {
      const tags = [...this.state.params.tags];
      const newTags = tags.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      this.setState(prevState => ({ params: {...prevState.params, tags: newTags } }));
  }

  handlePagination = page => {
    this.setState({
      currentPage: page.selected
    })
  }
  getSelectedCountriCode = () => {
    const country = csc.getAllCountries().find(item => item.name === this.state.params.country)
    return country?.isoCode
}

getSelectedState = () => {
    const state = csc.getStatesOfCountry(this.getSelectedCountriCode()).find(item =>                               
        item.name === this.state.params.state
    )
    return {value: state?.isoCode, label: state?.name}
}


getSelectedCity = () => {
    const city = csc.getCitiesOfCountry(this.getSelectedCountriCode()).find(item =>                               
        item.name === this.state.params.city
    )

    return {value: city?.isoCode, label: city?.name}
}


  CustomPagination = () => {
    const count = Number(Math.ceil((this.state.count / this.state.rowsPerPage)))

    return (
      <ReactPaginate
        pageCount={count || 1}
        nextLabel='&rarr;'
        breakLabel='...'
        previousLabel='&larr;'
        activeClassName='active'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        forcePage={this.state.currentPage}
        onPageChange={page => this.handlePagination(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end p-1'}
      />
    )
  }


      render() {
        return (
          <div>
            {/* <div className='invoice-list-wrapper'> */}
            <Drawer open={this.state.open} onClose={() => this.setState({ open: false })} anchor='right' >
              <FormControl component="fieldset">
                <CustomToast />
                <Box minWidth='45vh' margin='30px 0px 10px'>
                  <Box width='80%' margin='auto'>
                  <Box textAlign='center' marginBottom='20px'>
                      <Typography variant='h6'>Search for media</Typography>
                  </Box>
                  <Box component='div' className={this.props.classes.gridItem}>
                    <Select id="content-type" name="contentType" 
                    styles={customStyles}
                        value={this.state.contentOptions.find(item => item.value === this.state.params.content_type)}
                        options={this.state.contentOptions} 
                        onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, content_type: e.value} }))} />
                  </Box>
                  <Box component='div' className={this.props.classes.gridItem}>
                    <TextField
                        label="Username"
                        size='small'
                        variant="outlined"
                        name="username"
                        value={this.state.params.username}
                        onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, username: e.target.value} }))} 
                    />
                  </Box>
                  <Box component='div' width='full' className={this.props.classes.gridItem}>
                        <Select id="experience" name="experience" 
                            value={experienceOptions.find(item => item.value === this.state.experience)}
                            options={experienceOptions}
                            styles={customStyles} 
                        onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, experience: e.value} }))} />
                  </Box>
                  <Box component='div' className={this.props.classes.gridItem}>
                    <ReactTags tags={this.state.params.tags.map(item => {
                      return {text: item, id: item}
                    })}
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        handleDrag={this.handleDrag}
                        delimiters={delimiters} 
                        style={{ color: 'black !important' }}
                    />
                  </Box>
                    <Box component='div' width='full' className={this.props.classes.gridItem}>
                        <Box>
                            <Typography>Select a country*:</Typography>
                        </Box>
                            <Select 
                                options={this.state.countries}
                                value={this.state.params.country !== "" ? 
                                this.state.countries.find(item => item.label === this.state.params.country) : ""} 
                                styles={customStyles} 
                                onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, country: e.label, 
                                state: "", city: ""} }))} 
                            />
                    </Box>
                    <Box component='div' width='full' className={this.props.classes.gridItem}>
                        <Box mb={1}>
                            <Typography>State: </Typography>
                        </Box>
                        <Select 
                            options={csc.getStatesOfCountry(this.getSelectedCountriCode()).map(item => {
                                return {value: item.isoCode, label: item.name}
                            })}
                            value={this.getSelectedState()} 
                            name="state"
                            styles={customStyles} 
                            onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, state: e.label, city: ""} }))}  
                            />
                    </Box>
                    <Box component='div' width='full' className={this.props.classes.gridItem}>
                        <Box mb={1}>
                            <Typography>City: </Typography>
                        </Box>
                        <Select 
                            options={csc.getCitiesOfCountry(this.getSelectedCountriCode()).map(item => {
                                return {value: item.isoCode, label: item.name}
                            })}
                            value={this.getSelectedCity()} 
                            name="city"
                            id='city'
                            styles={customStyles} 
                            onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, city: e.label} }))}                  
                      />
                  </Box>
                  <Box component='div' className={this.props.classes.gridItem}>
                    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            size='small'
                            id="date-picker"
                            label="Media Date"
                            name="eventDate"
                            onChange={e =>  this.setState(prevState => ({ params: {...prevState.params, event_time: moment(e).format("MM/DD/YYYY")} }))}
                            value={this.state.params.event_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Box textAlign='center'>
                    <Box mb={1}>
                     <Button style={{ border: '1px solid black', backgroundColor: 'white', color: 'black'}}
                     size='small' onClick={this.resetFilter}>Reset Filters</Button>
                    </Box>
                    <Box>
                      <Button style={{ border: '1px solid black', backgroundColor: 'white', color: 'black'}}
                      onClick={this.getData}>Search</Button>
                    </Box>
                  </Box>
                  </Box>
                </Box>
              </FormControl>
            </Drawer>
            <Card>
            <Container style={{padding: '2% 2%',}}>
              <Card style={{ padding: '1% 3%', minHeight: '80vh', backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>        
                <Box mt={3}>
                  <Button className={this.props.classes.button} size='large'
                  onClick={() => this.setState(prevState => ({ open: !prevState.open }))}>Search</Button>
                </Box>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  subHeader={true}
                  columns={columns}
                  responsive={true}
                  sortIcon={<ChevronDown />}
                  className='react-dataTable'
                  defaultSortField='invoiceId'
                  paginationDefaultPage={this.state.currentPage}
                  paginationComponent={this.CustomPagination}
                  data={this.state.content}
                />
              </Card>
              </Container>
            </Card>
          </div>
        ) 
      }
}

export default  withStyles(styles)(EditorSearchPage)
