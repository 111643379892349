import { Component } from 'react';
import validator from 'validator';
import ContributorsService from '../../services/contributorsService';
import RawContentForm from "./RawContentForm";
import { Container, Box } from '@material-ui/core'
import { CustomToast, Toast } from '../../components/toastify'

class EditPage extends Component{
    constructor(props) {
        super(props)

        this.state = {
            data: null,
        }

      }

    componentDidMount(){
        if(this.props.location.data){
            this.setState({ data: this.props.location.data })
        }else{
          ContributorsService.getContentById(this.props.location.pathname.split('/')[3])
          .then(result => {
              if(result.status === 200){
                  this.setState({
                      data: result.data,
                  })
              }else{
                  Toast('error', "There was an error with the request. Please try again later.")
              }

          })
        }
    }


    handlePatch = (e, data) => {
        e.preventDefault();

        if(
            data.content_type.value !== "" &&
            data.tags?.length !== 0 &&
            validator.isURL(data.link) &&
            data.copyright === true &&
            data.raw_content_address.country !==  "" &&
            data.raw_content_address.state !== "" &&
            data.raw_content_address.city !== ""
        ){
            ContributorsService.patchContent(data).then(response => {
                if (response.status === 200) {
                    this.props.history.push("/contributor/content/")
                } else {
                    Toast('error', response.data.error)
                }
            })
        } else {
            Toast('error', 'Please fill out all of the required fields.')
        }
    }  

    render() {
        return (
            <Container style={{ padding: '2% 3%' }}>
                <CustomToast />
                <Box component='div' p={2} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    {this.state.data && <RawContentForm handleUpload={this.handlePatch} data={this.state.data} isEdit={true}/>}
                </Box>
            </Container>
        )
    }
}

export default EditPage;