import { useEffect, useState, useRef } from "react";
import BlogService from "../../services/blogService";
import ArticleComments from './ArticleComments';
import { CardMedia, Typography, Box, Container  } from '@material-ui/core';
import { CustomToast } from '../toastify'
import '../../styles/blog-article.css'
import TagsComponent from "./TagsComponent";


const BlogArticle = (props) => {
    const [data, setData] = useState(null)
    const blogContent = useRef(null);

    useEffect(() => {
      BlogService.getArticleBySlug(window.location.pathname.split('/')[2])
      .then(result => {
              if(result.status === 200){
                  setData(result.data)
                  blogContent.current.innerHTML = result.data.content
              }else if(result.status === 404){
                  props.history.push("/developing")
              }
        })
    }, [])

    return (
      <Container maxWidth='md'>
        <CustomToast />
        <Box maxWidth='md' pt={5} pb={3} style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
            {data &&
              <Box component='div' minHeight='100vh'>
                    <Box alignContent='center' maxWidth='80%' margin='auto'>
                      <Box textAlign='center'>
                        <Typography variant="h4" gutterBottom style={{ overflowWrap: 'break-word', color: 'white' }}>
                          {data?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <CardMedia image={data.featured_image} style={{ width: '100%', height: '40vh'}}/>
                      </Box>
                      <Box component='div' textAlign='left' padding='30px 10px'>
                        <Typography variant="body1"  style={{overflowWrap: 'break-word', color: 'white'}} >
                          <span ref={blogContent}></span>
                        </Typography>
                      </Box>
                      <TagsComponent tags={data.tags} />
                      <Box component='div' fullWidth borderTop='1px solid silver' padding='15px 15px'>
                        <Typography variant='h5' style={{color: 'white'}}>Comments:</Typography>
                        <Box width='80%' margin='auto'>
                          <ArticleComments articleId={data.id} commentsList={data.comments}/>
                        </Box>
                      </Box>
                    </Box >
              </Box>}
        </Box>
      </Container>
    )
}

export default BlogArticle;