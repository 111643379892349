import { Component } from 'react';
import authService from '../services/authService';

import Form from "react-validation/build/form";
import SignUpForm from '../components/SignUpComponents/SignUpForm';
import { Avatar, Link, Grid, Typography, withStyles, CssBaseline, Box, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { CustomToast, Toast } from '../components/toastify'

const styles = theme => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: '2% 10%',
      color: 'white'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#fed108',
        color: 'black'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
});


class SignupPage extends Component{
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            message: "",
            loading: false,
            activeStep: 1,
        }
      }
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }


    requiredValidator = value => {
        if (!value) {
          return (
            <div className="alert alert-danger" role="alert">
              This field is required!
            </div>
          );
        }
    };

    handleSignUp = (e) => {
        e.preventDefault();
        
        this.setState({
            loading: true
        }, () => {
            this.form.validateAll();
            
            if (this.checkBtn.context._errors?.length === 0) {
                authService.register(this.state.email, this.state.password) 
                .then(response => {
                    if(response.status >= 400){
                        Toast('error', response.data?.error)
                        this.setState({
                            loading: false,
                        });
                    }

                    this.props.history.push("/login");
                })
            }

            this.setState({
                loading: false
            })
        })
           
    }  

    
    render() {
        return (
            <Container component="main" maxWidth="lg"  style={{ padding: '3% 3%' }}>
            <CustomToast />
            <CssBaseline />
                <Box className={this.props.classes.paper}>
                    <Avatar className={this.props.classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4">
                        Sign Up
                    </Typography>
                    <Form className={this.props.classes.form}>
                        <SignUpForm/>
                    </Form>
                    <Grid container>
                      <Grid item xs>
                      </Grid>
                      <Grid item>
                        <Link href="/login" variant="button">
                            <Typography variant='textSecondary' style={{ color: 'white' }}>
                                Log In 
                            </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }
}

export default withStyles(styles)(SignupPage);