// ** React Imports
import { Link } from 'react-router-dom'
import ContributorService from '../../../services/contributorsService'
// ** Custom Components
import moment from 'moment'
// ** Third Party Components
import {
  Edit,
  Trash
} from 'react-feather'

import { Chip, Box, Button } from '@material-ui/core'

const deleteRaw = rawId => {
  ContributorService.deleteContent(rawId)
  .then(result => {
    if(result.status === 204){
      window.location.reload()
    }
  })
}

// ** Table columns
export const columns = [
  {
    name: 'Media Type',
    minWidth: '100px',
    selector: 'content_type',
    sortable: true,
    cell: row =>  <span><strong>{row.content_type || "Unknown"}</strong></span>
  },
  {
    name: 'Tags',
    selector: 'tags',
    sortable: true,
    minWidth: '100px',
    cell: row => 
    <Box component='div'>
      {row.tags.map(tag => {
        return <Box component='div' fullWidth><Chip label={tag}></Chip></Box>
      })}
    </Box>
  },
  {
    name: 'Location',
    selector: 'address',
    sortable: true,
    minWidth: '100px',
    cell: row => {
      let address = ""
      Object.keys(row?.raw_content_address).map((key, index) =>{
        if(row.raw_content_address[key] && (row.raw_content_address[key] !== "" && key !== 'zip_code')){
          address += row.raw_content_address[key]
          address += ', '
        }
      })
      address = address.substring(0, address.length - 2)
      return address?.length > 30 ? address.substring(0, 30) + '...' : address
    }
  },
  {
    name: 'Media Time',
    selector: 'eventTime',
    sortable: true,
    minWidth: '150px',
    cell: row => moment(row.event_time).format('MM/DD/YYYY hh:mm')
  },
  {
    name: 'Link',
    selector: 'link',
    sortable: true,
    minWidth: '150px',
    cell: row => <Button size="small" color="primary" href={row.link} target="_blank">Media Link</Button>
  },
  {
    name: 'Date Posted',
    selector: 'createdAt',
    sortable: true,
    minWidth: '150px',
    cell: row => moment(row.created_at).format('MM/DD/YYYY hh:mm')
  },
  {
    name: 'Action',
    minWidth: '110px',
    selector: '',
    sortable: true,
    cell: row => (
      <div className='column-action d-flex align-items-center'>
        <Link to={{
          pathname: `/contributor/edit/${row.id}`,
          data: row
        }}>
          <Edit size={14} className='mr-50' />
        </Link>
        <Link>
          <Trash size={14} className='mr-50' onClick={() => deleteRaw(row.id)}/>
        </Link>
      </div>
    )
  }
]
