import Form from "react-validation/build/form";
import { makeStyles, withStyles, Box, FormControlLabel, MenuItem, Select, InputBase, TextField } from '@material-ui/core';
import SubscriptionForm from "../SubscriptionComponents/SubscriptionForm"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useEffect, useState } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CssTextField = withStyles({
  root: {
    '& label': {
        color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fed108',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'black',
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fed108',
      },
    },
  },
})(TextField);


const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      color: 'white',
      position: 'relative',
      backgroundColor: 'black',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
      },
    margin: {
        margin: theme.spacing(1),
    },
}));

const UserTypeForm = ({ setPaymentMethodCallback, role, setNumberToken, username, setRole, setUsername, numberTokens }) => {
    const [type, setType] = useState("");

    useEffect(() => {
      if(type !== "SUBSCRIBER"){
        setRole(type)
      }
    }, [type])


    return (
                <Form>       
                    <Box component="div" m={1}>
                    <CssTextField
                            type="text"
                            className="form-control"
                            name="username"
                            variant="outlined"
                            margin="normal"
                            size='large'
                            required
                            id="username"
                            label="Username"
                            style={{ maxWidth: '40%', width: '40%'}}
                            autoFocus
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </Box>

                    <Box component="div" m={1}>
                      <FormControlLabel
                      labelPlacement="top"
                      control={
                          <Select
                          value={type}
                          style={{width: '12em', marginBottom: '20px', backgroundColor: 'black'}}
                          onChange={e => setType(e.target.value)}
                          input={<BootstrapInput/>}
                          >   
                            <MenuItem value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={"CONTRIBUTOR"}>Contributor</MenuItem>
                            <MenuItem value={"EDITOR"}>Editor</MenuItem>
                            <MenuItem value={"SUBSCRIBER"}>Subscriber</MenuItem>
                          </Select>}
                          label="Choose an Account Type"
                      />
                      </Box>

                    {type === "SUBSCRIBER" && (
                    <Elements stripe={stripePromise}>
                      <SubscriptionForm setPaymentMethodCallback={setPaymentMethodCallback} setNumberToken={setNumberToken} 
                        role={role} setRole={setRole} numberTokens={numberTokens}/>
                    </Elements>
                    )}
                </Form>
    )
}

export default UserTypeForm;