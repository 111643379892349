import { ToastContainer, toast } from 'react-toastify';

const CustomToast = () => {
    return (
            <ToastContainer
            position="top-right"
            autoClose={2000}
            draggablePercent={60}
            limit={3}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
    )
}

const Toast = (type, content) =>{
    if(type === "success"){
     return toast.success(content, {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else if(type === "error"){
        return toast.error(content, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
}
export { CustomToast, Toast}