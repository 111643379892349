// ** React Imports
import { Component } from 'react'
// ** Table Columns
import { columns } from './columns'

// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import DataTable from 'react-data-table-component'
import { Label, CustomInput, Row, Card } from 'reactstrap'
import { CustomToast, Toast } from '../../../components/toastify'

import '../../../styles/scss/react-dataTable-component.scss'
import '../../../styles/pagination.css'
import EditorService from "../../../services/editorsService";
import { Box, Container, Grid, Typography } from '@material-ui/core';

const CustomHeader = ({ handlePerPage, rowsPerPage }) => {
 return (
   <Box component='div' fullWidth width='100%' marginBottom={1.5}>
     <Grid container>
     <Grid item lg={3} md={3} style={{marginRight: '6%'}}>
       <Box component='div' margin='auto'> 
         <Typography style={{fontSize: '1.5em', color: 'white'}}>Ratings</Typography>
       </Box>
     </Grid>
     <Grid  item lg={8} md={8}>
       <Row component='div'>
         <Grid container lg={12} md={12}> 
                   <Box className='d-flex align-items-center mr-2' margin='auto'>
                     <Box component='div' fullWidth marginBottom={1} >
                       <Label for='rows-per-page' style={{color: 'white'}}>Show Per Page</Label>
                     </Box>
                     <CustomInput
                       type='select'
                       id='rows-per-page'
                       value={rowsPerPage}
                       onChange={handlePerPage}
                       style={{ borderRadius: '5px', padding: '5px 7px', borderColor: 'silver', outline: 'none'}}
                     >
                         <option value='10'>10</option>
                         <option value='25'>25</option>
                         <option value='50'>50</option>
                     </CustomInput>
                   </Box>
             </Grid> 
       </Row>
     </Grid>
     </Grid>
   </Box>
 )
}


class EditorListPage extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            id: null,
            content:[],
            count: 0,
            currentPage: 0,
            rowsPerPage: 10,
        }

    }
    
    getData = () => {
      if(!this.state.content.can_rate){
        EditorService.getRatings(this.state.id, this.state.rowsPerPage, this.state.currentPage)
        .then(response => {
            if(response.status === 200){
                this.setState({
                    content: response.data.results,
                    count: response.data.count
                })
            }else{
                Toast('error', "There was an error with the request. Please try again later.")
            }

        })
      }
    }

    componentDidMount() {
      this.setState({ id: this.props.id }, () => this.getData())
    }


    handlePerPage = e => {
      this.setState({
        rowsPerPage: parseInt(e.target.value)
      }, () => this.getData())
    }

    
    handlePagination = page => {
      this.setState({
        currentPage: page.selected
      }, () => this.getData())
    }

    CustomPagination = () => {
      const count = Number(Math.ceil((this.state.count / this.state.rowsPerPage)))


      return (
        <Box component='div' className='pagination'>
          <ReactPaginate
            pageCount={count}
            nextLabel='&rarr;'
            breakLabel='...'
            previousLabel='&larr;'
            activeClassName='active'
            forcePage={this.state.currentPage}
            onPageChange={page => this.handlePagination(page)}
          />
        </Box>
      )
    }

      render() {
        return (
          <Container>
            <menu/>
            <CustomToast />
            <Card style={{marginTop: '40px'}}>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  subHeader={true}
                  columns={columns}
                  responsive={true}
                  sortIcon={<ChevronDown />}
                  className='react-dataTable'
                  defaultSortField='invoiceId'
                  paginationDefaultPage={this.state.currentPage}
                  paginationComponent={this.CustomPagination}
                  data={this.state.content}
                  subHeaderComponent={
                    <CustomHeader
                    rowsPerPage={this.state.rowsPerPage}
                    handlePerPage={this.handlePerPage}
                  />
                  }
                />
            </Card>
          </Container>

        ) 
      }
}

export default EditorListPage
