import { Component } from "react";
import AuthService from '../services/authService'
import { Button, Box, Grid, Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RoleDialog from "../components/ProfileComponents/RoleDialog"
import { CustomToast, Toast} from '../components/toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubscriptionInfo from "../components/SubscriptionComponents/SubscriptionInfo"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ProfileForm from '../components/ProfileComponents/ProfileForm'
import Payouts from '../components/ProfileComponents/Payouts'
import PayoutServices from '../services/payoutServices'
import RedirectDialog from '../components/ProfileComponents/RedirectDialog'
import ChangePasswordDialog from '../components/ProfileComponents/ChangePassword'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const allRoles =  [
    {"value": "EDITOR", "label": "Editor"},
    {"value": "CONTRIBUTOR", "label": "Contributor"},
    {"value": "SUBSCRIBER", "label": "Subscriber"},
]

const experienceOptions = [
    {"value": "LESS_THAN_1", "label": "Less than 1 year"},
    {"value": "ONE_TWO_YEARS", "label": "1-2 Years"},
    {"value": "TWO_FIVE_YEARS", "label": "2-5 Years"},
    {"value": "FIVE_YEARS_MORE", "label": "5 Years or more"},
]

const styles = theme => ({ 
  button: {
    padding: '0px',
    color: '#fed108',
    backgroundColor: 'black',
    border: '1px solid #fed108',
    '& span':{
      padding: '8px 15px'
    },
    '& :hover':{
      color: 'black',
      backgroundColor: '#fed108'
    }
  }
})

class ProfilePage extends Component{
    constructor(props) {
        super(props)

        this.state ={
          data: null,
          open: false,
          openPayouts: false,
          openRedirect: false,
          openChangePassword: false
        }
    }

    componentDidMount(){
      AuthService.getProfile()
      .then(result => {
        if(result.status === 200){
          let experience = experienceOptions.find(item => item.value === result.data.experience)
          let roles = []
          result.data.role.map(item => {
            if(item.role_name === "SUB_FREE" || item.role_name === "SUB_SUBSCRIPTION" || item.role_name === "SUB_PERVIEW"){
              roles.push(this.findRole("SUBSCRIBER"))
            }else{
              if(this.findRole(item.role_name)){
                roles.push(this.findRole(item.role_name))
              }
            }
          })
          this.setState({ data: {...result.data, experience: experience, role: roles} })
          }
      })
      
      if(this.props.history.location.state){
        Toast(this.props.location.state.type, this.props.location.state.message)
      }
    }

    setSubscription = (sub) => {
      this.setState({ subscription: sub })
    }
    
    findRole = (role) => {
      return allRoles.find((element) => {
        return element.value === role 
      })
    }

    enablePayouts = () => {
      PayoutServices.enablePayouts().then(result => {
        if(result.status === 200){
            this.setState({ openRedirect: true }, () => {
              setTimeout(function() { 
                window.location.replace(result.data?.url);
              }, 3000)
            })
            
        }else{
          Toast("error", result.data?.error)
        }
      })
    }

    setPayoutAmount = (payout) => {
      this.setState(prevState => ({
        data: {...prevState.data, "payout": payout}
      }))
    }

    roleButton = () => {
      this.setState(prevState=> ({ open: !prevState.open }))
    }

    isUserSubscriberOnly = () => {
      const roles = localStorage.getItem('roles').split(',')
      if(roles && roles?.length === 1 && (roles[0] === "SUB_SUBSCRIPTION" || roles[0] === "SUB_FREE" || roles[0] === "SUB_PERVIEW")){
          return true
      }
      return false
    }

    isUserEditContrib = () => {
      const roles = localStorage.getItem('roles').split(',')
      if(roles && roles?.length === 2){
        if(roles.some(item => item === "EDITOR") && roles.some(item => item === "CONTRIBUTOR")){
          return true
        }
      }else if(roles && roles?.length === 1 && (roles.some(item => item === "EDITOR") || roles.some(item => item === "CONTRIBUTOR"))){
        return true
      }
      return false
    }

      render() {
          return(
            <>{this.state.data !== null &&
              <Box component='div' pt={5} pb={5} pl={10} pr={8}>
                <Grid container lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white' }}>
                    <CustomToast />
                    
                    <Grid item lg={2} md={2} sm={12} xs={12}><Box></Box></Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}
                    >
                      <Box minHeight='40%' component='div' padding='5%'>
                      {!this.isUserSubscriberOnly() &&
                        <Box border={1} borderRadius={30} borderColor={'silver'} p={4} marginBottom={3}>
                        {this.state.data.role.some(item => item.value === 'EDITOR' || item.value === 'CONTRIBUTOR') ? 
                        <div>
                          <Typography variant='h6'>Payouts</Typography>
                          <Box component='div' marginBottom={2}>
                            <Chip
                            label={<Typography>Total Amount: ${this.state.data?.payouts.amount}</Typography>}
                            style={{margin: '3px 5px', borderColor: '#fed108', color: 'white'}}
                            variant='outlined'>   
                            </Chip>
                          </Box>
                          {this.state.data.role.some(item => item.value === "EDITOR" || item.value === 'CONTRIBUTOR') && this.state.data?.payouts.enabled &&
                            <Box>
                              <Button className={this.props.classes.button} size='small'
                                onClick={() => this.setState({ openPayouts: true })}>Request payout</Button>
                            </Box>
                          }
                          {this.state.data.role.some(item => item.value === "EDITOR" || item.value === 'CONTRIBUTOR') && !this.state.data?.payouts.enabled &&
                            <Box>
                              <Typography>Payouts are not currently enabled.</Typography>
                              <Button size='small' className={this.props.classes.button}
                               onClick={this.enablePayouts}>Enable Payouts</Button>
                            </Box>
                          }
                          <Payouts onClose={() => this.setState(prevState => ({ openPayouts: !prevState.openPayouts}))} open={this.state.openPayouts}
                          flag={this.state.data?.payouts.enabled} payouts={this.state.data?.payouts} setPayoutAmount={this.setPayoutAmount}/>
                          <RedirectDialog open={this.state.openRedirect}/>
                          </div> :
                          <Typography style={{color: 'silver'}}>You need to be an editor or contributor to view this.</Typography>}
                        </Box>}
                        
                        {!this.isUserEditContrib() &&
                        <Box minHeight={'40%'} border={1} borderRadius={30} borderColor={'silver'} p={4}>
                          <Box> 
                            <Typography variant='h6'>Subscription</Typography>
                            {localStorage.getItem('roles').split(',').includes('SUB_SUBSCRIPTION') && <Typography>Monthly</Typography>}
                            {localStorage.getItem('roles').split(',').includes('SUB_FREE') && <Typography>Free</Typography>}
                            {localStorage.getItem('roles').split(',').includes('SUB_PERVIEW') && <Typography>Per View</Typography>}
                            <Elements stripe={stripePromise}>
                              <SubscriptionInfo subscription={this.state.data.subscription}  roleButton={this.roleButton}/>
                            </Elements>
                          </Box>                        
                        </Box>}
                      </Box>
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} xs={12}>
                      <Box component='div' padding='5%'>
                        <Typography variant='h6' style={{marginBottom: '3%'}}>Profile Details</Typography>
                        <ProfileForm handleChange={this.handleChange}
                        oldData={this.state.data} roleButton={this.roleButton}/>
                        <RoleDialog open={this.state.open} subscription={this.state.data.subscription}
                        onClose={() => this.setState(prevState=> ({ open: !prevState.open }))} rolesProps={this.state.data?.role}/>
                      <Button style={{marginTop: '10px'}} className={this.props.classes.button}
                        onClick={() => this.setState({ openChangePassword: true })}>
                        Change Password
                      </Button>
                      <ChangePasswordDialog open={this.state.openChangePassword} 
                      onClose={() => this.setState(prevState => ({ openChangePassword: !prevState.openChangePassword }))} />
                      </Box> 
                    </Grid> 
                    <Grid item lg={2} md={2} sm={12} xs={12}><Box></Box></Grid>
                  </Grid>
                </Box>
              }</>
          )}

} 

export default withStyles(styles)(ProfilePage);
