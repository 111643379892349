import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Component } from 'react';
import ContentView from '../components/ContentComponents/ContentView';
import { CustomToast, Toast } from '../components/toastify';
import ContentService from '../services/contentService';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            hover: [false, false, false, false, false],
            open: false,
            selectedContent: null,
        };
    }

    componentDidMount() {
        ContentService.getContent('top-5=true&', '', '1').then((response) => {
            if (response.status === 200) {
                this.setState({
                    data: response.data.results,
                });
            } else {
                Toast('error', 'There was an error with the request.');
            }
        });
        this.openStory();
    }

    openStory = () => {
        const search = new URLSearchParams(this.props.location.search);
        const contentId = search.get('contentId');
        if (!contentId) {
            return;
        }
        ContentService.getContent(`id=${contentId}&`, '', '1').then((response) => {
            if (response.status === 200) {
                this.setState({ selectedContent: response.data.results[0], open: true });
            } else {
                Toast('error', 'There was an error with the request.');
            }
        });
    };

    redirectStory = (item) => {
        this.setState({
            open: true,
            selectedContent: item,
        });
    };

    onClose = () => {
        this.setState((prevState) => ({ open: !prevState.open }));
    };

    updateHover = (index, value) => {
        let hoverArr = this.state.hover;
        hoverArr.forEach((item, index1) => {
            if (index1 === index) {
                hoverArr[index] = value;
            }
        });
        this.setState({ hover: hoverArr });
    };

    addRatingData = (data) => {
        this.setState((prev) => ({ ...prev, selectedContent: data }));
    };

    render() {
        return (
            <Container maxWidth="xl">
                <ContentView
                    open={this.state.open}
                    onClose={this.onClose}
                    data={this.state.selectedContent}
                    setData={this.addRatingData}
                />
                <CustomToast />
                <Box pt={2} pb={2}>
                    <Box m={2}>
                        <Typography variant="h5" style={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                            LATEST STORIES
                        </Typography>
                    </Box>
                    <Grid container lg={12} md={12} sm={12} xs={12}>
                        <Grid
                            item
                            lg={3}
                            md={5}
                            sm={5}
                            xs={12}
                            style={{ margin: '15px auto', minWidth: '380px', backgroundColor: 'rgba(0,0,0,0.9)' }}
                        >
                            {this.state.data &&
                                this.state.data.map((item, index) => {
                                    return (
                                        <Box
                                            borderTop="0.5px solid grey"
                                            paddingTop={3}
                                            paddingBottom={6}
                                            paddingLeft={2}
                                            paddingRight={2}
                                            onClick={() => this.redirectStory(item)}
                                        >
                                            <Box
                                                width="80%"
                                                margin="auto"
                                                textAlign="center"
                                                mb={3}
                                                style={{ overflowWrap: 'break-word' }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: 'white',
                                                        fontFamily: '"Open Sans",arial,sans-serif',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                            <Box width="100%">
                                                <img
                                                    style={
                                                        this.state.hover[index]
                                                            ? {
                                                                transition: 'filter .3s ease-out',
                                                                webkitTransform: 'translateZ(0px)',
                                                            }
                                                            : {
                                                                filter: 'grayScale(100)',
                                                                transition: 'filter .3s ease-out',
                                                                webkitTransform: 'translateZ(0px)',
                                                            }
                                                    }
                                                    onMouseEnter={() => this.updateHover(index, true)}
                                                    width="100%"
                                                    src={item.thumbnail}
                                                    onMouseLeave={() => this.updateHover(index, false)}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Grid>
                        <Grid item lg={5} md={4} sm={12} xs={12}></Grid>
                    </Grid>
                </Box>
            </Container>
        );
    }
}

export default HomePage;
