import axios from './custom-axios';
import AuthService from './authService';

class SubscriptionService {
    subscribe(email, paymentPayload){
        if(email !== null && email !== ""){
            return axios.post("/stripe/subscription/", {"email": email, ...paymentPayload})
        }else{
            return axios.post("/stripe/subscription/", paymentPayload,  { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
        }
    }

    chargeCredits(email, number_tokens, paymentPayload){
        if(email !== null && email !== ""){
            return axios.post("/stripe/charge/", {"email": email, "number_tokens": number_tokens, ...paymentPayload})
        }else{
            return axios.post("/stripe/charge/", {"number_tokens": number_tokens, ...paymentPayload},  { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
        }
    }

    cancelSubscription(){
        return axios.delete("/stripe/subscription/",  { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    reactivateSubscription(){
        return axios.patch("/stripe/subscription/", {}, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    getPaymentMethod(){
        return axios.get("/stripe/subscription/payment_method/", { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    addPaymentMethod(paymentPayload){
        return axios.patch("/stripe/subscription/payment_method/", paymentPayload, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }
}
  
  export default new SubscriptionService();
