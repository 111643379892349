import { Component } from 'react';

import authService from "../services/authService"

class LogoutPage extends Component{
    componentDidMount(){
        authService.logout().then(response => {
            localStorage.removeItem('vettageToken');
            this.props.history.push('/login');
        })          
    }

    render(){
        return null;
    }
}

export default LogoutPage;