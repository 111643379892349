import { Component } from 'react';

import validator from 'validator';
import ContributorsService from '../../services/contributorsService';
import RawContentForm from "./RawContentForm";
import { Container, Box } from '@material-ui/core'
import { CustomToast, Toast } from '../../components/toastify'

class UploadPage extends Component{
    constructor(props) {
        super(props)
    }


    handleUpload = (e, data) => {
        e.preventDefault();

        if(
            data.content_type !== "" &&
            data.tags?.length !== 0 &&
            validator.isURL(data.link) &&
            data.copyright === true &&
            data.raw_content_address.country !==  "" &&
            data.raw_content_address.state !== "" &&
            data.raw_content_address.city !== ""
        ){
            ContributorsService.uploadContent(data).then(response => {
                if (response.status === 201) {
                    this.props.history.push("/contributor/content/")
                } else {
                    Toast('error', response.data.error)
                }
            })
        } else {
            Toast('error', 'Please fill out all of the required fields.')
        }
    }  

    render() {
        return (
            <Container style={{ padding: '2% 3%' }}>
                <CustomToast />
                <Box component='div' p={2} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <RawContentForm handleUpload={this.handleUpload}/>
                </Box>
            </Container>
        )
    }
}

export default UploadPage;