import Header from './Header'
import Footer from './Footer'
import { Component } from 'react';
import { Box } from '@material-ui/core'
import BackgroundSlider from 'react-background-slider'
import image1 from '../../static/image1.jpg'
import image2 from '../../static/image2.jpg'
import image3 from '../../static/image3.jpg'
import image4 from '../../static/image4.jpg'
import bgImage from '../../static/pat.png'

import '../../styles/styles.css'

class Layout extends Component{ 
    constructor(props){
        super(props)

    }
    
    render(){
        return (
            <div>
                <Header/>
                    <Box position='relative' style={this.props.location.pathname !== "/" 
                    ? { backgroundColor: '#282828' } : { backgroundImage: `url(${bgImage})`, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        {this.props.location.pathname === "/" && 
                            <BackgroundSlider className="slider"
                                images={[image1, image2, image3, image4]}
                                duration={10} transition={2} />}
                        <this.props.component {...this.props}/>
                    </Box>
                <Footer/>
            </div>
        )
    }
}

export default Layout;
