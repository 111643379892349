import axios from './custom-axios';

class AuthService {
    login(email, password) {
      return axios
        .post("/account/login/", {
            "email": email,
            "password": password
        })
    }
  
    logout() {
      return axios
      .post("/account/logout/", {}, { headers: this.authHeader()})
    }
  
    register(email, password, username, role, paymentPayload) {
      return axios.post("/account/registration/", {
        "email": email,
        "password1": password,
        "password2": password,
        "username": username,
        "role": role,
        "payment_payload": paymentPayload
      });
    }

    checkEmail(email){
      return axios.post("/account/email/", { 
        "email": email
      });
    } 

    checkUsername(username){
      return axios.post("/account/username/", { 
        "username": username
      });
    }

    getProfile(token){
      return axios.get("/account/user/", { validateStatus: status => {return status < 500}, headers: token ? token : this.authHeader() });
    }

    patchProfile(form){

      return axios.patch("/account/user/", {
        "username": form.username,
        "user_address": {
          "country": form.user_address.country,
          "state": form.user_address.state,
          "city": form.user_address.city,
          "zip_code": form.user_address.zip_code,
        },
      }, { validateStatus: status => {return status < 500}, headers: this.authHeader() });
    }


    resetPassword(email){
      return axios.post("/account/password/reset/", { 
        "email": email
      }, {});
    }

    confirmResetPassword(password, uuid, token){
      return axios.post("/account/password/reset/confirm/", { 
        "new_password1": password,
        "new_password2": password,
        "uid": uuid,
        "token": token
      }, {});
    }

    changePassword(password){
      return axios.post("/account/password/change/", { 
        "new_password1": password,
        "new_password2": password
      }, { validateStatus: status => {return status < 500}, headers: this.authHeader() });
    }

    addRoles(roles){
      return axios.patch("/account/user/", {
        "role": roles
      }, { validateStatus: status => {return status < 500}, headers: this.authHeader() });
    }

    confirmEmail(id){
        return axios.get("/account/account-confirm-email/" + id + "/", { validateStatus: status => {return status < 500}});
    }

    authHeader() {
      const vettageToken = localStorage.getItem('vettageToken');

      if (vettageToken) {
        return { "Authorization": 'Token ' + vettageToken };
      } else {
        return {};
      }
    }   
  }
  
  export default new AuthService();
