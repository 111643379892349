

import { useState } from 'react'
import { Dialog, TextField, Backdrop , Button, Box, Typography, IconButton, CircularProgress, makeStyles  } from '@material-ui/core';
import AuthService from '../../services/authService'
import Form from "react-validation/build/form";

import { CustomToast, Toast} from '../toastify';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box flexWrap="wrap">
          <Typography style={{wordBreak: "break-all"}} variant="h6">{children}</Typography>
      </Box>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
      padding: theme.spacing(2),
  },
  }))(MuiDialogContent);
  

const ChangePasswordDialog = ({ onClose, open }) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const changePassword = e => {
        e.preventDefault()
        setLoading(true)

        if(password?.length <= 8 || confirmPassword?.length >= 40){
            Toast("error", "Password is not valid. The length must be between 8 and 40 characters.")
            return false
        }
        if(password !== confirmPassword){
            Toast("error", "The two passwords don't match.")
            return false
        }

        AuthService.changePassword(password)
        .then(response => {
            if(response.status === 200){
                Toast('success', "Your password has been changed.")
            }else{
                Toast('error', "There was an error with the request. Please try again later.")
            }
        })
        setPassword("")
        setConfirmPassword("")
        onClose()
        setLoading(false)
    }
   


    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" maxWidth='sm' open={open}>
          <Backdrop style={{
             zIndex: 1,
             color: '#fff',
          }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box component="div" flexWrap="wrap" justifyContent="center" fullWidth alignItems="center" marginBottom={3} p={{ xs: 2, sm: 3, md: 4 }} minHeight='20vh'>
            <Box component='div' textAlign='center'>
              <DialogTitle id="dialog-title" onClose={onClose}>Change Password</DialogTitle>
            </Box>
            <CustomToast/>
                <DialogContent dividers>
                <Form
                    onSubmit={changePassword}
                >
                    <TextField
                        type="password"
                        name="password"
                        margin="normal"
                        variant='outlined'
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        autoComplete="password"
                        autoFocus
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextField
                        type="password"
                        name="confirm-password"
                        margin="normal"
                        variant='outlined'
                        required
                        fullWidth
                        id="confirm-password"
                        label="Confirm Password"
                        autoComplete="password"
                        autoFocus
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <Box textAlign='center'>
                      <Button
                        type="submit"
                        size='large'
                        style={{ border: '1px solid black', borderRadius: '0px', color: 'black' }}
                        disabled={loading}
                      >
                          <span>Change Password</span>
                      </Button>
                    </Box>
                  </Form>
                </DialogContent>
            </Box>
        </Dialog>
    );
}

export default ChangePasswordDialog;