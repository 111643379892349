import { ContentType } from '../utilities/content-type';
import AuthService from './authService';
import axios from './custom-axios';

class ContentService {
    getExtensions(contentType) {
        return contentType === ContentType.Audio ? ['mp3', 'wav', 'ogg'] : ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    }

    getContent(params, limit, page) {
        return axios.get('content/final/?' + params + 'page=' + page + '&limit=' + limit, {
            validateStatus: (status) => {
                return status < 500;
            },
            headers: AuthService.authHeader(),
        });
    }

    rateContent(finalContentId, newsworthiness, credibility, timeliness, designQuality) {
        return axios.post(
            'content/' + finalContentId + '/ratings/',
            {
                newsworthiness: newsworthiness / 10,
                credibility: credibility / 10,
                timeliness: timeliness / 10,
                design_quality: designQuality / 10,
            },
            {
                validateStatus: (status) => {
                    return status < 500;
                },
                headers: AuthService.authHeader(),
            }
        );
    }

    checkPermission(final_content_id) {
        return axios.get('content/permission/', {
            params: { final_content_id },
            validateStatus: (status) => {
                return status < 500;
            },
            headers: AuthService.authHeader(),
        });
    }
}

export default new ContentService();
