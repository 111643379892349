export const ContentType = {
    Multimedia: 'MULTIMEDIA',
    Animation: 'ANIMATION',
    Audio: 'AUDIO',
    Graphic: 'GRAPHIC',
    Photo: 'PHOTO',
    Text: 'TEXT',
    Video: 'VIDEO',
}

export const CONTENT_TYPE_OPTIONS = Object.entries(ContentType).map(([label, value]) => ({ value, label }));
