import axios from './custom-axios';
import AuthService from './authService';

class PayoutServices {

    enablePayouts(){
        return axios
        .get("/stripe/account/", { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    callback(success){
        return axios
        .get("/stripe/account/?success=" + success, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    makePayout(payload){
        return axios
        .post("/stripe/payout/", payload, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }
}

export default new PayoutServices();