import React from "react";
import { useState, useEffect } from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  TextField,
  Container,
  Box,
  List
} from "@material-ui/core";
import moment from 'moment';
import BlogService from '../../services/blogService';
import { Toast } from '../toastify'

const CssTextField = withStyles({
  root: {
      '& label': {
          color: 'white'
      },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fed108',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fed108',
      },
    },
  },
})(TextField);


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  fonts: {
  },
  inline: {
    display: "inline"
  }
}));


const CommentReplies = ({ commentId, listReplies, showReply, setShowReply}) => {
  const classes = useStyles();
  const [patch, setPatch] = useState(false)
  const [newReply, setNewReply] = useState("")
  const [patchReplyContent, setPatchReply] = useState("")
  const [replies, setReplies] = useState([])

  const postReply = () => {
    if(newReply === ""){
      Toast("error", "The reply can't be empty.")
      return;
    }
    BlogService.postCommentReply(commentId, newReply)
    .then(result => {
        if(result.status === 201){
          setReplies([...replies, result.data])
          setNewReply("")
          setShowReply("")
          Toast("success", "The reply has been posted.")
        }else{
          Toast("error", result.data?.error)
        }
    })
  }

  const deleteReply = replyId => {
    BlogService.deleteReply(commentId, replyId)
    .then(result => {
        if(result.status === 204){
          setReplies(replies.filter(item => item.id !== replyId))
          Toast('success', "The reply has been deleted.")
        }else{
            Toast('error', result?.data?.error)
        }
    })
  }

  const patchReply = (replyId) => {
    if(patchReplyContent === ""){
      Toast("error", "The reply can't be empty.")
      return;
    }
    BlogService.patchCommentReply(commentId, replyId, patchReplyContent)
    .then(result => {
        if(result.status === 200){
          const data = replies.filter(item => item.id !== replyId)
          setReplies([...data, result.data])
          setShowReply("")
          Toast("success", "The reply has been edited.")
        }else{
          Toast("error", result.data?.error)
        }
        setPatch("")
    })
  }

  useEffect(() => {
    setReplies(listReplies)
  }, [])

  return (
    <Container>
            {showReply === commentId && 
              <Box component='div' fullWidth margin='15px 15px'>
                  <Box>
                      <CssTextField
                      id="outlined-multiline-static"
                      label="Add a reply"
                      multiline
                      rows={4}
                      fullWidth
                      value={newReply}
                      onChange={e => setNewReply(e.target.value)}
                      defaultValue="Default Value"
                      variant="outlined"
                  />
                  </Box>
                  <Box component='div' marginTop='10px' textAlign='center'>
                      <Button variant='outlined' size='small'  style={{borderColor:"#fed108", color: "#fed108"}} onClick={() => postReply()}>Reply</Button>
                  </Box>
              </Box>
          }
        <List>
          {replies?.sort((a,b) => moment(a.created_at) - moment(b.created_at)).map(reply => {
            return (
          <React.Fragment key={reply.id}>
            <ListItem key={reply?.id} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="avatar" />
              </ListItemAvatar>
              <ListItemText
                primary={patch === reply.id ? 
                  <CssTextField
                      id="outlined-multiline-static"
                      label="Add a reply"
                      multiline
                      rows={4}
                      style={{ color: 'white' }}
                      fullWidth
                      value={patchReplyContent}
                      onChange={e => setPatchReply(e.target.value)}
                      defaultValue="Default Value"
                      variant="outlined"
                    />
                  : 
                  <Typography className={classes.fonts} style={{color: 'white'}}>
                  {reply?.content}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      style={{color: 'white'}}
                    >
                      {reply?.user}  {" "} - {moment(reply?.created_at).format('DD/MM/YYYY hh:mm')}
                    </Typography>
                    {reply.owner &&
                    <Box fullWidth>
                        {patch === reply.id ? 
                        <Box marginRight='3px' component='span'>
                          <Button size='small'  style={{borderColor:"#fed108", color: "#fed108", marginRight: '5px'}} variant='outlined'
                          onClick={() => patchReply(reply.id)}>Update</Button> 
                        </Box>
                        : 
                        <Box marginRight='3px' component='span'>
                          <Button size='small'  style={{borderColor:"#fed108", color: "#fed108", marginRight: '5px'}} variant='outlined'
                          onClick={() => {setPatch(reply.id); setPatchReply(reply.content)}}>Edit</Button> 
                        </Box>
                        }
                        <Button size='small'  style={{borderColor:"#fed108", color: "#fed108"}} variant='outlined'
                        onClick={() => deleteReply(reply.id)}>Delete</Button> 
                    </Box>}
                  </>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
          )})}
        </List> 
      </Container>
      );
};

export default CommentReplies;