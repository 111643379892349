import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    Drawer,
    Link,
    Menu,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
    Icon,
    Grid,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom"
import Logo from '../../static/logo.gif'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(() => ({
header: {
    backgroundColor: "black",
    "@media (max-width: 900px)": {
    paddingLeft: 0,
    // height: '10vh'
    },
    // minHeight: '10vh',
    // height: '10vh',
    borderBottom: '1px solid #fed108'
},
logo: {
    width: '100%', 
    height: '100%',
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
},
menuButton: {
    fontFamily: "Open Sans, sans-serif",
    size: "18px",
    marginLeft: "16px",
},
toolbar: {
    height: '100%',
    display: "flex",
    justifyContent: "space-between",
},
drawerContainer: {
    padding: "20px 30px",
},
gridItems: {
  "@media (min-width: 961px) and (max-width: 1280px)": {
    height: '50%'
  },
  "@media (min-width: 1280px)": {
    height: '100% !important'
  },
  "@media (max-width: 960px)": {
    height: '100% !important'
  },
},
}));

const PublicMenuItems = [
  {
      "label": "Home",
      "href": "/",
  },
  {
      "label": "About",
      "href": "/about",
  },
  {
      "label": "Contact",
      "href": "/about#contact",
  },
  {
      "label": "The Developing Story",
      "href": "/developing",
  },
  {
      "label": "Story Search",
      "href": "/content",
  },
  {
    "label": "Log In",
    "href": "/login",
  },
  {
    "label": "Sign Up",
    "href": "/signup",
  },
]


const PrivateMenuItems = [
    {
        "label": "Profile",
        "href": "/profile",
    },
    {
        "label": "Media",
        "roles": ["EDITOR"],
        "subMenu": [
            {
            "label": "Submitted",
            "href": "/editor/content",
            },
            {
            "label": "Submit Final Piece",
            "href": "/editor/upload",
            },
            {
              "label": "Raw Media Pull",
              "href": "/editor/search",
              }
        ]
    },
    {
        "label": "Raw Media",
        "roles": ['CONTRIBUTOR'],
        "subMenu": [
            {
            "label": "My Media",
            "href": "/contributor/content",
            },
            {
            "label": "Submit",
            "href": "/contributor/upload",
            },
        ]
    },
    {
        "label": "Story Search",
        "href": "/content",
        "roles": ['SUBSCRIBER'],
    },
    {
      "label": "Log out",
      "href": "/logout",
    },
]

const hasPermissions = item => {
    if(!item.roles){
      return true
    }

    // If no token or role is present, return False (User must choose role on SignUp)
    if(localStorage.getItem('vettageToken') == "undefined" || localStorage.getItem('vettageToken') == null || 
    !localStorage.getItem('roles') || localStorage.getItem('roles') == null){
        return false
    }
    
    const roles = localStorage.getItem('roles')
    if(item.roles.some(role => role === "EDITOR") && roles.split(',').some(role => role === "EDITOR")){
        return true
    }

    if(item.roles.some(role => role === "CONTRIBUTOR") && roles.split(',').some(role => role === "CONTRIBUTOR")){
        return true
    }

    if(item.roles.some(role => role === "SUBSCRIBER") && (roles.split(',').some(role => role === "SUB_FREE") || 
    roles.split(',').some(role => role === "SUB_PERVIEW") || roles.split(',').some(role => role === "SUB_SUBSCRIPTION"))){
        return true
    }

    return false
}

const isAuthenticated = () => {
  if(localStorage.getItem('vettageToken') && localStorage.getItem('vettageToken') !== 'undefined'){
    return true
  }
  return false
}

const Header = () => {
    const { header, logo, gridItems, menuButton, toolbar, drawerContainer } = useStyles();
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
      });
    const { mobileView, drawerOpen } = state;
    const [anchorEl, setAnchorEl] = React.useState({
      RawContent: false,
      Contributor: false
    });
    const [selectedIndex, setSelectedIndex] = React.useState("")

    const handleClickMenu = index => {
        if (selectedIndex === index) {
          setSelectedIndex("")
        } else {
          setSelectedIndex(index)
        }
    }
  
    
    useEffect(() => {
        const setResponsiveness = () => {
          return window.innerWidth < 960
            ? setState((prevState) => ({ ...prevState, mobileView: true }))
            : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
    
        setResponsiveness();
    
        window.addEventListener("resize", () => setResponsiveness());
    }, []);  

    const displayDesktop = () => {
        return (
          <Toolbar className={toolbar}>
            <div>{getMenuButtons()}</div>
          </Toolbar>
        );
    };

    const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        </Toolbar>
    );
      };

    
    const getDrawerChoices = () => {
        if(isAuthenticated()){
          return PrivateMenuItems.map((item, index) => {
            if(hasPermissions(item)){
                if(item.subMenu){
                    return (
                    <>
                    <ListItem button onClick={() => handleClickMenu(index)}>
                        <ListItemText primary={item.label} />
                        {index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {item.subMenu.map(subItem => {
                          return <ListItem button>
                                  <Link {...{
                                    component: RouterLink,
                                    to: subItem.href,
                                    color: "inherit",
                                    style: { textDecoration: "none" },
                                    key: subItem.label,
                                }}
                                >
                                <ListItemText primary={subItem.label} />
                                </Link>
                          </ListItem>
                        })}
                        </List>
                    </Collapse>
                    </>
                    );
                }else{
                    return (
                    <Link
                    {...{
                        component: RouterLink,
                        to: item.href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: item.label,
                    }}
                    >
                    <MenuItem>{item.label}</MenuItem>
                    </Link>
                )}}
              });
      }else{
        return PublicMenuItems.map(item => {
          return (
            <Link
            {...{
                component: RouterLink,
                to: item.href,
                color: "inherit",
                style: { textDecoration: "none" },
                key: item.label,
            }}
            >
            <MenuItem>{item.label}</MenuItem>
            </Link>
        )})
      }
    }

    const handleClick = (target, e) => {
      setAnchorEl({...anchorEl, [target]: e.currentTarget});
    };

    const handleClose = target => {
      setAnchorEl({...anchorEl, [target]: null});
    };

    const getMenuButtons = () => {
        if(isAuthenticated()){
          return PrivateMenuItems.map(item => {
            if(hasPermissions(item)){
              if(item.subMenu){
                return (
                  <>
                    <Button
                    onClick={e => handleClick(item.label.replace(' ', ''), e)}
                    {...{
                        key: item.href,
                        color: "inherit",
                        to: item.href,
                        component: RouterLink,
                        className: menuButton,
                    }}>
                          <Typography variant='h6' style={{ fontSize: '2.2vh', textTransform: 'none' }}>
                          {item.label}
                          </Typography>
                          <Box component='div' margin='15% 0px 10% 0px'>
                            <Icon><ExpandMore/></Icon>
                          </Box>
                    </Button>
                    <Menu
                        anchorEl={anchorEl[item.label.replace(' ', '')]}
                        keepMounted
                        open={anchorEl[item.label.replace(' ', '')]}
                        onClose={() => handleClose(item.label.replace(' ', ''))}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ horizontal: "center" }}
                    >
                    {item.subMenu.map(subItem => {
                              return <Link
                                {...{
                                    component: RouterLink,
                                    to: subItem.href,
                                    color: "inherit",
                                    style: { textDecoration: "none" },
                                    key: subItem.href,
                                }}
                                >
                                    <MenuItem style={{ fontSize: '18px'}}
                                    onClick={() => handleClose(item.label.replace(' ', ''))} value={subItem.label}>{subItem.label}</MenuItem>
                                </Link>
                    })}
                    </Menu>
                  </>
                )}else{
                    return (
                        <Button
                        {...{
                            key: item.label,
                            color: "inherit",
                            to: item.href,
                            component: RouterLink,
                            className: menuButton,
                        }}
                        >
                          <Typography style={{fontSize: '2.2vh', textTransform: 'none'}} >
                            {item.label}
                          </Typography>
                        </Button>
                    );
                }
        }})
      }else{
        return PublicMenuItems.map(item => {
          return (
            <Button
                {...{
                    key: item.label,
                    color: "inherit",
                    to: item.href,
                    component: RouterLink,
                    className: menuButton,
                }}
                >
                <Typography style={{fontSize: '2.2vh', textTransform: 'none'}} >
                  {item.label}
                </Typography>
            </Button>
        )})
      }
    };
  
    const getLogo = () => {
      return (
          <Box component='div' position='static'>
              <a href='/'><img src={Logo} className={logo}  alt="logo"/></a>
          </Box>
      )
    }

    return (
        <header>
            <AppBar position='static' className={header}>
              <Grid container style={{height: 'inherit'}} lg={12} sm={12} xs={12}>
                <Box clone className={gridItems} p={2} order={{ xs:2, md: 1, lg: 1 }}>
                  <Grid item lg={5} md={12} sm={10}  xs={10}>
                      <Grid container style={{height: '100%'}} justify="left">
                        {getLogo()}
                      </Grid>
                  </Grid>
                </Box> 
                <Box clone order={{ xs:1, md: 2, lg: 2 }}>
                  <Grid item lg={7} md={12} sm={2} xs={2} style={{margin: 'auto'}}  className={gridItems} >
                      <Grid container style={{height: '100%'}} justify="center">
                      {mobileView ? displayMobile() : displayDesktop()}
                      </Grid>
                  </Grid>
                </Box>
              </Grid>
            </AppBar>
        </header>
    )
}

export default Header;