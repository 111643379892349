import axios from 'axios';

const instance = axios.create({
    responseType: "json",
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    validateStatus: status => {
        // handling our own errors less than 500 status
        return status < 500;
      },
})

instance.interceptors.response.use(function (response) {
  if ("x-vettage-roles" in response.headers){
    localStorage.setItem("roles", response.headers["x-vettage-roles"]);
  }
  else {
    localStorage.removeItem('roles');
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

export default instance
