import axios from './custom-axios';
import AuthService from './authService';

class BlogService {

    getAllArticles(params, rowsPerPage, currentPage){
        return axios.get("/blog/posts/?limit=" + rowsPerPage + "&page=" + currentPage + params
        , { validateStatus: status => {return status < 500} })
    }
    
    getArticleBySlug(slug){
        return axios.get("/blog/posts/" + slug,  { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    postComment(articleId, comment){
        return axios.post("/blog/" + articleId + "/comment/",{
            "content": comment
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }

    patchComment(articleId, commentId, comment){
        return axios.patch("/blog/" + articleId + "/comment/" + commentId,{
            "content": comment
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }

    deleteComment(articleId, commentId){
        return axios.delete("/blog/" + articleId + "/comment/" + commentId,{ validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }

    postCommentReply(commentId, reply){
        return axios.post("/blog/" + commentId + "/reply/",{
            "content": reply
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }

    patchCommentReply(commentId, replyId, reply){
        return axios.patch("/blog/" + commentId + "/reply/" + replyId,{
            "content": reply
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }

    deleteReply(commentId, replyId){
        return axios.delete("/blog/" + commentId + "/reply/" + replyId,{ validateStatus: status => {return status < 500}, headers: AuthService.authHeader() }) 
    }
}

export default new BlogService;