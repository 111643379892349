// ** Custom Components
import moment from 'moment'

// ** Table columns
export const columns = [
  {
    name: 'Newsworthiness',
    minWidth: '30px',
    selector: 'newsworthiness',
    sortable: true,
    cell: row =>  <span><strong>{row.newsworthiness}</strong></span>
  },
  {
    name: 'Credibility',
    minWidth: '80px',
    selector: 'credibility',
    sortable: true,
    cell: row =>  <span><strong>{row.credibility}</strong></span>
  },
  {
    name: 'Timeliness',
    minWidth: '80px',
    selector: 'timeliness',
    sortable: true,
    cell: row =>  <span><strong>{row.timeliness}</strong></span>
  },
  {
    name: 'Design Quality',
    minWidth: '80px',
    selector: 'design_quality',
    sortable: true,
    cell: row =>  <span><strong>{row.design_quality}</strong></span>
  },
  {
    name: 'Date',
    minWidth: '80px',
    selector: 'date',
    sortable: true,
    cell: row =>  <span><strong>{moment(row.created_at).format("MM/DD/YYYY hh:mm")}</strong></span>
  },
]
