import React, {useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Container, makeStyles, Box, Card, Grid, CardHeader, CardContent, Typography, CardActions, Button, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { CustomToast, Toast} from '../toastify';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        'maxWidth': '100%',
        'width': '100%',
        // 'color': 'white',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
};

const useStyles = makeStyles({
    root: {
      maxWidth: 500,
      margin: '35vh auto',
    },
    stripe: {
      width: "100%",
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      padding: '5px 10px',
      border: '0px',
      color: 'black',
      backgroundColor: '#fed108',
      '& span:hover':{
        padding: '0px',
        border: '0px',
        color: 'black',
        backgroundColor: '#fed108',
      }
    }
});

const tiers = [
  {
    title: 'Free',
    price: '0.00',
    description: ['1 story per month', 'No Rating'],
    buttonText: 'Try it for free',
    buttonVariant: 'outlined',
    name: "SUB_FREE",
  },
  {
    title: 'Per Month',
    subheader: 'Most popular',
    price: '8.99',
    description: ['Unlimited stories per month', 'Rating available'],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
    name: "SUB_SUBSCRIPTION",
    priceID: process.env.REACT_APP_STRIPE_PRICE_ID
  },
  {
    title: 'Per View',
    price: '1.00',
    description: ['1 story per credit', 'Rating available for that story'],
    buttonText: 'Buy Credits',
    buttonVariant: 'outlined',
    name: "SUB_PERVIEW",
  },
];

const CssTextField = withStyles({
  root: {
  }
})(TextField);

const SubscriptionForm = ({ setPaymentMethodCallback, role, setRole, numberTokens, setNumberToken }) => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [priceId, setPriceId] = useState("");

    useEffect(() => {
      setPaymentMethodCallback(handlePaymentMethod)
    })

    const handlePaymentMethod = async () => {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      let cardElement = elements.getElement(CardElement)
      if(priceId === ""){
        Toast("error", "Please choose a pricing plan.")
        return null;
      }
      if(cardElement?._complete === false){
        Toast("error", "Please finish the card information.")
        return null;
      }

      if(role === "SUB_SUBSCRIPTION"){
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if(result.error){
          return null;
        }else{
          return {"payment_method": result["paymentMethod"]["id"], "price_id": priceId};
        }
      }else if(role === "SUB_PERVIEW"){
        if(numberTokens === ""){
          Toast("error", "Please enter the number of tokens you'd like to purchase.")
        }
        const result = await stripe.createToken(cardElement);
        
        if(result.error){
          return null;
        }else{
          return {"token": result["token"]["id"]};
        }
      }
    }
    

    return (
        <Box component="div" width="100%">  
            <CustomToast />
            <Box component="div" m={1}>
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
              {tiers.map((tier) => (
                <Grid item key={tier.title} xs={12} sm={tier.title === 'Per View' ? 12 : 6} md={4} lg={4} style={{borderColor: 'red'}}>
                  <Card
                    style={tier.name === role ? { border: '4px solid #fed108'} : {}}
                  >
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                    />
                    <CardContent>
                      <Box className={classes.cardPricing}>
                        <Box component='div' textAlign='center'>
                          <Typography component="h4" variant="h5" color="textPrimary">
                            ${tier.price}
                          <Typography variant="h6" color="textSecondary">
                            /mo
                          </Typography>
                          </Typography>
                        </Box>
                      </Box>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography component="li" variant="subtitle1" align="center" key={line}>
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions>
                      <Button fullWidth data-role={tier.name} data-price={tier?.priceID}
                              onClick={event => (setRole(event.currentTarget.dataset.role), setPriceId(event.currentTarget.dataset?.price))} 
                              className={classes.button}>
                        {tier.buttonText}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
               </Grid>
              </Container>
            </Box> 
            {role !== "" && role !== "SUB_FREE" ?
              <Box component="div" width={"70%"} margin={'3% auto'} style={{ backgroundColor: 'white' }}
              border={1} borderRadius={10} p={1.5} borderColor={'silver'}>
                <Grid container lg={12} md={12} sm={12} xs={12}>
                  <Grid item lg={role === "SUB_PERVIEW" ? 3 : 0} md={role === "SUB_PERVIEW" ? 3 : 0} xs={12}>
                  {role === "SUB_PERVIEW" &&
                    <Box component='div' margin='0 auto 20px' textAlign='center'>
                          <CssTextField
                          type="number"
                          size='small'
                          label="Number of tokens"
                          onChange={e => setNumberToken(e.target.value)}
                          value={numberTokens}
                        />
                    </Box>
                  }
                  </Grid>
                  <Grid item lg={role === "SUB_PERVIEW" ? 8 : 12} md={role === "SUB_PERVIEW" ? 8 : 12} style={{width: "100%", margin: 'auto'}}>
                    <Box component='div' width='100%' >
                      <CardElement options={CARD_ELEMENT_OPTIONS}/>
                    </Box>
                  </Grid>
                </Grid>  
              </Box> 
            : null}
        </Box>
        )
}

export default SubscriptionForm;