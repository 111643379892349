import { Component, Fragment } from 'react'
import BlogService from "../services/blogService"
import { CustomToast, Toast } from "../components/toastify";
import ReactPaginate from 'react-paginate'
import { Grid, Box, Container  } from '@material-ui/core';
import '../styles/blog.css'
import BlogSingleItem from '../components/BlogComponents/BlogSingleItem'
import '../styles/styles.css'

const rowsPerPage = 5

class BlogPage extends Component{
    constructor(props){
        super(props)

        this.state = {
            data: null,
            count: 0,
            currentPage: 0,
            tags: [],
            title: "",
        }
    }

    getData = () => {
      let params = "&"
      if(this.state?.tags.length !== 0){
        params += "tags="
        this.state.tags.map(item => {
          params += item.text ? item.text : item + ","
        })
        params = params.substring(0, params?.length - 1)
      }
      if(this.state.title !== ""){
        params += "title=" + this.state.title
      }

      BlogService.getAllArticles(params, rowsPerPage, this.state.currentPage + 1)
        .then(result => {
            if(result.status === 200){
                this.setState({ data: result.data?.results, count: result.data.count })
            }else{
                Toast("error", result.data?.error)
            }
        })
    }
    
    componentDidMount(){
      const url = new URL(window.location.href);

      if (url.searchParams.get('tags')) {
        this.setState(prevState => ({tags: [...prevState.tags, url.searchParams.get('tags')]}),
        () => this.getData())
      }else{
        this.getData()
      }
    }
    
    resetFilter = () => {
      this.setState({ 
        tags: "",
        title: ""
      }, () => this.getData())
    }
    
    handlePagination = page => {
      this.setState({
        currentPage: page.selected
      }, () => this.getData())
    }

    CustomPagination = () => {
      const  count = Number(Math.ceil(this.state.count / rowsPerPage))

      return (
        <ReactPaginate
          pageCount={count}
          nextLabel='&rarr;'
          breakLabel='...'
          previousLabel='&larr;'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          forcePage={this.state.currentPage}
          onPageChange={page => this.handlePagination(page)}
          pageClassName={'page-item'}
          nextLinkClassName={'page-link'}
          nextClassName={'page-item next'}
          previousClassName={'page-item prev'}
          previousLinkClassName={'page-link'}
          pageLinkClassName={'page-link'}
          containerClassName={'pagination pagination-circle justify-content-center'}
        />
      )
    }

    render(){
        return (
            <>
              {this.state.data !== null &&
              <div>
                <CustomToast/>
                <Container>
                    <section className="text-center">
                    <div className="row" style={{marginTop: '15px !important'}}>
                      <Grid container lg={12} md={12}> 
                      {this.state.data.sort((a,b) => b.created_at > a.created_at).map(item => {
                        return (
                          <Grid lg={5} md={5} xs={12} style={{margin: 'auto'}}>
                            <Box component='div' margin={2}>
                              <BlogSingleItem blog={item} />
                            </Box>
                          </Grid>
                        )
                      })}
                      </Grid>
                      </div>
                   </section>
                   </Container>
                <this.CustomPagination/>
            </div>
            }</>
        )
    }
}

export default BlogPage;