import { Component } from "react";
import { Avatar, Link, TextField, Button, Typography, withStyles, CssBaseline, Box, Container  } from '@material-ui/core';
import { CustomToast, Toast } from '../components/toastify'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Form from "react-validation/build/form";
import authService from '../services/authService';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10% 15%', 
    minHeight: '50vh', 
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#fed108',
    color: 'black'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    padding: '0px',
    color: '#fed108',
    backgroundColor: 'black',
    border: '1px solid #fed108',
    '& span':{
      padding: '8px 15px'
    },
    '& :hover':{
      color: 'black',
      backgroundColor: '#fed108'
    }
}
  });

const CssTextField = withStyles({
    root: {
      '& label': {
          color: 'white'
      },
      '& label.Mui-focused': {
        color: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#fed108',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
      '& .MuiFormHelperText-root':{
          color: 'white'
      }
    }
})(TextField);

class ResetPassword extends Component{
    constructor(props){
        super(props)

        this.state = {
            password: "",
            confirmPassword: ""
        }
    }

    resetPassword = e =>{
        e.preventDefault() 

        if(this.state.password?.length <= 8 || this.state.password?.length >= 40){
            Toast("error", "Password is not valid. The length must be between 8 and 40 characters.")
            return false
        }
        if(this.state.password !== this.state.confirmPassword){
            Toast("error", "The two passwords don't match.")
            return false
        }

        authService.confirmResetPassword(this.state.password, this.props.match.params.uid, this.props.match.params.token)
        .then(response => {
            if(response.status === 200){
                Toast("success", "Your password has been reset.")
                this.props.history.push({
                  pathname: '/login',
                  state: {"type": "success", "message": "Your password has been changed."}
                })
            }else{
              let resp = "There was an error with the request."
              if(response.data.new_password1){
                response.data.new_password1.map(item => {
                  resp += item
                })
              }else if(response.data.new_password2){
                response.data.new_password2.map(item => {
                  resp += item
                })
            }
            Toast('error', resp)
          }
        })
    }

    render(){
        return (
          <Container style={{ padding: '2% 0', minHeight: '50vh', maxWidth: '35%' }} component='main'>
            <CustomToast />
            <CssBaseline />
            <div className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" style={{ color: 'white' }}>
                Reset Password
              </Typography>
              <Form
                className={this.props.classes.form}
                onSubmit={this.resetPassword}
                >
                  <Box mb={2}>
                    <CssTextField
                        type="password"
                        name="password"
                        margin="normal"
                        variant='outlined'
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        autoComplete="password"
                        autoFocus
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                    <CssTextField
                        type="password"
                        name="confirm-password"
                        margin="normal"
                        variant='outlined'
                        required
                        fullWidth
                        id="confirm-password"
                        label="Confirm Password"
                        autoComplete="password"
                        autoFocus
                        value={this.state.confirmPassword}
                        onChange={e => this.setState({ confirmPassword: e.target.value })}
                    />
                  </Box>
                    <Box mb={2}>
                      <Button
                        type="submit"
                        className={this.props.classes.button}
                        disabled={this.state.loading}
                      >
                          Reset
                      </Button>
                    </Box>
                    <Link href="/login" variant="body2" style={{ color: 'white' }}>
                        {"Log In"}
                    </Link>
                  </Form>
                </div>
            </Container>
        )
    }
}


export default withStyles(styles)(ResetPassword)