import { Dialog, Container, Typography, Box } from "@material-ui/core"

const RedirectDialog = ({ open }) => {
    return (
        <div>
            <Container>
                <Dialog aria-labelledby="simple-dialog-title" maxWidth="sm" open={open}>
                        <Box component="div" flexWrap="wrap" justifyContent="center" fullWidth alignItems="center" p={{ xs: 2, sm: 3, md: 4 }} height='4vh'>
                            <Box component="div" margin={'auto'}>
                                <Typography>You will be redirected to Stripe in 3 seconds.</Typography>
                            </Box>
                        </Box>
                    </Dialog>
            </Container>
        </div>
    )
}

export default RedirectDialog