import React from 'react';
import { Route, Router as BrowserRouter, Switch, Redirect } from 'react-router-dom';
import './App.css';
import history from './utilities/history'
import PayoutServices from './services/payoutServices'
import AuthService from './services/authService'

import LoginPage from './views/LoginPage'
import LogoutPage from './views/LogoutPage'
import SignupPage from './views/SignupPage'
import ProfilePage from './views/ProfilePage'
import ResetPassword from './views/ResetPassword'
import ResetPasswordConfirm from './views/ResetPasswordConfirm'

import ContributorUploadPage from './views/Contributor/UploadPage';
import ContributorListPage from './views/Contributor/list/index';
import ContributorEditPage from './views/Contributor/EditPage';

import EditorSearch from './views/Editor/search/index';
import EditorListPage from './views/Editor/list/index';
import EditorUploadPage from './views/Editor/UploadPage';
import EditorEditPage from './views/Editor/EditPage';

import ContentPage from './views/ContentPage';

import BlogPage from './views/BlogPage'
import BlogArticlePage from './components/BlogComponents/BlogArticle'

import AboutPage from './views/AboutPage'
import RulesPage from './views/RulesPage'
import UserAgreement from './views/UserAgreementPage'
import LegalPage from './views/LegalPage'
import PrivacyPage from './views/PrivacyPage'

import NotFoundPage from './views/NotFoundPage'

import Layout from './components/CommonComponents/Layout'

import HomePage from './views/Home'

const isAuthenticated = () => {
  if(localStorage.getItem('vettageToken') && localStorage.getItem('vettageToken') !== "undefined"){
    return true
  }
  return false
}

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  let pathName = rest.path.split("/")[1]
  let isAccessible = null;

  switch (pathName) {
    case 'login':
    case 'signup':
    case 'password-reset':
      isAccessible = !isAuthenticated();
      break;
    case 'logout':
      isAccessible = true;
      break;
    case 'profile':
      isAccessible = isAuthenticated();
      break;
    case 'editor':
      isAccessible = isAuthenticated() && localStorage.getItem("roles") && localStorage.getItem("roles").split(",").includes("EDITOR");
      break;
    case 'contributor':
      isAccessible = isAuthenticated() && localStorage.getItem("roles") && localStorage.getItem("roles").split(",").includes("CONTRIBUTOR");
      break;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAccessible ?
        <Layout component={Component} {...props}/> : <Redirect to="/404" />
      }
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        <Layout component={Component} {...props}/> 
      }
    />
  );
}

const handleCallback = (param) => {
  PayoutServices.callback(param).then(result => {
    if (result.status < 300) {
      history.push({
        pathname: '/profile',
        state: {"type": "success", "message": "Your account has succesfully been connected and you are now eligible to receive payouts. Please review the User Agreement."}
      })
    }
    else {
      history.push({
        pathname: '/profile',
        state: {"type": "error", "message": result.data?.error}
      })
    }
  })
}

const emailConfirmation = props => {
  AuthService.confirmEmail(props.match.params.id).then(result => {
    if (result.status === 200) {
      history.push({
        pathname: '/login',
        state: {"type": "success", "message": "Your email has been confirmed."}
      })
    }
    else {
      history.push({
        pathname: '/login',
        state: {"type": "error", "message": "There was an error with the confirmation. Please contact the administrator or try again."}
      })
    }
  })
}

function App() {
  return (
    <div className="App">
        <BrowserRouter history={history}>
            <Switch>
              {/* Front Routes */}
              <PublicRoute exact path="/" component={HomePage} /> 

              {/* Auth Routes */}
              <ProtectedRoute exact path="/login" component={LoginPage}/>
              <ProtectedRoute exact path="/logout" component={LogoutPage}/>
              <ProtectedRoute exact path="/signup" component={SignupPage}/>
              <ProtectedRoute exact path="/password-reset/confirm/:uid/:token/" component={ResetPasswordConfirm}/>
              <ProtectedRoute exact path="/password-reset" component={ResetPassword}/>

              {/* Contributor Routes */}
              <ProtectedRoute exact path="/contributor/upload" component={ContributorUploadPage}/> 
              <ProtectedRoute exact path="/contributor/edit/:rawContentId" component={ContributorEditPage}/> 
              <ProtectedRoute exact path="/contributor/content" component={ContributorListPage}/>

              {/* Editor Routes */}
              <ProtectedRoute exact path="/editor/search" component={EditorSearch}/>
              <ProtectedRoute path="/editor/upload" component={EditorUploadPage}/>
              <ProtectedRoute exact path="/editor/edit/:finalContentId" component={EditorEditPage}/>
              <ProtectedRoute exact path="/editor/content" component={EditorListPage}/> 
              
              {/* Content Route */}
              <PublicRoute exact path="/content" component={ContentPage}/>

              {/* Profile Routes */}
              <ProtectedRoute exact path="/profile" component={ProfilePage}/>

              {/* Blog Routes */}
              <PublicRoute exact path="/developing/:slug" component={BlogArticlePage}/>
              <PublicRoute exact path="/developing" component={BlogPage}/>
              
              {/* Payout Routes */}
              <Route exact path="/success_url" render={() => handleCallback("true")}/>
              <Route exact path="/refresh_url" render={() => handleCallback("false")}/>
              <Route exact path='/confirm-email/:id' render={props => emailConfirmation(props)} />

              {/* Static Routes */}
              <PublicRoute exact path='/about' component={AboutPage} />
              <PublicRoute exact path='/rules' component={RulesPage} />
              <PublicRoute exact path='/user-agreement' component={UserAgreement} />
              <PublicRoute exact path='/legal' component={LegalPage} />
              <PublicRoute exact path='/privacy' component={PrivacyPage} />

              {/* 404 Error Route */}
              <PublicRoute path="/404" component={NotFoundPage}/>
              <Redirect to="/404"/>
            </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
