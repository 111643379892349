
import moment from "moment"
import { Button, Box, TextField, Typography, Grid, makeStyles } from '@material-ui/core'
import SubscriptionService from '../../services/subscriptionService'
import { useState } from "react";
import {useStripe, useElements, Elements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Toast} from '../toastify';
import PaymentMethods from "./PaymentMethods"

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        'maxWidth': '100%',
        'width': '100%',
        'color': '#32325d',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
};

const useStyles = makeStyles({
    button: {
        padding: '0px',
        color: '#fed108',
        backgroundColor: 'black',
        border: '1px solid #fed108',
        '& span':{
          padding: '8px 15px'
        },
        '&.Mui-disabled':{
            border: '0px',
          backgroundColor: 'silver',
          color: 'black',  
        },
        '& :hover':{
          color: 'black',
          backgroundColor: '#fed108'
        }
      }
});


const SubscriptionInfo = ({ subscription, roleButton }) => {
    const stripe = useStripe();
    const elements = useElements();
    const styles = useStyles();
    const [tokens, setTokens] = useState("")
    const [open, setOpen] = useState(false)

    const purchaseTokens = async () => {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        let cardElement = elements.getElement(CardNumberElement)
        if(cardElement._complete === false){
            Toast("error", "Please finish the card information.") 
            return null;
        }
        const result = await stripe.createToken(cardElement);
        if(result.error){
            Toast("error", "There was an error with the payment.") 
            return null;
        }else{
            SubscriptionService.chargeCredits(null, tokens, {"token": result["token"]["id"]})
            .then(result => {
                if(result.status === 200){
                    Toast("success", "You've succesfully purchased " + tokens + " tokens.") 
                }else{
                    Toast("error", result.data?.error) 
                }
            })
            return ;
        }
    }
      
    const cancelSubscription = () => {
        if(subscription?.subscription_cancel){
            Toast("success", "The subscription has already been cancelled. End Date: " + moment(subscription?.subscription_end_date).format('dddd, MMMM Do, YYYY hh:mm A'))
            return;
        }
        SubscriptionService.cancelSubscription()
        .then(result => {
            if(result.status === 200){
                Toast("success", result.data?.message)
                window.location.reload()
            }else{
                Toast("error", result.data?.error)
            }
        })
    }

    const reactivateSubscription = () => {
        if(!subscription?.subscription_cancel){
            Toast("The subscription can't be reactivated.")
            return;
        }
        SubscriptionService.reactivateSubscription()
        .then(result => {
            if(result.status === 200){
                Toast(result.data?.message)
                window.location.reload()
            }else{
                Toast(result.data?.error)
            }
        })
    }

    return (
        <Box component='div'>
            {localStorage.getItem('roles').split(",").some(item => item === "SUB_SUBSCRIPTION") && 
            subscription?.subscription_active ? 
            <Box>
                
                {subscription?.subscription_active && 
                <Box component='div' m={2}>
                    <Box>
                        <Typography>Subscription End Date:</Typography>
                    </Box>
                    <Typography>{moment(subscription?.subscription_end_date).format('DD MMM YYYY hh:mm A')}</Typography>
                </Box>
                }
                <Grid container lg={12} md={12}>
                    <Grid item lg={12} md={12} xs={12} style={{marginBottom: "10px"}}>
                        <Box component='div' paddingLeft={2} paddingRight={2}>
                            <Box mb={0.5}>
                                <Typography style={{ fontSize: '10px', color: 'silver' }}>By cancelling the subscription, the current subscription period 
                                won't be affected and no more charges will occur. You will be able to start a new subscription.</Typography>
                            </Box>
                            <Button 
                            onClick={cancelSubscription} 
                            disabled={subscription?.subscription_cancel}
                            size='small'
                            className={styles.button}
                            >
                                Cancel Subscription
                            </Button> 
                        </Box>
                        <Box>
                            <Button onClick={roleButton} className={styles.button} size='small' style={{marginTop: '10px'}}>Change Subscription</Button>
                        </Box>

                    </Grid>
                    <Grid item lg={12} md={12}  xs={12}>
                        <Box component='div' paddingLeft={2} paddingRight={2}>
                            <Button 
                                onClick={() => setOpen(!open)}
                                size='small'
                                className={styles.button}
                            >
                                Payment Information
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <PaymentMethods open={open}  onClose={e => setOpen(false)}/>
                {subscription?.subscription_cancel &&
                <div>
                    <h4>The subscription has been canceled and will end at the Subscription End Date.</h4>
                    <Button onClick={reactivateSubscription} className={styles.button}>Reactivate Subscription</Button>
                </div>}
            </Box>
            : null }

            
            {localStorage.getItem('roles').split(",").some(item => item === "SUB_PERVIEW") &&
            <Box component="div" margin="auto">
                <Box>
                    <Typography>You have {subscription?.tokens} tokens left.</Typography>
                </Box>
                <div style={{marginBottom: "5px", backgroundColor: "#FFF", textAlign: "center"}}>
                    <TextField
                    type="number"
                    label="Tokens"
                    onChange={e => setTokens(e.target.value)}
                    value={tokens}
                    />
                    <br/>
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                </div>
                <Button className={styles.button} onClick={purchaseTokens}>Purchase</Button>
            </Box>
            }
    </Box>
    )
}

export default SubscriptionInfo