

import { useState, useEffect } from 'react'
import { Dialog, Checkbox, FormGroup, FormControlLabel, Backdrop , Button, Box, Typography, IconButton, Grid,
  CircularProgress  } from '@material-ui/core';
import SubscriptionForm from '../SubscriptionComponents/SubscriptionForm'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AuthService from '../../services/authService'
import moment from 'moment'
import SubscriptionService from '../../services/subscriptionService'
import { CustomToast, Toast} from '../toastify';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/styles.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const allRoles = [
  {"value": "CONTRIBUTOR", "label": "Contributor"},
  {"value": "EDITOR", "label": "Editor"}, 
  {"value": "SUBSCRIBER", "label": "Subscriber"},
]

const subRoles = [
  {"value": "SUB_FREE", "label": "Free Subscription"},
  {"value": "SUB_SUBSCRIPTION", "label": "Subscriber (Per Month)"},
  {"value": "SUB_PERVIEW", "label": "Subscriber (Per View)"},
]

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
 
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box flexWrap="wrap">
          <Typography style={{wordBreak: "break-all"}} variant="h6">{children}</Typography>
      </Box>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
      padding: theme.spacing(2),
  },
  }))(MuiDialogContent);
  

const isEqual = (arr1, arr2) => {
  return arr1?.length === arr2?.length && arr1.every((e, i) => e.name === arr2[i].name && e.age === arr2[i].age);
}

function RoleDialog(props) {
    const { onClose, open, rolesProps, subscription } = props;
    const [roles, setRoles] = useState([]) 
    const [subRole, setSubRole] = useState("")
    const [tokens, setTokens] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => { setRoles(rolesProps) }, [rolesProps])
    let handlePaymentMethodCallback = () => {}

    const handleChange = (event, text) => {
      if (event.target.checked) {
        setRoles([allRoles.find(el => el.value === text), ...roles]);
      } else {
        if(text === "SUBSCRIBER"){
          setSubRole("")
        }
        const newRoles = roles.filter(
          (element) => element.value !== text
        );
        setRoles(newRoles);
      }
    };

    const changeRoles = async () => { 
      setLoading(true)
      if(subRole === "" || subRole === "SUB_FREE"){
        let newRoles = roles.filter(item => item.value !== "SUBSCRIBER")
        newRoles = newRoles.map(item => ({"role_name": item.value}))    
        if(subRole === "SUB_FREE"){
          newRoles.push({"role_name": "SUB_FREE"})
        }
        AuthService.addRoles(newRoles)
          .then(result => {
            if(result.status === 200){
              let roles = []
              result.data.role.map(item => roles.push(item.role_name))
              localStorage.setItem('roles', roles)
              Toast("success", roles?.length === 1 ? "You've succesfully added the role." : "You've succesfully added the roles.")
            }
            window.location.reload()
          })
      } else if(subRole !== ""){
        let paymentPayload = null;
        if(tokens === "" && subRole === "SUB_PERVIEW"){
          Toast("error", "Please enter the number of credits you'd like to purchase.")
          setLoading(false) 
          return;
        }
        paymentPayload = await handlePaymentMethodCallback()
        if(paymentPayload === null || paymentPayload === undefined) {
          setLoading(false); 
          Toast("There was a problem with the payment. Please try again."); return;}
        if(subRole === "SUB_SUBSCRIPTION"){
          SubscriptionService.subscribe(null, paymentPayload)
          .then(result => {
            if(result.status === 200){
              let roles = []
              result.data.roles.map(item => roles.push(item))

              localStorage.setItem('roles', roles)
              Toast("success", "You've succesfully subscribed.")
              window.location.reload()
            }else{
              onClose()
              Toast("error", result.data?.error)
            }
          })
        } else if(subRole === "SUB_PERVIEW"){
          SubscriptionService.chargeCredits(null, tokens, paymentPayload)
          .then(result => {
            if(result.status === 200){
              let roles = []
              result.data.roles.map(item => roles.push(item))
              localStorage.setItem('roles', roles)
              Toast("success", "You've succesfully purchased " + tokens + " tokens.")
              window.location.reload()
            }else{
              onClose()
              Toast("error", result.data?.error)
            }
          })
        }
      }
      setLoading(false)   
    }


    return (
        <Dialog onClose={onClose}
        maxWidth={subscription?.subscription_active || subscription?.subscription_tokens ? 'sm' : 'md' } open={open}>
          <Backdrop style={{
             zIndex: 1,
             color: '#fff',
          }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box component="div" flexWrap="wrap" justifyContent="center" fullWidth alignItems="center" marginBottom={3} p={{ xs: 2, sm: 3, md: 4 }} minHeight='20vh'>
            <Box component='div' textAlign='center'>
              <DialogTitle id="dialog-title" onClose={onClose}>Roles</DialogTitle>
            </Box>
            <CustomToast/>
            <DialogContent dividers>
              <Box component='div' textAlign='center' fullWidth m={2} borderColor={'silver'} border={1} borderRadius={10}>
                <FormGroup alignItems='center' >
                  <Grid container lg={12} md={12} sm={6} xs={6} style={{padding: "5px 8px", margin: 'auto'}}>
                  {allRoles.map(item => 
                  <Grid item lg={4} md={4} sm={12} xs={12} style={{margin: 'auto'}} >
                    <FormControlLabel
                      control={<Checkbox checked={roles.some(el => el.value === item.value)} 
                      disabled={rolesProps.some(el => el.value === item.value)} value={item.value} name={item.value} 
                      onChange={e => handleChange(e, e.target.value)}/>}
                      label={item.label}
                    />
                  </Grid>
                  )}
                  </Grid>
                </FormGroup>
              </Box>
              {/* If the user isn't a SUBSCRIBER or if the user is a Free Subscriber or if the subscription
              has been canceled and then ended or if the subscriber doesn't have credits, render subscription form */}
              {(roles.some(item => item.value === "SUBSCRIBER") && !rolesProps.some(item => item.value === "SUBSCRIBER")) || 
              (rolesProps.some(item => item.value === "SUBSCRIBER") && (!subscription?.subscription_tokens && !subscription?.subscription_active)
              || subscription?.free_subscription) ?
              <div>
                  <Elements stripe={stripePromise}>
                    <SubscriptionForm setPaymentMethodCallback={click => handlePaymentMethodCallback = click} setNumberToken={setTokens} 
                      role={subRole} setRole={setSubRole} numberTokens={tokens}/>
                  </Elements>
              </div> : 
              <Box>
                {subscription?.subscription_active && 
                <Typography style={{textAlign: 'center'}}>You already have an active subscription. If you wish to change the subscription type, please cancel the subscription
                and wait until it ends - { 
                moment(subscription?.subscription_end_date).format('MM/DD/YYYY hh:mm A')}</Typography>}

                {subscription?.subscription_tokens && 
                <Typography style={{textAlign: 'center'}}>All of the credits need to be spent before you can change your plan. You have {subscription?.tokens} tokens left.</Typography>}
              </Box>}

                <Box textAlign='center' component='div' marginTop={2}>
                {!isEqual(roles, rolesProps) || subRole !== "" ? 
                  <Button onClick={changeRoles} disabled={loading} 
                  style={{ border: '1px solid black', borderRadius: '0px', color: 'black' }}
                  >Submit</Button> : 
                  <Button onClick={onClose} 
                  style={{ border: '1px solid black', borderRadius: '0px', color: 'black' }}
                  >Exit</Button>}
                </Box>
              </DialogContent>
            </Box>
        </Dialog>
    );
}

export default RoleDialog;