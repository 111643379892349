import { Component } from "react";
import '../styles/RulesPage.css'


class PrivacyPage extends Component{
    render(){
        return (
            <div className='main'>
                <h1>Vettage Privacy</h1>
                <div><h4>Information You Provide to Us</h4>
                <p>We collect information you provide to us directly when you use the Services. This includes:</p><br/>
                <ul className='privacy-list'>
                    <li><h4>Account information</h4>
                    <p>If you create a Vettage account,  you provide a username and password. Your username is public, 
                        and it doesn’t have to be related to your real name. Your email address is only stored on our 
                        server and never released to the public. We also store your user account preferences and settings.
                    </p>
                    </li>
                    <li><h4>Content you submit</h4>
                    <p>We collect the content you submit to the Services. This includes your messages with 
                        other users (e.g., private messages to us and messages on posts in “The Developing Story”). 
                        Your content may include text, links, images, gifs, and videos.
                    </p>
                    </li>
                    <li><h4>Actions you take</h4>
                    <p>We collect information about the actions you take when using the Services. 
                        This includes your interactions with content, like rating, collaborating, 
                        and submitting. It also includes your interactions with other users, such as mentions in comments or involvement in story creation. 
                    </p>
                    </li>
                    <li><h4>Transactional information</h4>
                    <p>If you purchase products or services from us (e.g., Subscriptions), we will collect certain information from you, 
                        including your name, address, email address, and information about the product or service you are purchasing. 
                        Vettage uses industry-standard payment processor services (for example, Stripe) to handle payment information.
                    </p>
                    </li>
                    <li><h4>Other information</h4>
                    <p>You may choose to provide other information directly to us. For example, we may collect information 
                        when you fill out a form, participate in Vettage-sponsored activities or promotions, apply for a job, 
                        request customer support, or otherwise communicate with us.
                    </p>
                    </li>
                </ul>
                </div>
                <div><h4>Information We Collect Automatically</h4>
                <p>When you access or use our Services, we may also automatically collect information about you. This includes:
                <ul className='privacy-list'>
                    <li><h4>Log and usage data</h4>
                    <p>We may log information when you access and use the Services. This may include your IP address, 
                        user-agent string, browser type, operating system, referral URLs, device information (e.g., device IDs),
                         device settings, pages visited, links clicked, the requested URL, and keyword search terms. Except for 
                         the IP address used to create your account, Vettage will delete any IP addresses collected after 100 days.
                    </p>
                    </li>
                    <li><h4>Information collected from cookies and similar technologies</h4>
                    <p>We may receive information from cookies, which are pieces of data your browser stores and sends back to us 
                        when making requests, and similar technologies. We use this information to improve your experience, understand 
                        user activity, personalize content and advertisements, and improve the quality of our Services. For example, we 
                        store and retrieve information about your preferred language and other settings. 
                    </p>
                    </li>
                    <li><h4>Location information</h4>
                    <p>We may receive and process information about your location. For example, with your consent, we may collect 
                        information about the specific location of your mobile device (for example, by using GPS or Bluetooth). 
                        We may also receive location information from you when you choose to share such information on our Services, 
                        including by associating your content with a location, or we may derive your approximate location from other 
                        information about you, including your IP address. 
                    </p>
                    </li>
                </ul>
                </p>
                </div>
                <div>
                    <p>
                        We use information about you to:
                        <ul className='privacy-list'>
                            <li>Provide, maintain, and improve the Services;</li>
                            <li>Research and develop new services;</li>
                            <li>Help protect the safety of Vettage and our users, which includes blocking suspected 
                                spammers, addressing abuse, and enforcing the Vettage User Agreement and our other policies;</li>
                            <li>Send you technical notices, updates, security alerts, invoices, and other support and 
                                administrative messages;</li>
                            <li>Provide customer service;</li>
                            <li>Communicate with you about products, services, offers, promotions, and events, 
                                and provide other news and information we think will be of interest to you; and</li>
                            <li>Monitor and analyze trends, usage, and activities in connection with our Services.</li>
                        </ul>
                    </p>
                </div>
            </div>
        )
    }
}

export default PrivacyPage