import { format } from 'date-fns'
import { Button, Typography } from '@material-ui/core'

// ** Table columns
export const columns = [
  {
    name: 'Media Type',
    minWidth: '100px',
    selector: 'content_type',
    sortable: true,
    cell: row =>  <span><strong>{row.content_type}</strong></span>
  },
  {
    name: 'Username',
    selector: 'username',
    sortable: true,
    minWidth: '120px',
    cell: row => <Typography variant='textPrimary'>{row.creator}</Typography>
  },
  {
    name: 'Tags',
    selector: 'tags',
    sortable: true,
    minWidth: '100px',
    cell: row => row.tags.map(tag => {
        return <span>{tag}</span>
      })
  },
  {
    name: 'Link',
    selector: 'link',
    sortable: true,
    minWidth: '120px',
    cell: row => <Button size="small" color="primary" href={row.link} target="_blank">Link</Button>

  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true,
    minWidth: '100px',
    cell: row => {
      let address = ""
      const length = Object.keys(row?.raw_content_address)?.length
      Object.keys(row?.raw_content_address).map((key, index) =>{
        if(row.raw_content_address[key] !== ""){
          address += row.raw_content_address[key]
          if(length !== index + 1){
            address += ', '
          }
        }
    
    })
      return address?.length > 30 ? address.substring(0, 30) + '...' : address
    }
  },
  {
    name: 'Media Date',
    selector: 'eventTime',
    sortable: true,
    minWidth: '150px',
    cell: row => format(new Date(row.event_time), 'MM/dd/yyyy')
  },
]
