import { useEffect, useState } from 'react'
import AuthService from '../../services/authService'
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Button, FormControl, TextField, InputAdornment, Grid, Box, makeStyles, Chip, Typography, withStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Toast} from '../toastify';
import DoneIcon from '@material-ui/icons/Done';
import csc from 'country-state-city'
import moment from 'moment'

const useStyles = makeStyles(() => ({
    gridItem: {
      'margin': '0px 0px 25px 0px'  
    },
    button: {
        padding: '0px',
        color: '#fed108',
        backgroundColor: 'black',
        border: '1px solid #fed108',
        '& span':{
          padding: '8px 15px'
        },
        '&.Mui-disabled':{
            border: '0px',
          backgroundColor: 'silver',
          color: 'black',  
        },
        '& :hover':{
          color: 'black',
          backgroundColor: '#fed108'
        }
      }
}));
    
const CssTextField = withStyles({
    root: {
        backgroundColor: 'black',
      '& label': {
          '&.Mui-disabled':{
            color: 'white'
          },
          color: '#fed108'
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        '&.Mui-disabled':{
            '& fieldset': {
                borderColor: 'white',
            },
        },
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#fed108',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
    }
})(TextField);


const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    }),
    control: (provided, state) => ({
      ...provided,
      opacity: '1 !important',
      backgroundColor: 'black',
      color: state.isDisabled ? 'white' : '#fed108'  
    }),
    option: (provided, state) => ({
        ...provided,
        opacity: 1,
        color: state.isSelected ? '#fed108' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opacity: 1,
        color: state.isDisabled ? 'white' : '#fed108'
    }),
    input: (provided) => ({
        ...provided,
        input: {
          opacity: 1,
        },
    }),
}

const isUserSubscriberOnly = () => {
    const roles = localStorage.getItem('roles').split(',')
    if(roles && roles?.length === 1 && (roles[0] === "SUB_SUBSCRIPTION" || roles[0] === "SUB_FREE" || roles[0] === "SUB_PERVIEW")){
        return true
    }
    return false
}

const ProfileForm = ({ oldData, roleButton}) => {
    const [data, setData] = useState(null)
    const [selector, setSelector] = useState({
        countries: csc.getAllCountries().map(item => ({value: item.isoCode, label: item.name})),
        country: null,
        states: [],
        state: null,
        cities: [],
        city: null,
    })
    const [patch, setPatch] = useState(true)
    const { gridItem, button } = useStyles();

    useEffect(() => {
        setData(oldData);
    }, [oldData])
    
    const patchProfile = () => {
        const form = {
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            phone: data.phone,
            user_address: {
                country: data.address.country,
                state: data.address.state,
                city: data.address.city,
                zip_code: data.address.zip_code
            },
        }

        Object.keys(form).map((key) => {
            if(key && key !== "address" && form[key] === ""){
                delete form[key]
            }
        })

        AuthService.patchProfile(form)
          .then(result => {
              if(result.status === 400){
                Toast("error", result.data.username ? result.data.username[0] : "There was a problem with the update.")
              }else if(result.status === 200){
                  Toast("success", "Your profile has been updated.")
                  setPatch(!patch)
              }
          })
    }

    const getSelectedCountry = () => {
        if (!selector.country && !!data.address.country){
            const country = csc.getAllCountries().find(item => item.name === data.address.country)
            setSelector(prevState => ({
                ...prevState,
                country: { value: country.isoCode, label: country.name }
            }));
        }
        return selector.country;
    }

    const setSelectedCountry = event => {
        setData(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                country : event.label,
                city: "",
                state: ""
            }
        }));
        setSelector(prevState => ({
            ...prevState,
            country: { value: event.value, label: event.label },
            states: csc.getStatesOfCountry(event.value).map(item => ({value: item.isoCode, label: item.name})),
            state: null,
            cities: [],
            city: null
        }));
    }

    const getSelectedState = () => {
        if (!!selector.country && !selector.state && !!data.address.state){
            const country = getSelectedCountry();
            const state = csc.getStatesOfCountry(country.value).find(item => item.name === data.address.state)
            if (state) setSelector(prevState => ({
                ...prevState,
                state: { value: state.isoCode, label: state.name }
            }));
        }
        return selector.state;
    }

    const setSelectedState = event => {
        setData(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                state : event.label
            }
        }));
        setSelector(prevState => ({
            ...prevState,
            state: { value: event.value, label: event.label },
            cities: csc.getCitiesOfState(prevState.country.value, event.value).map(item => ({value: item.name, label: item.name})),
            city: null
        }));
}

    const getSelectedCity = () => {
        if (!!selector.country && !!selector.state && !selector.city && !!data.address.state){
            const country = getSelectedCountry();
            const state = getSelectedState();
            const city = csc.getCitiesOfState(country.value, state.value).find(item => item.name === data.address.city)
            if (city) setSelector(prevState => ({
                ...prevState,
                city: { value: city.name, label: city.name }
            }));
        }
        return selector.city;
    }

    const setSelectedCity = event => {
        setData(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                city : event.label
            }
        }));
        setSelector(prevState => ({
            ...prevState,
            city: { value: event.value, label: event.label }
        }));
    }

    return (
        <>{data !== null ? 
        <FormControl component="fieldset" fullWidth>
            <Grid container lg={12} md={12}  sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{margin: 'auto'}}>
                    <Box component='div' width='full' className={gridItem}>
                        <CssTextField
                            required
                            disabled={patch}
                            label="Username"
                            variant="outlined"
                            name="username"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                                ),
                            }}
                            value={data.username}
                            onChange={e => setData({...data, username: e.target.value })}
                        />
                    </Box>
                    <Box component='div' width='full' className={gridItem}>
                        
                        <Box component='div' id='roles'>
                            <Typography variant='h6' style={{margin: '5px 0px'}}>Roles:</Typography>
                            {data.role.map(item => {
                            return  <Chip
                            variant='outlined'
                            icon={<DoneIcon style={{color: '#fed108'}}/>}
                            label={item.label}
                            clickable
                            style={{margin: '3px 5px', borderColor: '#fed108', color: '#fed108'}}
                            />
                            })}
                            <Box component='div'>
                                <Button onClick={roleButton} className={button} size='small' style={{marginTop: '10px'}}>Add Role</Button>
                            </Box>
                        </Box>
                    </Box>
                    {!isUserSubscriberOnly() &&
                    <>
                    <Box component='div' width='full' className={gridItem}>
                        <Box mb={1}>
                            <Typography variant='textPrimary'>Member Since:</Typography>
                        </Box>
                        <Box border='1px solid white' p={1.5} borderRadius='7px' style={{ backgroundColor: 'black' }}>
                            <Typography variant='primary'>{moment(data.created_at).format('DD MMM YYYY')}</Typography>
                        </Box>
                    </Box></>}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} alignContent='center' 
                style={{padding: '0px 10px', margin: 'auto', display: isUserSubscriberOnly() ? 'none' : ''}}>
                {!isUserSubscriberOnly() &&
                    <>
                    <Box component='div' width='full' className={gridItem}>
                        <Box mb={1}>
                            <Typography>Country: </Typography>
                        </Box>
                        <Select 
                            options={selector.countries}
                            value={getSelectedCountry()}
                            styles={customStyles} 
                            onChange={setSelectedCountry}
                            isDisabled={patch}
                        />
                    </Box>                   

                    <Box component='div' width='full' className={gridItem}>
                        <Box mb={1}>
                            <Typography>State: </Typography>
                        </Box>
                        <Select 
                            options={selector.states}
                            value={getSelectedState()} 
                            name="state"
                            styles={customStyles} 
                            onChange={setSelectedState}
                            isDisabled={patch}
                        />
                    </Box>
                    <Box component='div' width='full' className={gridItem}>
                        <Box mb={1}>
                            <Typography>City: </Typography>
                        </Box>
                        <Creatable
                            options={selector.cities}
                            value={getSelectedCity()} 
                            name="city"
                            id='city'
                            styles={customStyles} 
                            onChange={setSelectedCity}
                            isDisabled={patch}
                        />
                    </Box>
                    </>}
                    </Grid>
                    <Box component='div' style={{width: '100%'}}>
                        {patch ? 
                        <Button className={button} onClick={() => setPatch(!patch)}>Edit Profile</Button> :
                        <Button type="submit" className={button} size='large' onClick={patchProfile}>
                                Submit
                        </Button>}
                    </Box>
            </Grid>
    </FormControl>
    : null }</>
    )
}

export default ProfileForm;