import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import TagsComponent from "./TagsComponent";
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    transition: '0.3s',
    position: 'relative',
    maxWidth: 500,
    marginLeft: 'auto',
    overflow: 'initial',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(0.5),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '100%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    minWidth: '50%',
    padding: 24,
    overflowWrap: "anywhere",
    color: 'white'
  },
  cta: {
    marginTop: 24,
    color: 'white',
    textTransform: 'initial',
  },
  button: {
    border: '1px solid #fed108', 
    borderRadius: '5px', 
    color: '#fed108',
    "&:hover": {
      backgroundColor: '#fed108',
      color: 'black'
    }
  }
}));

const BlogSingleItem = ({blog}) => {
  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();
  const history = useHistory()

  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        className={styles.media}
        onClick={() => history.push( "/developing/" +  blog.slug)} 
        image={blog.featured_image}
      />
      <CardContent className={styles.content}>
        <TextInfoContent
          overline={moment(blog.created_at).format("DD MMM YYYY")}
          heading={blog.title.length < 50 ? blog.title : blog.title.substring(0, 50) + "..."}
          body={blog.short_description.substring(0, 100) + "..."}
        />
        <TagsComponent tags={blog.tags} />
        <Button className={styles.button}
        onClick={() => history.push("/developing/" +  blog.slug)} 
        >Read more</Button>
      </CardContent>
    </Card>
)};

export default BlogSingleItem