import { Component } from "react";
import '../styles/RulesPage.css'


class RulesPage extends Component{
    render(){
        return (
            <div className='main'>
                <h1>Vettage Content Policy</h1>
                <h3>Vettage is a dynamic tool for journalists to connect directly with their audiences, enabling quality journalistic content created, run, and regulated by you, the Vettage users.</h3>
                <p>On Vettage, you can participate in a content revolution designed to filter out non-credible news content by 
                    financially incentivizing credibility and overall quality. In the “Developing Story” area, 
                    you may also comment, discuss, learn, debate, support, and connect with people who share your 
                    desire for a healthier information landscape. </p>
                <p>While you may not relate to every story (and you may even find some offensive), 
                    reportage should never be used as political advertising, much less as a public relations tool, 
                    nor should it ever be suppressed in any effort characterized by intentionally misleading audiences. 
                    Likewise, everyone on Vettage should have an expectation of privacy and safety, so please respect the
                    privacy and safety of others.</p>
                <p>The character and culture climate of Vettage is defined 
                    by its users. This culture is shaped implicitly by the contributions of its members. <br/>
                    Below are the rules that apply to everyone on Vettage. These rules are enforced by us, the admins. <br/>
                    Vettage is only what we make of it together, and can only exist if we operate by a 
                    shared set of rules. We ask that you abide by not just the letter of these rules, but the spirit as well.
                    </p>
                <div>
                    <hr className='divider'></hr>
                    <h2 style={{fontWeight: 'bold'}}>Rules</h2>
                    <h4>Rule 1</h4>
                    <p>Remember the human. Vettage is a place for positive engagement, not for attacking marginalized or 
                        vulnerable groups of people. Everyone has a right to use Vettage free of harassment, bullying, and 
                        threats of violence. Communities and users that incite violence or that promote hate based on identity or 
                        vulnerability will be banned.</p>
                    <h4>Rule 2</h4>
                    <p>Post and support authentic content which contributes to important areas of public knowledge; posting of AI (artificial intelligence or otherwise deceptively manipulated material through digital or other means) is strictly forbidden and will result in a permanent ban; and 
                        do not cheat or engage in any other type of manipulation (including spamming, rating manipulation, ban evasion, 
                        or subscriber fraud) or otherwise interfere with or disrupt the Vettage community.</p>
                    <h4>Rule 3</h4>
                    <p>Respect the privacy of others. Instigating harassment, for example by revealing someone’s personal 
                        or confidential information, is not allowed. Never post or threaten to post intimate or sexually-explicit 
                        media of anyone inside or outside the community.</p>
                    <h4>Rule 4</h4>
                    <p>Do not post or encourage the posting of sexual or suggestive content involving minors.</p>
                    <h4>Rule 5</h4>
                    <p>You don’t have to use your real name to use Vettage, but don’t impersonate an individual or an  entity in a misleading or deceptive manner.</p>
                    <h4>Rule 6</h4>
                    <p>Ensure people have predictable experiences on Vettage by using appropriately descriptive keywords, particularly content that is graphic, sexually-explicit,
                         or potentially offensive.</p>
                    <h4>Rule 7</h4>
                    <p>Ensure people have predictable experiences on Vettage by using appropriately descriptive keywords, particularly content that is graphic, sexually-explicit,
                         or potentially offensive.</p>
                    <h4>Rule 8</h4>
                    <p>Don’t break the site or do anything that interferes with normal use of Vettage.</p>
                </div>
                <div>
                    <hr className='divider'></hr>
                    <h2><strong>EDITORS</strong></h2>
                    <h5>To earn higher ratings (and pay), avoid posting these:</h5>
                    <p>We have a variety of ways of enforcing our rules, including, but not limited to:</p>
                    <div>
                        <h4>Not news</h4>
                        <p>A story that has lost relevancy due to age or being superseded by new information, does not properly 
                            afford full understanding of a story, or is not important to the public, or is false or includes provably 
                            false information. Breaking news, stories that could be considered “ambulance-chasing” without larger
                             context or implications are not allowed.</p>
                    </div>
                    <div>
                        <h4>Opinion/analysis or advocacy piece</h4>
                        <p>Opinion and analysis are left to the pundits filling TV’s 24-hour 
                            news cycles and are not allowed. This includes articles with editorial opinion, personal conjecture, or
                             otherwise. Press releases with an editorial slant, and analysis of news events (rather than reporting on
                              the event itself) are not allowed. Submissions regarding advocacy for a certain point of view or ideology rather 
                              than objective reporting should be reviewed under this rule.</p>
                    </div>
                    <div>
                        <h4>Politics</h4>
                        <p>Rhetoric is not allowed. Substantial events within political institutions by themselves are generally
                             not relevant, but are reviewed on a case-by-case basis.</p>
                    </div>
                    <div>
                        <h4>Rhetorical Titles</h4>
                        <p>Titles of a rhetorical nature are not allowed. Titles should be unbiased and strictly factual.</p>
                    </div>
                    <div>
                        <h4>Paywall or blogspam/steals content</h4>
                        <p>Both Raw Content Contributes and Editors are required to tick the Copyright box before submitting content. 
                            Submissions which point to paywalled material or non-original content stealing or reposting content from 
                            news sites, or simply rehashed stories without added substance, are not allowed.</p>
                    </div>
                    <div>
                        <h4>Covers an already-submitted story</h4>
                        <p>If a popular story is submitted while the same event is covered in another submission, it may be subject to removal. 
                            If a story was already submitted but did not earn above a 50% average rating, then the rule is not applicable. </p>
                    </div>
                    <div>
                        <h4>Racist, sexist, vitriolic, or overly crude</h4>
                        <p>Content containing racist, sexist or bigoted slurs are subject to removal. Content which promotes racism, 
                            sexism or bigotry (including a viewpoint in relation to those) are subject to removal, and users whom engage 
                            in submitting such content may be banned at the mods' discretion. Overly crude material which adds nothing to 
                            discussion are also subject to removal. These include inappropriate or inflammatory comments, personal attacks, etc.</p>
                    </div>
                    <div>
                        <h4>Unnecessarily rude or provocative</h4>
                        <p>In conjunction with vitriolic and crude content, a submission that is unnecessarily rude (inflammatory, personal 
                            attacks) or purposefully provocative (baiting) are subject to removal. Advocating for or celebrating the 
                            death of another human being is grounds for a permanent ban. Regardless of what you think about a person, 
                            this type of discourse is unwelcome on Vettage and we will not hesitate to remove you for it.
                        </p>
                    </div>
                    <div>
                        <h4>Cheap or distracting joke or meme</h4>
                        <p>Jokes, memes, puns, and overall low quality submissions are subject to removal.</p>
                    </div>
                    <div>
                        <h4>Witch hunting</h4>
                        <p>Any submission which invokes a witch-hunt is not allowed. Contact information publicly revealed about 
                            the person or organization in question is allowed so long as it is not being used to incite personal 
                            harassment, and doesn't contain personal contact information (home phone number, information of 
                            non-public family members, etc.). <strong>To help ensure that such material isn’t removed, provide a 
                            link to any public source where this information is contained.</strong>
                        </p>
                    </div>
                    <div>
                        <h4>Advocating or celebrating death</h4>
                        <p>While civil criticism of others is allowed (whether living or deceased), advocating or celebrating the
                             death of others, including public figures, is not.
                        </p>
                    </div>
                    <div>
                        <h4>Non-professional Feature Stories</h4>
                        <p>Feature stories are generally allowed, within professional bounds. Dictionary.com defines feature stories as “a 
                            newspaper or magazine article or report of a person, event, an aspect of a major event, or the like, often having a 
                            personal slant and written in an individual style."
                        </p>
                    </div>
                    <div>
                        <h4>Raw Content without context</h4>
                        <p>Images, videos or audio files with discernible context (i.e. Photo essay with titles and captions, produced video with titles, 
                            introductory or reporter guidance relating it to the reported story, or podcast or NPR-style audio report containing all relevant
                             context within a defined story) are acceptable. Merely providing image captions or video descriptions isn't sufficient.
                        </p>
                    </div>
                    <div>
                        <h4>Languages</h4>
                        <p>Vettage certainly allows — and encourages — the posting of content in other languages, provided the Rules set forth in this Agreement are followed. 
                        </p>
                    </div>
                </div>
                <div>
                </div>
            </div>
        )
    }
}

export default RulesPage
