import axios from './custom-axios';
import AuthService from './authService';

class ContributorsService {

    uploadContent(data) {

        let tags = data.tags.map(item => item.text)

        return axios
            .post("/content/contributor/raw/", {
                "content_type": data.content_type.value,
                "tags": tags,
                "copyright": data.copyright,
                "raw_content_address": {
                    "country": data.raw_content_address.country,
                    "state": data.raw_content_address.state,
                    "city": data.raw_content_address.city,
                    "zip_code": data.raw_content_address.zip_code
                },
                "event_time": data.event_time,
                "link": data.link
            }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    
    getContent(limit, currentPage, country, contentType){
        return axios
        .get("/content/contributor/raw/?limit=" + limit + "&page=" + (currentPage + 1) + "&country=" + country +
        "&content_type=" + contentType , { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    
    getContentById(id){
        return axios
        .get("/content/contributor/raw/" + id, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }


    patchContent(data) {
        let tags = data.tags.map(item => item.text)

        return axios
        .patch("/content/contributor/raw/" + data.id, {
            "content_type": data.content_type.value,
            "tags": tags,
            "copyright": data.copyright,
            "raw_content_address": {
                "country": data.raw_content_address.country,
                "state": data.raw_content_address.state,
                "city": data.raw_content_address.city,
                "zip_code": data.raw_content_address.zip_code
            },
            "event_time": data.event_time,
            "link": data.link
        }, { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }

    deleteContent(id){
        return axios
        .delete("/content/contributor/raw/" + id , { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
    }
}

export default new ContributorsService();