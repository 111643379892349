import { Component } from "react";
import { Link } from 'react-router-dom'
import { Box, Button, Container, Grid } from '@material-ui/core'
import '../styles/404page.css'

class NotFound extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Container maxWidth='lg' width='lg' style={{ padding: '20vh 0' }}>
                <Box margin='auto' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', maxWidth: '70vh', padding: '5% 0' }}>
                    <Box>
                        <Box margin='auto'>
                            <Box component='div' display='inline-block' style={{ width: '82%' }}>
                                <p style={{ fontFamily: 'Roboto Mono', fontSize: '23px' }}>404, page not found.</p>
                            </Box>
                        </Box>
                        <Grid container  lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px' }}>
                            <Grid item lg={5} md={5} sm={12} xs={12} style={{margin: '8px auto'}}>
                                <Link to='/login' style={{ textDecoration: 'none' }}>
                                    <Button variant='contained' 
                                    style={{ color: 'black', border: '1px solid black', backgroundColor: 'white'}}>Login</Button>
                                </Link>
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} style={{margin: 'auto'}}>
                                <Link to='/content' style={{ textDecoration: 'none' }}>
                                    <Button variant='contained' color='primary'
                                    style={{ color: 'black', border: '1px solid black', backgroundColor: 'white'}}>Stories</Button></Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }
}

export default NotFound;