// ** React Imports
import { Component } from 'react'
// ** Table Columns
import { columns } from './columns'

// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import DataTable from 'react-data-table-component'
import { Label, Input, CustomInput, Row, Card } from 'reactstrap'
import Select from 'react-select';
import { CustomToast, Toast } from '../../../components/toastify'

import '../../../styles/scss/react-dataTable-component.scss'
import '../../../styles/pagination.css'
import EditorService from "../../../services/editorsService";
import { Button, Box, Container, Grid, Typography, withStyles } from '@material-ui/core';


const customStyles = {
  option: (provided, state) => ({
      ...provided,
      opacity: 1,
      color: state.isSelected ? '#fed108' : 'black',
      backgroundColor: state.isSelected ? 'black' : 'white'
  }),
}

const CustomHeader = ({ filterCountry, filterType, contentOptions, resetFilter, handleFilterSearch,
  handleFilter, handlePerPage, rowsPerPage, button }) => {
 return (
  <Box component='div' fullWidth width='100%' marginBottom={1.5} backgroundColor='transparent'>
    <Grid container lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={3} md={3} sm={12} xs={12} style={{marginRight: '6%', marginBottom: '15px'}}>
        <Box component='div' margin='auto' border='1px solid silver' borderRadius='10px' style={{backgroundColor: 'black'}}> 
          <Typography style={{fontSize: '2em', color: 'white'}}>Submitted</Typography>
        </Box>
      </Grid>
      <Grid  item lg={8} md={8} sm={12} xs={12}>
        <Row>
          <Grid container lg={12} md={12} sm={12} xs={12}> 
              <Grid item lg={2} md={2} sm={5} xs={5} style={{marginRight: '0.5em'}}>
                    <div className='d-flex align-items-center mr-2'>
                      <Box component='div' fullWidth marginBottom={1} >
                        <Label for='rows-per-page' style={{color: 'white'}}>Show Per Page</Label>
                      </Box>
                      <CustomInput
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={handlePerPage}
                        style={{ borderRadius: '5px', padding: '5px 7px', borderColor: 'silver', outline: 'none'}}
                      >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                      </CustomInput>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} sm={5} xs={5} style={{marginRight: '0.5em' }}>
                      <Box component='div' fullWidth marginBottom={1}>
                        <Label for='search-country' style={{color: 'white'}}>Filter by Country</Label>
                      </Box>
                      <Input
                        id='search-country'
                        type='text'
                        name="filterCountry"
                        style={{ border: '1.5px solid silver', borderRadius: '5px', padding: '5px 7px', outline: 'none'}}
                        value={filterCountry}
                        onChange={e => handleFilter(e, "")}
                        placeholder='Search Country'
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12} style={{marginRight: '0.5em', marginBottom: '10px'}}>
                      <Box component='div' fullWidth marginBottom={1}>
                        <Label for='search-type' style={{color: 'white'}}>Filter by Story Type</Label>
                      </Box>
                      <Select 
                              name="filterType"
                              value={contentOptions.find(item => item.value === filterType)}
                              options={contentOptions} 
                              styles={customStyles}
                              onChange={e => handleFilter(e.value.toUpperCase(), "type")} />
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12} style={{margin: 'auto 0'}}>
                      <Box component='div' mb={1}>
                      <Button className={button}
                        size='small' onClick={resetFilter}>Reset</Button>
                      </Box>
                      <Box component='div'>
                      <Button className={button}
                        size='small' onClick={handleFilterSearch}>Search</Button>
                      </Box>
                    </Grid>
              </Grid> 
        </Row>
      </Grid>
     </Grid>
   </Box>
 )
}

const styles = theme => ({ 
  button: {
    padding: '0px',
    color: '#fed108',
    backgroundColor: 'black',
    border: '1px solid #fed108',
    '& span':{
      padding: '8px 15px'
    },
    '& :hover':{
      color: 'black',
      backgroundColor: '#fed108'
    }
  }
})



class EditorListPage extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            content:[],
            count: 0,
            filterCountry: "",
            filterType: "",
            currentPage: 0,
            rowsPerPage: 10,
            contentOptions: [
              {"value": "", "label": "Select"}, {"value": "MULTIMEDIA", "label": "Multimedia"}, 
              {"value": "animation", "label": "Animation"}, {"value": "audio", "label": "Audio"}, {"value": "graphic", "label": "Graphic"},
              {"value": "photo", "label": "Photo"}, {"value": "text", "label": "Text"}, {"value": "video", "label": "Video"},
          ]
        }

    }
    
   
    getData = () => {
      EditorService.getContent(this.state.rowsPerPage, this.state.currentPage, this.state.filterCountry, this.state.filterType)
      .then(response => {
        if(response.status === 200){
          this.setState({
            content: response.data.results,
            count: response.data.count
          })
        }else{
          Toast('error', 'There was an error with the request. Please try again later.')
        }
      })
    }

    componentDidMount() {
      this.getData()
    }

    resetFilter = () => {
     this.setState({
      filterType: "",
      filterCountry: ""
     }, () => this.getData())
    }

    handlePerPage = e => {
      this.setState({
        rowsPerPage: parseInt(e.target.value)
      }, () => this.getData())
    }

    setFilter = (e, type) => {
      if(type === "type"){
        this.setState({
          filterType: e
        }, this.filter)
      }else{
        this.setState({
          [e.target.name]: e.target.value
        }, this.filter) 
      }
    }
    
    handlePagination = page => {
      this.setState({
        currentPage: page.selected
      }, () => this.getData())
    }

    CustomPagination = () => {
      const count = Number(Math.ceil((this.state.count / this.state.rowsPerPage)))


      return (
        <Box component='div' className='pagination'>
          <ReactPaginate
            pageCount={count || 1}
            nextLabel='&rarr;'
            breakLabel='...'
            previousLabel='&larr;'
            activeClassName='active'
            forcePage={this.state.currentPage}
            onPageChange={page => this.handlePagination(page)}
          />
        </Box>
      )
    }

      render() {
        return (
          <Container style={{padding: '2% 2%',}}>
            <CustomToast />
            <Card style={{ padding: '1% 3%', minHeight: '80vh', backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  subHeader={true}
                  columns={columns}
                  responsive={true}
                  sortIcon={<ChevronDown />}
                  className='react-dataTable'
                  defaultSortField='invoiceId'
                  paginationDefaultPage={this.state.currentPage}
                  paginationComponent={this.CustomPagination}
                  data={this.state.content}
                  subHeaderComponent={
                    <CustomHeader
                    value={this.state.value}
                    rowsPerPage={this.state.rowsPerPage}
                    contentOptions={this.state.contentOptions}
                    filterCountry={this.state.filterCountry}
                    filterType={this.state.filterType}
                    resetFilter={this.resetFilter}
                    handleFilterSearch={this.getData}
                    handleFilter={this.setFilter}
                    handlePerPage={this.handlePerPage}
                    button={this.props.classes.button}
                  />
                  }
                />
            </Card>
          </Container>

        ) 
      }
}

export default withStyles(styles)(EditorListPage)
