import { useState, useEffect } from 'react';
import authService from '../../services/authService';

import clsx from 'clsx';
import { AccountCircleOutlined, PersonAddOutlined } from '@material-ui/icons';
import { makeStyles, withStyles, Button, Typography, StepConnector, Stepper, StepLabel, Step, Container, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import UserDetailsForm from "./UserDetailsForm";
import UserTypeForm from "./UserTypeForm";
import { isEmail } from "validator";
import { useHistory } from "react-router-dom";
import { CustomToast, Toast } from '../toastify';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg, #888 0%, #fed108 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    border: '1px solid #fed108',
    backgroundColor: 'black',
    color: '#fed108',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    border: '1px solid #fed108',
    backgroundColor: '#fed108',
    color: 'black',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AccountCircleOutlined />,
    2: <PersonAddOutlined />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  button: {
    '&.Mui-disabled':{
      borderColor: '#888',
      color: '#888'
    },
    padding: 0, 
    border: '1px solid #fed108',
    borderRadius: '0px',
    color: '#fed108',
    backgroundColor: 'black',
    marginRight: theme.spacing(1),
    '& :hover': {
      backgroundColor: '#fed108',
      color: 'black'
    },
    '& span': {
      padding: '8px 15px'
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const validateDetails = (email, password) => {
    if(email === "" || password === ""){
        Toast("error", "All of the fields are required.")
        return false
    }
    if(!isEmail(email)){
        Toast("error", "Email is not valid.")
        return false
    }
    if(password?.length <= 8 || password?.length >= 40){
        Toast("error", "Password is not valid. The length must be between 8 and 40 characters.")
        return false
    }
    return true
}

const validateType = (username, role, tokens) => {
  if(username === "" || role === ""){
    Toast("error", "All of the fields are required.")
    return false
  }
  if(tokens === "" && role === "SUB_PERVIEW"){
    Toast("Please enter the number of credits you'd like to purchase.")
    return false;
  }
   return true
}

const SignUpForm = (props) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [number_token, setNumberToken] = useState("");
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['User Information', 'Username and Account Type'];

    let setPaymentMethodCallback = () => {}

    useEffect(async () => {
      if(loading){
        if(activeStep === 0){
          if(!validateDetails(email, password)){
            setLoading(false)
            return;
          }

          // Check if email is available
          authService.checkEmail(email)
          .then(result => {
            if(result.status === 200){
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setLoading(false)
              return
            }else if(result.status === 400){
              Toast("error", "The email has been taken.")
              setLoading(false)
              return;
            }
          })
        }

        if(activeStep === 1){
          if(!validateType(username, role, number_token)){
            setLoading(false)
            return
          }  
          // Check if username is available
          authService.checkUsername(username)
          .then((result) => {
            if(result.status === 400){
              Toast('error', "The username has been taken.")
              setLoading(false)
              return;
            }
          })
          
          let paymentPayload = null
          if(role !== "SUB_FREE"){
            paymentPayload = await setPaymentMethodCallback()
            console.log(paymentPayload);
            if(paymentPayload === null || paymentPayload === "undefined") { setLoading(false); return; }
          }
          authService.register(email, password, username, role, {...paymentPayload, tokens_count: number_token})
          .then(result => {
            console.log(result);
            if(result.status === 201){           
              history.push({
                pathname: "/login",
                state: {"type": "success", "message": "You have succesfully registered. Please confirm your email to log in."}
              })
            }else{
              Toast('error', "There was an error with the fields. Please recheck them and make sure all of them are filled.")
              setLoading(false)
              return
            }
          })
        }
      }
    }, [loading])

    const handleNext =  () => {
      setLoading(true)
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
            return <UserDetailsForm setEmail={setEmail} setPassword={setPassword} email={email} password={password}/>;
            case 1:
            return <UserTypeForm setPaymentMethodCallback={click => setPaymentMethodCallback = click} role={role} username={username} 
             setRole={setRole} setUsername={setUsername} setNumberToken={setNumberToken} numberTokens={number_token}/>;
            default:
            return 'Unknown step';
        }
    }
    
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
      <Container maxWidth='md'>
        <CustomToast />
        <Box className={classes.root}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{ background: 'transparent' }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Typography variant='subtitle1' style={{color: 'white'}}>{label}</Typography>
                  </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box>
              <Box>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                  <Box>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} size='large'>
                      Back
                    </Button>
                    <Button
                      // variant="outlined"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={loading}
                      size='large'
                    >
                      {activeStep === steps.length - 1 ? 'Sign Up' : 'Next'}
                    </Button>
                  </Box>
              </Box>
          </Box>
        </Box>
        </Container>
      );
}

export default SignUpForm;