import { useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { WithContext as ReactTags } from 'react-tag-input';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { FormControl, TextField, Button, Box, makeStyles, Drawer, Typography } from '@material-ui/core';
import moment from 'moment';
import csc from 'country-state-city'

const contentOptions = [
    {"value": "MULTIMEDIA", "label": "Multimedia"}, 
    {"value": "ANIMATION", "label": "Animation"}, {"value": "AUDIO", "label": "Audio"}, {"value": "GRAPHIC", "label": "Graphic"},
    {"value": "PHOTO", "label": "Photo"}, {"value": "TEXT", "label": "Text"}, {"value": "VIDEO", "label": "Video"},
]

const useStyles = makeStyles(() => ({
    gridItem: {
      'margin': '25px auto',
      textAlign: 'center'
    },
    button: {
        padding: '0px',
        color: '#fed108',
        backgroundColor: 'black',
        border: '1px solid #fed108',
        '& span':{
          padding: '8px 15px'
        },
        '& :hover':{
          color: 'black',
          backgroundColor: '#fed108'
        }
  }
}));    


const customStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    }),
    control: (provided, state) => ({
      ...provided,
      opacity: 1,
    }),
    option: (provided, state) => ({
        ...provided,
        opacity: 1,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opacity: 1,
    })
  }

const ContentSearch = ({ setParams, open, onClose }) => {
    const [contentType, setContentType] = useState("")
    const [username, setUsername] = useState("")
    const [tags, setTags] = useState([])
    const [selector, setSelector] = useState({
        countries: csc.getAllCountries().map(item => ({value: item.isoCode, label: item.name})),
        country: null,
        states: [],
        state: null,
        cities: [],
        city: null,
    })
    const [date, setDate] = useState(null)
    const { gridItem, button } = useStyles();

    const handleSearch = () => {
        let newTags = tags.map(item => item.text)

        const params = {
            content_type: contentType,
            username: username,
            tags: newTags,
            country: selector.country ? selector.country.label : null,
            state: selector.state ? selector.state.label : null,
            city: selector.city ? selector.city.label : null,
            event_time: date ? moment(date).format('MM/DD/YYYY') : null
        }

        let paramsQuery = ""

        for (const key of Object.keys(params)) {
            if (params[key] !== null && params[key] !== "" && params[key]?.length !== 0) {
              paramsQuery += key + "=" + params[key] + "&"
            }
        }
        setParams(paramsQuery)
    }

    const clearSearch = () => {
        setContentType("")
        setUsername("")
        setTags([])
        setSelector({
            countries: csc.getAllCountries().map(item => ({value: item.isoCode, label: item.name})),
            country: null,
            states: [],
            state: null,
            cities: [],
            city: null,
        })
        setDate(null)
        setParams("")
    }
    
    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i))
    }
    
    const handleAddition = (tag) => {
        setTags([...tags, tag])
    }
    
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setTags(newTags)
    }

    const getSelectedCountry = () => {
        return selector.country;
    }

    const setSelectedCountry = event => {
        setSelector(prevState => ({
            ...prevState,
            country: { value: event.value, label: event.label },
            states: csc.getStatesOfCountry(event.value).map(item => ({value: item.isoCode, label: item.name})),
            state: null,
            cities: [],
            city: null
        }));
    }

    const getSelectedState = () => {
        return selector.state;
    }

    const setSelectedState = event => {
        setSelector(prevState => ({
            ...prevState,
            state: { value: event.value, label: event.label },
            cities: csc.getCitiesOfState(prevState.country.value, event.value).map(item => ({value: item.name, label: item.name})),
            city: null
        }));
    }

    const getSelectedCity = () => {
        return selector.city;
    }

    const setSelectedCity = event => {
        setSelector(prevState => ({
            ...prevState,
            city: { value: event.value, label: event.label }
        }));
    }

    return (
    <Drawer open={open} onClose={onClose} anchor='right'>
        <FormControl component="fieldset">
            <Box width='50vh' margin='30px 0px'>
                <Box component='div' width='250px' margin='auto'>
                    <Box textAlign='center' marginBottom='20px'>
                        <Typography variant='h6'>Story Search</Typography>
                    </Box>
                    <Box component='div' className={gridItem}>
                        <TextField
                            id="username outlined-required"
                            label="Username"
                            variant="outlined"
                            size='small'
                            name="username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </Box>
                    <Box component='div' className={gridItem}>
                        <Select id="content-type" name="contentType" 
                        styles={customStyles}
                        value={contentType !== "" ? contentOptions.find(item => item.value === contentType) : ""}
                        options={contentOptions}
                        styles={customStyles} 
                        onChange={e => setContentType(e.value)} 
                        />
                    </Box>
                    <Box component='div' className={gridItem}>
                        <ReactTags 
                            tags={tags}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                        />
                    </Box>
                    <Box component='div' width='full' className={gridItem}>
                            <Box>
                                <Typography>Select a country:</Typography>
                            </Box>
                            <Select
                                options={selector.countries}
                                value={getSelectedCountry()}
                                styles={customStyles}
                                onChange={setSelectedCountry}
                            />
                        </Box>
                        <Box component='div' width='full' className={gridItem}>
                            <Box mb={1}>
                                <Typography>State: </Typography>
                            </Box>
                            <Select 
                                options={selector.states}
                                value={getSelectedState()} 
                                name="state"
                                styles={customStyles} 
                                onChange={setSelectedState}
                            />
                        </Box>
                        <Box component='div' width='full' className={gridItem}>
                            <Box mb={1}>
                                <Typography>City: </Typography>
                            </Box>
                            <Creatable
                                options={selector.cities}
                                value={getSelectedCity()} 
                                name="city"
                                id='city'
                                styles={customStyles} 
                                onChange={setSelectedCity}
                            />
                        </Box>
                    <Box component='div' className={gridItem}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker"
                                label="Story Date"
                                name="eventDate"
                                onChange={e => setDate(e)}
                                value={date}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <Box margin='auto' textAlign='center'>
                        <Box>
                            <Button style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} size='large' 
                            onClick={handleSearch}>Search</Button>
                        </Box>
                        <Box component='div' margin={1}>
                            <Button style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} size='small'
                            onClick={clearSearch}>Clear Search</Button>
                        </Box>
                    </Box>
                    </Box>
                </Box>
        </FormControl>
    </Drawer>
    )
}

export default ContentSearch;
