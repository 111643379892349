import { Component } from "react";
import { Avatar, Link, TextField, Button, Typography, withStyles, CssBaseline, Box, Container  } from '@material-ui/core';
import { CustomToast, Toast } from '../components/toastify'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Form from "react-validation/build/form";
import authService from '../services/authService';
import { isEmail } from "validator";

const styles = theme => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10% 15%', 
      minHeight: '50vh', 
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: '#fed108',
      color: 'black'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    button: {
      padding: '0px',
      color: '#fed108',
      backgroundColor: 'black',
      border: '1px solid #fed108',
      '& span':{
        padding: '8px 15px'
      },
      '& :hover':{
        color: 'black',
        backgroundColor: '#fed108'
      }
}
  });
  

const CssTextField = withStyles({
    root: {
      '& label': {
          color: 'white'
      },
      '& label.Mui-focused': {
        color: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#fed108',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
      '& .MuiFormHelperText-root':{
          color: 'white'
      }
    }
})(TextField);

class ResetPassword extends Component{
    constructor(props){
        super(props)

        this.state = {
            email: ""
        }
    }

    resetPassword = e =>{
      e.preventDefault()

      if(!isEmail(this.state.email)){
          Toast("error", "Email is not valid.")
          return false
      } 

    authService.resetPassword(this.state.email)
    .then(response => {
        if(response.status === 200){
            Toast("success", "An email has been sent to " + this.state.email + ".")
        }
    })
    }

    render(){
        return (
          <Container style={{ padding: '2% 0', minHeight: '50vh', maxWidth: '35%' }} component='main'>
          <CustomToast />
            <CssBaseline />
            <div className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" style={{ color: 'white' }}>
                Reset Password
              </Typography>
              <Form
                className={this.props.classes.form}
                onSubmit={this.resetPassword}
                >
                    <CssTextField
                        type="text"
                        name="email"
                        margin="normal"
                        variant='outlined'
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        autoComplete="email"
                        autoFocus
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <Box mb={2}>
                      <Button
                        type="submit"
                        size='small'
                        className={this.props.classes.button}
                        disabled={this.state.loading}
                      >
                          Reset Password
                      </Button>
                    </Box>
                    <Link href="/login" variant="body2" style={{ color: 'white' }}>
                        {"Log In"}
                    </Link>
                  </Form>
                </div>
            </Container>
        )
    }
}


export default withStyles(styles)(ResetPassword)