import { Component } from 'react';
import authService from '../services/authService';

import Form from "react-validation/build/form";
import { Avatar, Link, TextField, Button, Grid, Typography, withStyles, CssBaseline,
  Box, Container  } from '@material-ui/core';
import { isEmail } from "validator";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { CustomToast, Toast } from '../components/toastify'


const CssTextField = withStyles({
  root: {
    '& label': {
        color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fed108',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'black',
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fed108',
      },
    },
  },
})(TextField);

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: '8% 25%',
    color: 'white'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#fed108',
    color: 'black'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: 'white',
    marginBottom: theme.spacing(1),
  },
  submit: {
    padding: 0,
    border: '1px solid #fed108',
    borderRadius: '0',
    color: '#fed108',
    backgroundColor: 'black',
    '& span':{
      padding: '5px 18px'
    },
    '& :hover': {
      color: 'black',
      backgroundColor: '#fed108',
    },
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    backgroundColor: 'black'
  }
});

const validateForm = (email, password) => {
  if(email === "" || password === ""){
    Toast("error", "All of the fields are required.")
    return false
  }
  if(!isEmail(email)){
      Toast("error", "Email is not valid.")
      return false
  }
  //TODO Uncomment this check
  // if(password?.length <= 8 || password?.length >= 40){
  //     Toast("error", "Password is not valid. The length must be between 8 and 40 characters.")
  //     return false
  // }
  return true
}

class LoginPage extends Component{
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            loading: false,
        }
      }
    
    componentDidMount(){
      if(this.props.location.state){
        setTimeout(() => {
          Toast(this.props.location.state.type, this.props.location.state.message)
        }, 1000)
      }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }


    handleLogin = e => {
        e.preventDefault();
        this.setState({
            loading: true
        }, () => {
          if(validateForm(this.state.email, this.state.password)){
              authService.login(this.state.email, this.state.password) 
              .then(result => {
                if(result.status === 200){
                  localStorage.setItem('vettageToken', result.data.key);

                  const roles = localStorage.getItem('roles').split(',');
                  if(roles.includes("EDITOR")){
                    window.location.replace('/editor/content')
                  }
                  else if(roles.includes("CONTRIBUTOR")){
                    window.location.replace('/contributor/content')
                  }
                  else if(["SUBSCRIBER", "SUB_FREE", "SUB_SUBSCRIPTION", "SUB_PERVIEW"].filter(role => roles.includes(role))){
                    window.location.replace('/content')
                  }
                  else {
                    window.location.replace('/profile')
                  }
                }else if(result.status === 400 && result.data.non_field_errors){
                  Toast('error', result.data.non_field_errors[0])
                }
              })
            }
        })

        this.setState({
            loading: false
        })
    }  

    render() {
        return (
            <Container component="main" maxWidth="md" style={{ padding: '3% 3%' }}>
            <CustomToast />
            <CssBaseline />
              <Box className={this.props.classes.paper}>
                <Avatar className={this.props.classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h4" >
                  Sign in
                </Typography>
                <Form
                  className={this.props.classes.form}
                  onSubmit={this.handleLogin}
                  >
                      <Typography variant='textSecondary' style={{ color: 'silver' }}>Both fields are case-sensitive.</Typography>
                      <CssTextField
                          type="text"
                          name="email"
                          margin="normal"
                          variant='outlined'
                          required
                          fullWidth
                          label="Email Address"
                          autoComplete="email"
                          InputProps={{
                            className: styles.input
                          }}
                          value={this.state.email}
                          onChange={this.handleChange}
                      />
                      <CssTextField
                          margin="normal"
                          required
                          fullWidth
                          variant='outlined'
                          name="password"
                          label="Password"
                          type="password"
                          autoComplete="current-password"
                          value={this.state.password}
                          onChange={this.handleChange}
                      />
                      <Box margin='auto'>
                        <Button
                          disabled={this.state.loading}
                          type="submit"
                          variant="outlined"
                          className={this.props.classes.submit}
                          disabled={this.state.loading}
                          size='large'
                        >
                          Login
                        </Button>
                      </Box>
                      <Grid container>
                        <Grid item xs>
                          <Link href="password-reset" variant="body2" style={{ color: '#888'}}>
                            Forgot password?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="/signup" variant="body2"  style={{ color: '#888'}}>
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Form>
                  </Box>
            </Container>
        )
    }
}

export default withStyles(styles)(LoginPage);