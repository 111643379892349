import { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    makeStyles,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import csc from 'country-state-city';
import moment from 'moment';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { WithContext as ReactTags } from 'react-tag-input';
import validator from 'validator';
import { Toast } from '../../components/toastify';
import contentService from '../../services/contentService';
import EditorService from '../../services/editorsService';
import '../../styles/styles.css';
import { CONTENT_TYPE_OPTIONS, ContentType } from '../../utilities/content-type';

const checkBoxStyles = (theme) => ({
    root: {
        color: 'white',
        '&.Mui-checked': {
            color: 'white !important',
        },
    },
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const useStyles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    gridItem: {
        margin: '0px 20px 20px 0px',
    },
    button: {
        margin: 'auto',
        padding: '0px',
        color: '#fed108',
        backgroundColor: 'black',
        border: '1px solid #fed108',
        '& span': {
            padding: '8px 15px',
        },
        '&.Mui-disabled': {
            border: '0px',
            backgroundColor: 'silver',
            color: 'black',
        },
        '& :hover': {
            color: 'black',
            backgroundColor: '#fed108',
        },
    },
});

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: '#fed108',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            color: 'white',
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#fed108',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fed108',
            },
        },
        '& .MuiFormHelperText-root': {
            color: 'white',
        },
    },
})(TextField);

const CssDateTimePicker = withStyles({
    root: {
        width: '100%',
        color: 'white',
        borderColor: 'white',
        '& label': {
            color: 'white',
        },
        '&  input': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '&  input': {
            color: 'white',
        },
        '& .MuiInput-underline:before': {
            borderColor: 'white',
        },
        '& .MuiInput-underline:focused': {
            borderColor: 'white',
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)',
        },
    },
})(DateTimePicker);

const customStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
    control: (provided, state) => ({
        ...provided,
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
    }),
    option: (provided, state) => ({
        ...provided,
        opacity: 1,
        color: state.isSelected ? '#fed108' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opacity: 1,
        color: 'white',
    }),
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class FinalContentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                content_type: '',
                title: '',
                description: '',
                thumbnail: '',
                preview: '',
                tags: [],
                contributors: [],
                copyright: false,
                final_content_address: {
                    country: '',
                    state: '',
                    city: '',
                    zip_code: '',
                },
                event_time: moment().format('YYYY-MM-DDThh:mm'),
                link: '',
            },
            classes: makeStyles(),
            countries: csc.getAllCountries().map((item) => ({ value: item.isoCode, label: item.name })),
            country: null,
            states: [],
            state: null,
            cities: [],
            city: null,
            contentOptions: CONTENT_TYPE_OPTIONS,
            error: false,
        };
    }

    componentDidMount() {
        if (this.props?.data) {
            const tags = this.props.data.tags.map((tag) => {
                return { id: tag, text: tag };
            });
            const contributors = this.props.data.content_contributors.map((item) => {
                return { id: item, text: item };
            });
            const contentType = this.state.contentOptions.find(
                (option) => option.value === this.props.data.content_type
            );

            this.setState({
                data: { ...this.props.data, tags: tags, content_type: contentType, contributors: contributors },
            });
        }
    }

    handleDelete = (i) => {
        const tags = this.state.data.tags;
        this.setState({
            data: { ...this.state.data, tags: tags.filter((tag, index) => index !== i) },
        });
    };

    handleAddition = (tag) => {
        this.setState((prevState) => ({ data: { ...prevState.data, tags: [...prevState.data.tags, tag] } }));
    };

    handleDrag = (tag, currPos, newPos) => {
        const tags = [...this.state.data.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        this.setState({ data: { ...this.state.data, tags: newTags } });
    };

    handleDeleteContribs = (i) => {
        const contributors = this.state.data.contributors;
        this.setState({
            data: { ...this.state.data, contributors: contributors.filter((contrib, index) => index !== i) },
        });
    };

    handleAdditionContribs = (contributor) => {
        EditorService.checkUserContributor(contributor.text).then((response) => {
            if (response.status === 200) {
                this.setState((prevState) => ({
                    data: { ...prevState.data, contributors: [...prevState.data.contributors, contributor] },
                }));
            } else {
                Toast('error', response.data?.error);
            }
        });
    };

    handleDragContribs = (contributor, currPos, newPos) => {
        const contributors = [...this.state.data.contributors];
        const newContributors = contributors.slice();

        newContributors.splice(currPos, 1);
        newContributors.splice(newPos, 0, contributor);

        this.setState({ data: { ...this.state.data, contributors: newContributors } });
    };

    checkValidUrl = (contentType, url) => {
        const types = contentService.getExtensions(contentType);
        var parts = url.split('.');

        var extension = parts[parts?.length - 1];

        if (types.indexOf(extension) !== -1) {
            return true;
        } else {
            return false;
        }
    };

    handleSubmit = (e) => {
        this.props.handleUpload(e, this.state.data);
        this.setState({ error: true });
    };

    getSelectedCountry = () => {
        if (!this.state.country && !!this.state.data.final_content_address.country) {
            const country = csc
                .getAllCountries()
                .find((item) => item.name === this.state.data.final_content_address.country);
            this.setState({ country: { value: country.isoCode, label: country.name } });
        }
        return this.state.country;
    };

    setSelectedCountry = (event) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                final_content_address: {
                    ...prevState.data.final_content_address,
                    country: event.label,
                    city: '',
                    state: '',
                },
            },
            country: { value: event.value, label: event.label },
            states: csc.getStatesOfCountry(event.value).map((item) => ({ value: item.isoCode, label: item.name })),
            state: null,
            cities: [],
            city: null,
        }));
    };

    getSelectedState = () => {
        if (!!this.state.country && !this.state.state && !!this.state.data.final_content_address.state) {
            const country = this.getSelectedCountry();
            const state = csc
                .getStatesOfCountry(country.value)
                .find((item) => item.name === this.state.data.final_content_address.state);
            if (state) this.setState({ state: { value: state.isoCode, label: state.name } });
        }
        return this.state.state;
    };

    setSelectedState = (event) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                final_content_address: {
                    ...prevState.data.final_content_address,
                    state: event.label,
                },
            },
            state: { value: event.value, label: event.label },
            cities: csc
                .getCitiesOfState(prevState.country.value, event.value)
                .map((item) => ({ value: item.name, label: item.name })),
            city: null,
        }));
    };

    getSelectedCity = () => {
        if (
            !!this.state.country &&
            !!this.state.state &&
            !this.state.city &&
            !!this.state.data.final_content_address.state
        ) {
            const country = this.getSelectedCountry();
            const state = this.getSelectedState();
            const city = csc
                .getCitiesOfState(country.value, state.value)
                .find((item) => item.name === this.state.data.final_content_address.city);
            if (city) this.setState({ city: { value: city.name, label: city.name } });
        }
        return this.state.city;
    };

    setSelectedCity = (event) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                final_content_address: {
                    ...prevState.data.final_content_address,
                    city: event.label,
                },
            },
            city: { value: event.value, label: event.label },
        }));
    };

    renderHelperText = () => {
        return (
            <span>
                *For best results, we recommend an &nbsp;
                {
                    <a
                        style={{ color: 'white' }}
                        target="_blank"
                        href="https://docs.ipfs.io/how-to/websites-on-ipfs/single-page-website/"
                    >
                        IPFS
                    </a>
                }
                &nbsp;link. For video submissions, use IPFS link, Vimeo, or other direct link to content on server.
            </span>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <FormControl component="fieldset" className={this.state.classes.formControl}>
                <Box component="div">
                    <Grid container lg={12} md={12} xs={12} sm={12} style={{ margin: 'auto' }}>
                        <Grid item lg={5} md={5} xs={12} sm={12} style={{ margin: 'auto' }}>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <Typography style={{ color: 'white' }}>Story Type:</Typography>
                                <Select
                                    id="content-type"
                                    name="contentType"
                                    required
                                    value={this.state.data.content_type}
                                    options={this.state.contentOptions}
                                    styles={customStyles}
                                    onChange={(e) =>
                                        this.setState((prevState) => ({ data: { ...prevState.data, content_type: e } }))
                                    }
                                />
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <CssTextField
                                    required
                                    id="title"
                                    label="Title"
                                    variant="outlined"
                                    name="title"
                                    fullWidth
                                    inputProps={{ maxLength: 100 }}
                                    value={this.state.data.title}
                                    onChange={(e) =>
                                        this.setState((prevState) => ({
                                            data: { ...prevState.data, title: e.target.value },
                                        }))
                                    }
                                    error={this.state.data.title === '' && this.state.error}
                                    helperText={'The maximum amount of characters is 100.'}
                                />
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <CssTextField
                                    required
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    name="description"
                                    fullWidth
                                    value={this.state.data.description}
                                    onChange={(e) =>
                                        this.setState((prevState) => ({
                                            data: { ...prevState.data, description: e.target.value },
                                        }))
                                    }
                                    error={this.state.data.description === '' && this.state.error}
                                    helperText={"This field can't be empty"}
                                />
                            </Box>
                            {!!this.state.data.content_type && (
                                <Box component="div" width="full" className={classes.gridItem}>
                                    <CssTextField
                                        required
                                        defaultValue=""
                                        id="preview"
                                        label={`Preview ${this.state.data.content_type?.label}`}
                                        variant="outlined"
                                        name="preview"
                                        fullWidth
                                        value={this.state.data.preview}
                                        onChange={(e) =>
                                            this.setState((prevState) => ({
                                                data: { ...prevState.data, preview: e.target.value },
                                            }))
                                        }
                                        error={
                                            (this.state.data.preview === '' && this.state.error) ||
                                                (this.state.data.preview !== '' &&
                                                    (!validator.isURL(this.state.data.preview, {
                                                        require_valid_protocol: false,
                                                    }) ||
                                                        !this.checkValidUrl(this.state.data.content_type.value, this.state.data.preview)))
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            validator.isURL(this.state.data.preview, {
                                                require_valid_protocol: false,
                                            }) === false ||
                                                this.checkValidUrl(this.state.data.content_type.value, this.state.data.preview) === false ||
                                                this.state.error
                                                ? `Please enter a valid URL (Supported formats: ${contentService.getExtensions(this.state.data.content_type.value).join(', ')})`
                                                : ''
                                        }
                                    />
                                </Box>
                            )}
                            <Box component="div" width="full" className={classes.gridItem}>
                                <CssTextField
                                    required
                                    id="image-thum outlined-required"
                                    label="Thumbnail"
                                    variant="outlined"
                                    name="thumbnail"
                                    fullWidth
                                    value={this.state.data.thumbnail}
                                    onChange={(e) =>
                                        this.setState((prevState) => ({
                                            data: { ...prevState.data, thumbnail: e.target.value },
                                        }))
                                    }
                                    error={
                                        (this.state.data.thumbnail === '' && this.state.error) ||
                                            (this.state.data.thumbnail !== '' &&
                                                (!validator.isURL(this.state.data.thumbnail, {
                                                    require_valid_protocol: false,
                                                }) ||
                                                    !this.checkValidUrl(ContentType.Photo, this.state.data.thumbnail)))
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        validator.isURL(this.state.data.thumbnail, {
                                            require_valid_protocol: false,
                                        }) === false ||
                                            this.checkValidUrl(ContentType.Photo, this.state.data.thumbnail) === false ||
                                            this.state.error
                                            ? `Please enter a valid URL (Supported formats: ${contentService.getExtensions(ContentType.Photo).join(', ')})`
                                            : ''
                                    }
                                />
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <Typography style={{ margin: '10px', color: 'white' }}>Tags:</Typography>
                                <ReactTags
                                    tags={this.state.data.tags}
                                    handleDelete={this.handleDelete}
                                    handleAddition={this.handleAddition}
                                    handleDrag={this.handleDrag}
                                    delimiters={delimiters}
                                />
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <Typography style={{ margin: '10px', color: 'white' }}>Contributors:</Typography>
                                <ReactTags
                                    tags={this.state.data.contributors}
                                    handleDelete={this.handleDeleteContribs}
                                    handleAddition={this.handleAdditionContribs}
                                    handleDrag={this.handleDragContribs}
                                    delimiters={delimiters}
                                    placeholder={'Press enter to add new contributor.'}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={5} md={5} xs={12} sm={12} style={{ margin: 'auto' }}>
                            <Box border="1px solid white" padding="5px 15px" mb={3}>
                                <Box m={2}>
                                    <Typography variant="h6" style={{ color: 'white' }}>
                                        Story Location
                                    </Typography>
                                </Box>
                                <Box component="div" width="full" className={classes.gridItem}>
                                    <Box>
                                        <Typography style={{ color: 'white' }}>Select a country*:</Typography>
                                    </Box>
                                    <Select
                                        options={this.state.countries}
                                        value={this.getSelectedCountry()}
                                        styles={customStyles}
                                        onChange={this.setSelectedCountry}
                                    />
                                </Box>
                                <Box component="div" width="full" className={classes.gridItem}>
                                    <Box mb={1}>
                                        <Typography style={{ color: 'white' }}>State: </Typography>
                                    </Box>
                                    <Select
                                        options={this.state.states}
                                        value={this.getSelectedState()}
                                        name="state"
                                        styles={customStyles}
                                        onChange={this.setSelectedState}
                                    />
                                </Box>
                                <Box component="div" width="full" className={classes.gridItem}>
                                    <Box mb={1}>
                                        <Typography style={{ color: 'white' }}>City: </Typography>
                                    </Box>
                                    <Creatable
                                        options={this.state.cities}
                                        value={this.getSelectedCity()}
                                        name="city"
                                        id="city"
                                        styles={customStyles}
                                        onChange={this.setSelectedCity}
                                    />
                                </Box>
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <CssDateTimePicker
                                        required
                                        id="time"
                                        label="Story Date"
                                        name="eventTime"
                                        value={this.state.data.event_time}
                                        onChange={(value) =>
                                            this.setState((prevState) => ({
                                                data: { ...prevState.data, event_time: value },
                                            }))
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box component="div" width="full" className={classes.gridItem}>
                                <CssTextField
                                    required
                                    defaultValue=""
                                    id="link"
                                    label="Link to content"
                                    variant="outlined"
                                    name="link"
                                    fullWidth
                                    value={this.state.data.link}
                                    onChange={(e) =>
                                        this.setState((prevState) => ({
                                            data: { ...prevState.data, link: e.target.value },
                                        }))
                                    }
                                    error={
                                        validator.isURL(this.state.data.link, { require_valid_protocol: false }) ===
                                        false && this.state.error
                                    }
                                    helperText={this.renderHelperText()}
                                />
                            </Box>
                        </Grid>
                        <Box
                            component="div"
                            width="full"
                            fullWidth
                            className={classes.gridItem}
                            margin={'auto !important'}
                        >
                            <Box color="white">
                                <Typography style={{ textAlign: 'left' }}>
                                    I acknowledge the following Contributors as fellow copyright-owners to share awards
                                    for this collaborative work:
                                    <ul>
                                        {this.state.data.contributors.map((item) => {
                                            return <li>{item.text}</li>;
                                        })}
                                    </ul>
                                </Typography>
                                <CustomCheckbox
                                    checked={this.state.data.copyright}
                                    onChange={() =>
                                        this.setState((prevState) => ({
                                            data: { ...this.state.data, copyright: !prevState.data.copyright },
                                        }))
                                    }
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    type="submit"
                                    disabled={!this.state.data.copyright}
                                    size="large"
                                    onClick={(e) => this.handleSubmit(e)}
                                    className={this.props.classes.button}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </FormControl>
        );
    }
}

export default withStyles(useStyles)(FinalContentForm);
