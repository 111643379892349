import { Typography, Box, Chip  } from '@material-ui/core';
import { useHistory } from 'react-router-dom'

const TagsComponent = (props) => {
    const { tags } = props

    const handleClick = tag => {
        window.location.replace('/developing?tags=' + tag)
    }

    return (
        <div>
        {tags && 
            <Box component='div' marginTop={1} marginBottom={3}>
            <Box component='div' marginBottom='10px'>
              <Typography style={{ color: 'white' }}>Tags: </Typography>
            </Box>
                  {tags.map(tag => {
                    return (
                      <Chip
                        label={tag}
                        onClick={() => handleClick(tag)}
                        style={{border: '1px solid white', borderRadius: '5px', backgroundColor: 'black', color: 'white'}}
                      />
                    )
                  })} 
          </Box>
        }
        </div>
    )
    
}

export default TagsComponent