import { useState, useEffect } from 'react'
import { Dialog, DialogTitle, Button, Box, Typography, Divider, makeStyles } from '@material-ui/core';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SubscriptionService from '../../services/subscriptionService'
import { Toast } from '../toastify';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        'maxWidth': '100%',
        'width': '100%',
        'color': '#32325d',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  
const useStyles = makeStyles({
    button: {
        padding: '0px',
        color: 'black',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '0px',
        '& span':{
          padding: '8px 15px'
        },
        '& :hover':{
          color: 'black',
          backgroundColor: '#fed108'
        }
      }
});

const PaymentMethods = ({ open, onClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const styles = useStyles()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        SubscriptionService.getPaymentMethod()
        .then(result => {
            if(result.status === 200){
                setData(result.data.payment_methods)
            }
        })
    }, [])


    const handlePaymentMethod = async () => {
        setLoading(true)
        let cardElement = elements.getElement(CardElement)
        if(cardElement._complete === false){
          Toast("error", "Please finish the card information.")
          setLoading(false)
          return null;
        }
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          setLoading(false)
          return;
        }
  
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
          
        if(result.error){
            Toast("error", "There was an error with the request. Please try again later.")
            setLoading(false)
            return null;
        }else{
            SubscriptionService.addPaymentMethod(result)
            .then(resp => {
                if(resp.status === 200){
                    setData([...data, {"brand": resp.data.payment_method?.card?.brand, 
                    "last4": resp.data.payment_method?.card?.last4, 
                    "exp_month": resp.data.payment_method?.card?.exp_month, "exp_year": resp.data.payment_method?.card?.exp_year}])
                    Toast("success", resp.data?.message)
                    cardElement.clear()
                    onClose()
                }else{
                    Toast("error", resp.data?.error)
                }
            })
        }
        setLoading(false)
    }
      


    return (
        <>{data !== null &&
                <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" fullWidth maxWidth="sm" open={open}>
                    <Box component="div" flexWrap="wrap" justifyContent="center" minHeight="43vh" fullWidth alignItems="center" p={{ xs: 2, sm: 3, md: 4 }} height='43vh'>
                        <DialogTitle id="simple-dialog-title">Add a Payment Method</DialogTitle>
                        <Divider/>
                            <Box m={4}>
                                <Box component='div' border={1} p={2} borderRadius={10} borderColor={'silver'}>
                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                </Box>
                                <Box component='div' m={3} textAlign='center'>
                                    <Button onClick={handlePaymentMethod} className={styles.button}
                                     disabled={loading}>Submit</Button>
                                </Box>
                            </Box>
                            <Box component='div' textAlign={'center'}>
                                <Typography style={{fontSize: '14px', color: 'silver'}}>The new payment method will be set as a primary.</Typography>
                            </Box>
                            {/* {data.map(item => {
                                return (
                                    <Box component='div'>
                                        <Typography> Brand: {item?.brand}</Typography>
                                        <Typography> Number: **** - **** - {item?.last4}</Typography>
                                        <Typography> Expiry Month/Year: {item?.exp_month}/{item?.exp_year}</Typography>                                        
                                    </Box>
                                )
                            })} */}
                    </Box>
            </Dialog>
        }</>
    )
}

export default PaymentMethods