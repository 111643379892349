
import axios from './custom-axios'

class UtilServices {
    sendForm(name, email, message){
        return axios.post("/content/contact/", {
            "email": email,
            "name": name,
            'message': message
        })
    }
}

export default new UtilServices();
