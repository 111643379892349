import { Component } from "react";
import '../styles/RulesPage.css'


class UserAgreementPage extends Component{
    render(){
        return (
            <div className='main'>
                <h1>Vettage User Agreement</h1>
                <h3>As a Software-as-a-Service (“SaaS”), Vettage affords collaborative journalism to address the stories most vital 
                    to public interest and freedom from media monopoly. This User Agreement and your conduct make that possible.</h3>

                <p>Greetings. This Vettage User Agreement (“Terms”) applies to your access to and use of the site, 
                    mobile app, widget, API, emails, and other online products and services (collectively, the “Services”) 
                    provided by Vettage, LLC (“Vettage,” “we,” “us,” or “our”).By accessing or using our Services, you agree 
                    to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Services. 
                    Please take a look at Vettage’s <a href='/privacy'>Privacy Policy</a> too—it explains if, how and why we collect, use, and share 
                    information about you when you access or use our Services.</p>
                <div>
                    <ol className='user-agreement-list'>
                        <li><h2>1. Your Access to the Services</h2>
                            <div>
                                <p>
                                Children under the age of 13 are not allowed to create an Account or otherwise use the Services. 
                                Additionally, you must be over the age required by the laws of your country to create an account 
                                or otherwise use the Services, or we need to have received verifiable consent from your parent or 
                                legal guardian.<br/><br/>

                                In addition, certain of our Services or portions of our Services may require you to be 18 years 
                                of age or older, so please read all notices and any Additional Terms carefully when you access the Services.
                                <br/><br/>
                                If you are accepting these Terms on behalf of another legal entity, including a business 
                                or government entity, you represent that you have full legal authority to bind such entity to these Terms.
                                </p>
                            </div>
                        </li>
                        <li><h2>2. Your Use of the Services</h2>
                            <div>
                                <p>
                                Vettage grants you a personal, non-transferable, non-exclusive, revocable, limited license to use and 
                                access the Services solely as permitted by these Terms. We reserve all rights not expressly granted to 
                                you by these Terms.<br/><br/>

                                Except as permitted through the Services or as otherwise permitted by us in writing, your license does not include the right to:
                                <ol>
                                    <li>license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the Services or Content;</li>
                                    <li>modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the Services or Content; or</li>
                                    <li>access the Services or Content in order to build a similar or competitive website, product, or service, except as permitted following written approval.</li>
                                </ol>
                                <br/><br/>
                                We reserve the right to modify, suspend, or discontinue the Services (in whole or in part) at any time, with or without notice 
                                to you. Any future release, update, or other addition to functionality of the Services will be subject to these Terms, which 
                                may be updated from time to time. You agree that we will not be liable to you or to any third party for any modification, 
                                suspension, or discontinuation of the Services or any part thereof.
                                </p>
                            </div>
                        </li>
                        <li><h2>3. Your Vettage Account and Account Security</h2>
                            <div>
                                <p>
                                To use certain features of our Services, you may be required to create a Vettage account 
                                (an “<strong>Account</strong>”) 
                                and provide us with a username, password, and certain other information about yourself as set 
                                forth in the <a href='/privacy'>Privacy Policy.</a><br/><br/>
                                You are solely responsible for the information associated with your Account and anything that happens 
                                related to your Account. You must maintain the security of your Account and immediately &nbsp;
                                <a href='mailto:vettage@vettage.com'>notify Vettage</a>&nbsp;
                                if you discover or suspect that someone has accessed your Account without your permission. We recommend 
                                that you use a strong password that is used only with your Account and enable two-factor authentication 
                                if and when possible.
                                <br/><br/>
                                You will not license, sell, or transfer your Account without our prior written approval.
                                </p>
                            </div>
                        </li>
                        <li><h2>4. Your Content</h2>
                            <div>
                                The Services may contain information, text, links, graphics, photos, videos, or other materials 
                                (“<strong>Content</strong>”), including Content created or submitted to the Services by you or 
                                through your Account 
                                (“<strong>Your Content</strong>”). We take no responsibility for and Vettage itself does not 
                                expressly or implicitly endorse, support, or guarantee the completeness, truthfulness, accuracy, 
                                or reliability of any of Your Content. <br/><br/>
                                By submitting Your Content to the Services, you represent and warrant that you have all rights, power, 
                                and authority necessary to grant the rights to Your Content contained within these Terms. Because you 
                                alone are responsible for Your Content, you may expose yourself to liability if you post or share 
                                Content without all necessary rights.<br/><br/>
                                You retain any ownership rights you have in Your Content, but you grant Vettage the following license 
                                to use that Content:<br/><br/>
                                When Your Content is created with or submitted to the Services, you grant us a worldwide, royalty-free, 
                                perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use, copy, modify, 
                                adapt, prepare derivative works of, distribute, store, perform, and display Your Content and any name, 
                                username, voice, or likeness provided in connection with Your Content in all media formats and channels 
                                now known or later developed anywhere in the world. This license includes the right for us to make Your 
                                Content available for syndication, broadcast, distribution, or publication by other companies,
                                 organizations, or individuals who partner with Vettage. You also agree that we may remove metadata 
                                 associated with Your Content, and you irrevocably waive any claims and assertions of moral rights or 
                                 attribution with respect to Your Content.<br/><br/>
                                Any ideas, suggestions, and feedback about Vettage or our Services that you provide to us are entirely 
                                voluntary, and you agree that Vettage may use such ideas, suggestions, and feedback without compensation 
                                or obligation to you.<br/><br/>
                                Although we have no obligation to screen, edit, or monitor Your Content, we may, in our sole discretion, 
                                delete or remove Your Content at any time and for any reason, including for violating these Terms, 
                                violating our <a href='/rules'>Content Policy</a>, or if you otherwise create or are likely to create liability for us.
                            </div>
                        </li>
                        <li><h2>5. Third-Party Content, Advertisements, and Promotions</h2>
                            <div>
                                <p>
                                The Services contain links to third-party websites, which may be posted by other users 
                                (“<strong>Third-Party Content</strong>”). Third-Party Content is not 100% under our control, and we are not responsible
                                for any third party’s websites. Your use of Third-Party Content is at your own risk and you should make 
                                any investigation you feel necessary before proceeding with any transaction in connection with such 
                                Third-Party Content.<br/><br/>
                                As a principle however, Vettage is committed to remain 100% user-supported, in order to 
                                remove advertiser influence and ensure fair and accurate reporting. 
                                </p>
                            </div>
                        </li>
                        <li><h2>5. Editor Guidelines</h2>
                            <div>
                                <p>
                                When using or accessing Vettage, you must comply with these Terms and all applicable laws, rules, and regulations. 
                                Please review the <a href='/rules'>Content Policy</a> which is part of these Terms and contain Vettage’s rules about prohibited content 
                                and conduct. In addition to what is prohibited in the <a href='/rules'>Content Policy</a>, you may not do any of the following:
                                <ul>
                                    <li>Use the Services in any manner that could interfere with, disable, disrupt, overburden, or otherwise impair the Services.</li>
                                    <li>Gain access to (or attempt to gain access to) another user’s Account or any non-public 
                                        portions of the Services, including the computer systems or networks connected to or used 
                                        together with the Services.</li>
                                    <li>Upload, transmit, or distribute to or through the Services any viruses, worms, malicious code, 
                                        or other software intended to interfere with the Services, including its security-related features.</li>
                                    <li>Use the Services to violate applicable law or infringe any person’s or entity's intellectual property rights or any other proprietary rights.</li>
                                    <li>Access, search, or collect data from the Services by any means (automated or otherwise) except as permitted in these Terms or in a separate agreement with Vettage. 
                                        Scraping the Services without Vettage’s prior consent is prohibited.</li>
                                    <li>Use the Services in any manner that we reasonably believe to be an abuse of or fraud on Vettage or any payment system.</li>
                                </ul>
                                </p>
                                <p>
                                We encourage you to <a href='mailto:vettage@vettage.com'>report</a> content or conduct that you believe violates these Terms or our <a href='/rules'>Content Policy</a>. 
                                We also support the responsible reporting of security vulnerabilities. To report a security issue, 
                                please email <a href='mailto:vettage@vettage.com'>vettage@vettage.com</a>.
                                </p>

                            </div>
                        </li>
                        <li><h2>6. Remember the Content Policy</h2>
                            <div>
                                <p>You are obligated to comply with our <a href='/rules'>Content Policy</a>. 
                                </p>

                            </div>
                        </li>
                        <li><h2>7. Respect the Platform</h2>
                            <div>
                                <p>
                                Vettage may, at its discretion, intervene to take control of a dispute when it believes it in the best 
                                interest of the community or the website. This should happen rarely (e.g., a top Editor violates a Copyright agreement or 
                                fails to allot the fair share of Subscription earnings to a key Contributor), but when it does, our goal is to keep the platform 
                                alive and vibrant. Finally, when the admins contact you, we ask that you respond within a reasonable amount of time.
                                <br/><br/>
                                Where Editors consistently are in violation of these guidelines, Vettage may step in with actions to heal the 
                                issues - sometimes pure education of the Editor will do, but these actions could potentially include removing Editor 
                                status, prevention of future participation rights, as well as account deletion. We hope permanent actions will never become necessary.
                                </p>
                                <ul>
                                    <li>You agree that when you receive reports related to a story you post, you will take appropriate action, which may include 
                                        removing content that violates policy and/or promptly escalating to Vettage for review;</li>
                                    <li>You are not, and may not represent that you are, authorized to act on behalf of Vettage;</li>
                                    <li>You may not enter into any agreement with a third party on behalf of Vettage, or any story that you post on 
                                        Vettage, without our written approval;</li>
                                    <li>You may not alter your story in return for any form of compensation, consideration, gift, or favor from third parties;</li>
                                    <li>If you have access to non-public information as a result of sharing a story on Vettage, you will use such information 
                                        only in connection with your performance as an Editor; and</li>
                                    <li>You may otherwise edit your story, provided that such actions do not conflict with these Terms or the <a href='/rules'>Content Policy</a></li>
                                    <li>Vettage reserves the right, but has no obligation, to overturn any action or decision of an Editor if Vettage, in its sole discretion, believes 
                                        that such action or decision is not in the interest of Vettage or the Vettage community.</li>
                                </ul>
                            </div>
                        </li>
                        <li><h2>8. Copyright, Trademark, the DMCA, and Takedowns</h2>
                            <div>
                                <p>
                                Vettage respects the intellectual property of others and requires that users of our Services do the same. 
                                We have a policy that includes the removal of any infringing material from the Services and for the termination, 
                                in appropriate circumstances, of users of our Services who are repeat infringers. If you believe that anything on 
                                our Services infringes a copyright or a trademark that you own or control, you may notify Vettage’s Designated Agent. 
                                <br/><br/>
                                Also, please note that if you knowingly misrepresent that any activity or material on our Service is infringing, 
                                you may be liable to Vettage for certain costs and damages.
                                <br/><br/>
                                If we remove Your Content in response to a copyright or trademark notice, we will notify you via Vettage’s 
                                private messaging system. If you believe Your Content was wrongly removed due to a mistake or misidentification 
                                in a copyright notice, you can send a counter notification via our 
                                <a href='https://www.reddithelp.com/en/submit-request/copyright-counter-notice'>Copyright Counter Notice Form</a> or to our 
                                Copyright Agent (contact information provided above). Please see 
                                <a href='http://www.copyright.gov/title17/92chap5.html#512'>17 U.S.C. 512(g)(3)</a> for the requirements of a 
                                proper counter notification.
                                </p>
                            </div>
                        </li>
                        <li><h2>9. Paid Services and Payment Information</h2>
                            <div>
                                <p>
                                There are no fees for the use of many aspects of the Services. However, Subscriptions are available for purchase 
                                (“<strong>Paid Services</strong>”). 
                                In addition to these Terms, by purchasing or using Vettage Paid Services, you further agree to the following:
                                </p>
                                <ol>
                                    <li>Vettage may change the fees or benefits associated with the Paid Services from time to 
                                        time with reasonable advance notice of material changes; provided, however, that no advance 
                                        notice will be required for temporary promotions, including temporary reductions in the fees
                                         associated with the Paid Services.</li>
                                    <li>You may submit your debit card, credit card, or other payment information (“<strong>Payment Information</strong>”) via our 
                                        Services to purchase the Paid Services. We use third-party service providers to process your Payment Information. 
                                        If you submit your Payment Information, you agree to pay all costs that you incur, and you give us permission to 
                                        charge you when payment is due for an amount that includes these costs and any applicable taxes and fees.</li>
                                </ol>
                            </div>
                        </li>
                        <li><h2>10. Indemnity</h2>
                            <div>
                                <p>
                                Except to the extent prohibited by law, you agree to defend, indemnify, and hold us, 
                                our directors, officers, employees, affiliates, agents, contractors, third-party service 
                                providers, and licensors (the “<strong>Vettage Entities</strong>”) harmless from any claim or demand, including 
                                costs and attorneys’ fees, made by any third party due to or arising out of (a) your use of the 
                                Services, (b) your violation of these Terms, (c) your violation of applicable laws or regulations, 
                                or (d) Your Content. We reserve the right to control the defense of any matter for which you are 
                                required to indemnify us, and you agree to cooperate with our defense of these claims
                                </p>
                            </div>
                        </li>
                        <li><h2>11. Disclaimers</h2>
                            <div>
                                <p>
                                THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, 
                                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, 
                                AND NON-INFRINGEMENT. VETTAGE ENTITIES DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, 
                                OR ERROR FREE. VETTAGE DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO 
                                THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING EDITORS. WHILE VETTAGE ATTEMPTS TO MAKE YOUR ACCESS 
                                TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR
                                 OTHER HARMFUL COMPONENTS.
                                </p>
                            </div>
                        </li>
                        <li><h2>12. Limitation of Liability</h2>
                            <div>
                                <p>
                                IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, 
                                WARRANTY, OR OTHERWISE, WILL THE VETTAGE ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, 
                                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE 
                                TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES 
                                THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN 
                                DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR 
                                LOSS OF DATA RESULTING THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE VETTAGE ENTITIES EXCEED THE
                                GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID VETTAGE IN THE PREVIOUS SIX MONTHS FOR THE 
                                SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, 
                                INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND EVEN IF THE 
                                VETTAGE ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH HEREIN 
                                IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE FOREGOING LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST 
                                EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                                </p>
                            </div>
                        </li>
                        <li><h2>13. Governing Law and Venue</h2>
                            <div>
                                <p>
                                    We want you to enjoy Vettage, so if you have an issue or dispute, you agree to raise it and 
                                    try to resolve it with us informally. You can contact us with feedback and concerns here. 
                                    Except for the government entities listed below, any claims arising out of or relating to 
                                    these Terms or the Services will be governed by the laws of California, without regard to 
                                    its conflict of laws rules. All disputes related to these Terms or the Services will be brought 
                                    solely in the federal or state courts located in San Francisco, California, and you 
                                    consent to personal jurisdiction in these courts.<br/><br/>
                                    <h4>Government Entities</h4>
                                    If you are a U.S. city, county, or state government entity, then this Section 13 does not apply to you.
                                    <br/><br/>
                                    If you are a U.S. federal government entity, any claims arising out of or relating to these 
                                    Terms or the Services will be governed by the laws of the United States of America without 
                                    regard to its conflict of laws rules. To the extent permitted by federal law, the laws of 
                                    Pennsylvania (other than its conflict of law rules) will apply in the absence of applicable 
                                    federal law. All disputes related to these Terms or the Services will be brought solely in the 
                                    federal or state courts located in Pittsburgh, Pennsylvania.
                                </p>
                            </div>
                        </li>
                        <li><h2>14. Changes to these Terms</h2>
                            <div>
                                <p>
                                    We may make changes to these Terms from time to time. If we make changes, we will post the revised Terms and 
                                    update the Effective Date above. If the changes, in our sole discretion, are material, we may also notify you 
                                    by sending an email to the address associated with your Account (if you have chosen to provide an email address) 
                                    or by otherwise providing notice through our Services. By continuing to access or use the Services on or after the 
                                    Effective Date of the revised Terms, you agree to be bound by the revised Terms. If you do not agree to the revised 
                                    Terms, you must stop accessing and using our Services before the changes become effective.
                                </p>
                            </div>
                        </li>
                        <li><h2>15. Termination</h2>
                            <div>
                                <p>
                                You may terminate these Terms at any time and for any reason by deleting or requesting deletion 
                                of your Account and discontinuing use of all Services. If you stop using the Services without 
                                deactivating your Account, your Account may be deactivated due to prolonged inactivity. <br/><br/>
                                We may suspend or terminate your Account, Editor status, or ability to access or use the Services 
                                at any time for any or no reason, including for violating these Terms or our 
                                <a href='/rules'>Content Policy</a>.
                                <br/><br/>
                                The following sections will survive any termination of these Terms or of your Account: 4 (Your Content), 
                                6 (Things You Cannot Do), 10 (Indemnity), 11 (Disclaimers), 12 (Limitation of Liability), 
                                13 (Governing Law and Venue), 15 (Termination), and 16 (Miscellaneous).
                                </p>
                            </div>
                        </li>
                        <li><h2>16. Miscellaneous</h2>
                            <div>
                                <p>
                                These Terms constitute the entire agreement between you and us regarding your 
                                access to and use of the Services. Our failure to exercise or enforce any right 
                                or provision of these Terms will not operate as a waiver of such right or provision. 
                                If any provision of these Terms is, for any reason, held to be illegal, invalid, or
                                 unenforceable, the rest of the Terms will remain in effect. You may not assign or transfer any of your rights 
                                 or obligations under these Terms without our consent. We may freely assign any of our rights and obligations under these Terms.
                                </p>
                            </div>
                        </li>
                    </ol>
                    <div>
                        <h4>Contact Information</h4>
                        <p>
                        Vettage, LLC
        <br/>606 Liberty Avenue, 3rd Floor Suite #107<br/>
                        Pittsburgh, PA 15222
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserAgreementPage
