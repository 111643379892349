import { useState } from "react"
import { Button, Dialog, Container, IconButton, Typography, Box,
     InputAdornment, FormControl, TextField, Chip } from "@material-ui/core"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Toast } from "../toastify";
import PayoutServices from '../../services/payoutServices'

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Box flexWrap="wrap">
            <Typography style={{wordBreak: "break-all"}} variant="h6">{children}</Typography>
        </Box>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles((theme) => ({
root: {
    padding: theme.spacing(2),
},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

const values = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

const theme = {
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        up: key => `@media (min-width:${values[key]}px)`,
    },
};


const Payouts = ({ flag, payouts, setPayoutAmount, open, onClose }) => {
    const [amount, setAmount] = useState(0)
    const [error, setError] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const handleChange = (newAmount) => {
        newAmount = newAmount.replace('e', '')

        if (newAmount > payouts.amount || newAmount === ""){
            setErrorMessage("The amount must be less or equal to the available amount.")
            setError(true)
        }else{
            setErrorMessage("")
            setError(false)
        }
        setAmount(newAmount)
    }

    const makePayout = () => {
        PayoutServices.makePayout({"payout_amount": amount})
        .then(result => {
            if(result.status === 200){
                setPayoutAmount({...payouts, "amount": amount})
                Toast("success", result.data?.message)
            }else{
                Toast("error", result.data?.error)
            }
        })
    }

    return (
        <div>
            <Container>
                <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" maxWidth="md" open={open}>
                    <Box component="div" flexWrap="wrap" justifyContent="center" fullWidth alignItems="center" marginBottom={2} p={{ xs: 2, sm: 3, md: 4 }} minHeight='40vh'>
                        <Box component="div" textAlign='center'>
                            <DialogTitle id="dialog-title" onClose={onClose}>Payouts</DialogTitle>
                        </Box>

                        {/* If flag is true, payouts are enabled and show withdraw form else show form to enable payouts */}
                        <DialogContent dividers>
                            <Box width='40vh' textAlign={'center'}>
                                <Box component='div'>
                                    <Chip
                                        label={<Typography>Available Amount: ${payouts.amount}</Typography>}
                                        style={{margin: '10px 5px', padding: '20px'}}
                                        color="primary"
                                        variant='outlined'>   
                                    </Chip>
                                </Box>
                                <Box component='div' marginBottom={2}>
                                    {payouts.fee && "This is your first payment for the current month. A fee of $2 will be applied."}
                                </Box>
                            <FormControl fullWidthvariant="outlined">
                                <TextField
                                    type='number'
                                    id="outlined-adornment-amount"
                                    value={amount}
                                    label="Amount"
                                    onChange={e => handleChange(e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={50}
                                />
                                {errorMessage !== "" && errorMessage}
                                <Button disabled={error} onClick={makePayout}>Payout</Button>
                            </FormControl>
                            </Box>
                        </DialogContent>
                    </Box>
                </Dialog>
            </Container>
        </div>
    )
}

export default Payouts